import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '../../../../services/media-watcher';
import { FuseNavigationService } from '../../../../components/navigation';
import { ApiUserSettings, Navigation, NavigationService, SessionService } from '@kedi/core';
import { QuickChatComponent } from '../../../../layout/common/quick-chat/quick-chat.component';
import { NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FuseFullscreenComponent } from '../../../../components/fullscreen/fullscreen.component';
import { FuseLoadingBarComponent } from '../../../../components/loading-bar/loading-bar.component';
import { HeaderButtonsComponent } from '../../../common/header-buttons/header-buttons.component';
import { LanguagesComponent } from '../../../common/languages/languages.component';
import { MessagesComponent } from '../../../common/messages/messages.component';
import { NotificationsComponent } from '../../../common/notifications/notifications.component';
import { SearchComponent } from '../../../common/search/search.component';
import { ShortcutsComponent } from '../../../common/shortcuts/shortcuts.component';
import { UserComponent } from '../../../common/user/user.component';
import { UserFirmComponent } from '../../../common/user-firm/user-firm.component';
import { FuseVerticalNavigationComponent } from '../../../../components/navigation/vertical/vertical.component';
import { UserCompanyComponent } from '../../../common/user-company/user-company.component';

@Component({
    selector     : 'compact-layout',
    templateUrl  : './compact.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports      : [
        NgIf, 
        FuseFullscreenComponent, 
        FuseLoadingBarComponent, 
        FuseVerticalNavigationComponent, 
        HeaderButtonsComponent,
        LanguagesComponent, 
        MatButtonModule, 
        MatDividerModule,
        MatIconModule, 
        MatMenuModule,
        MessagesComponent, 
        NotificationsComponent, 
        SearchComponent, 
        ShortcutsComponent, 
        QuickChatComponent,
        RouterOutlet, 
        UserComponent, 
        UserFirmComponent,
        UserCompanyComponent
    ],
})
export class CompactLayoutComponent implements OnInit, OnDestroy
{
    @ViewChild(QuickChatComponent) quickChat: QuickChatComponent;

    isScreenSmall: boolean;
    navigation: Navigation;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    // get siteUserSettings() : ApiUserSettings 
    // {
    //     return this.session.config.site.userSettings;
    // }
    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        public session: SessionService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number
    {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {

                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void
    {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if ( navigation )
        {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    onActivate(event) {
        window.scroll(0,0);
    }    
}
