import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'default',
    template: `<div></div>`,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
})
export class DefaultPage {
    public static readonly pageId: string = "DefaultPage";

    constructor() { }

    ngOnInit(): void { }
}
