import { Directive, ElementRef, Input, Pipe } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({
    name: "trustUrl",
    standalone: true,
})
export class TrustUrlPipe {
    constructor(private sanitizer: DomSanitizer) { }
    transform(url) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }
}
