<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation class="dark bg-gray-900 print:hidden" [appearance]="navigationAppearance"
    [mode]="isScreenSmall ? 'over' : 'side'" [name]="'mainNavigation'" [navigation]="navigation.default"
    [opened]="!isScreenSmall" (hoveredChanged)="setHovered($event)">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <div class="flex items-center w-full p-4 pl-6 h-24">
            <!-- Logo -->
            <div class="flex items-center justify-center">
                <img style="max-height:48px;" [src]="'images/logo/logo-text-menu.svg'">
            </div>
            <!-- Components VENOKTA-->
            <div *ngIf="isScreenSmall ||navigationAppearance != 'dense' || (navigationAppearance == 'dense' && isHovered)"
                class="flex items-center ml-auto">
                <notifications></notifications>
                <user [showAvatar]="false"></user>
            </div>
        </div>
        <!-- User VENOKTA-->
        <div *ngIf="isScreenSmall || navigationAppearance != 'dense' || (navigationAppearance == 'dense' && isHovered)"
            class="flex flex-col items-center w-full p-4">
            <fuse-avatar></fuse-avatar>
            <div class="flex flex-col items-center justify-center w-full mt-6">
                <div
                    class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-medium">
                    {{session.username()}}
                </div>
                <div
                    class="w-full mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-secondary">
                    {{session.userEmail()}}
                </div>
            </div>
        </div>
    </ng-container>
    <!-- Navigation footer hook -->
    <ng-container fuseVerticalNavigationContentFooter>
        <div class="flex flex-0 items-center justify-center h-16 pr-6 pl-2 mt-2 mb-4 opacity-12">
            <img class="max-w-36" [src]="'images/logo/logo-text-on-dark.svg'">
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto max-w-screen max-h-screen w-screen h-screen min-w-0 gap-0 p-0 m-0">
    <!-- Header -->
    <div
        class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <!-- Navigation toggle button -->
        <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>
        <!-- Navigation appearance toggle button -->
        <button class="hidden md:inline-flex" mat-icon-button (click)="toggleNavigationAppearance()">
            <mat-icon [svgIcon]="'heroicons_outline:switch-horizontal'"></mat-icon>
        </button>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <user-firm *ngIf="session && session.ActiveFirm"></user-firm>
            <user-company *ngIf="session && session.ActiveCompany"></user-company>
            <header-buttons *ngIf="navigation" class="space-x-1 sm:space-x-2" [navigation]="navigation.horizontal"
                [name]="'headerButtons'"></header-buttons>
            <languages *ngIf="session.config.multiLanguage()"></languages>
            <fuse-fullscreen *ngIf="session.config.toolFullScreen()" class="hidden md:block"></fuse-fullscreen>
            <search *ngIf="session.config.toolSearch()" [appearance]="'bar'"></search>
            <shortcuts *ngIf="session.config.toolShortcuts()"></shortcuts>
            <messages *ngIf="session.config.toolMessages()"></messages>
            <notifications *ngIf="session.config.toolNotifications()"></notifications>
            <button *ngIf="session.config.toolChat()" mat-icon-button (click)="quickChat.toggle()">
                <mat-icon [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
            </button>
            <user [showAvatar]="true" size="12"></user>
        </div>
    </div>

    <!-- Content -->
    <div class="inset-0 flex flex-col w-full h-full overflow-y-auto overflow-x-auto relative" cdkScrollable>
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true" (activate)="onActivate($event)"></router-outlet>
    </div>
</div>

<!-- Quick chat -->
<quick-chat *ngIf="session.config.toolChat() && !session.isAnonymous()" #quickChat="quickChat"></quick-chat>