import { NgModule } from '@angular/core';

@NgModule({
    declarations: [],
    imports     : []
})
export class SessionServiceModule
{
    /**
     * Constructor
     */
    constructor()
    {
    }
}
