import { BehaviorSubject } from "rxjs";
import { NavigationItem } from "../navigation/navigation.types";
import { KediFile } from "../../entities/core.entities";
import { SessionCompany, SessionFirm, SessionRole, User } from "../session/session.types";

// export interface ApiSetting {
//   Code: string;
//   SiteTitle: string;
//   SiteName: string[];
//   SiteDescription: string[];
//   SiteMotto: string[];
//   AllowAnonymous: boolean;
//   CaptchaSiteKey: string;
//   CanSignUp: boolean;
//   CanChangeLanguage: boolean;
//   CanChangeLayout: boolean;
//   CanChangeTheme: boolean;
//   CanChangeScheme: boolean;
//   LoginWith: string;
//   AuthScreenStyle: string;
//   DefaultLanguage: string;
//   DefaultLayout: string;
//   DefaultRoute: string;
//   DefaultTheme: string;
//   DefaultScheme: string;
//   MultiLanguage: boolean;
//   MultiLayout: boolean;
//   MultiTheme: boolean;
//   MultiScheme: boolean;
//   HasCalendarApp: boolean;
//   HasChatApp: boolean;
//   HasContactsApp: boolean;
//   HasLibraryApp: boolean;
//   HasMailApp: boolean;
//   HasNotesApp: boolean;
//   HasScurmboardApp: boolean;
//   HasTasksApp: boolean;
//   TransparentHeader?: boolean;
//   BackgroundImage?: string;
//   AllowedThemes?: string[];
//   AllowedSchemes?: string[];
//   AllowedLayouts?: string[];
//   UserSettings: ApiUserSettings;
//   SignUpSettings: ApiSignUpSettings;
//   MetaTitle: string;
//   MetaDescription: string;
//   MetaKeywords: string;
//   MetaOther: string[];
// }

// export interface ApiUserSettings {
//   HasUserSettings: boolean;
//   RouteUserSettings: string;
//   HasUserProfile: boolean;
//   RouteUserProfile: string;
//   HasUserStatus: boolean;
//   HasMessages: boolean;
//   HasNotifications: boolean;
//   HasShortcuts: boolean;
//   HasSearch: boolean;
//   HasFullScreen: boolean;
//   HasUserFirm: boolean;
// }

// export interface ApiSignUpSettings {
//   AskCaptcha: boolean;
//   AskCompany: boolean;
//   AskContract: boolean;
//   ContractContent: string;
//   AskPrivacy: boolean;
//   PrivacyContent: string;
// }

// export interface ApiLayoutSettings {
//   TransparentHeader?: boolean;
//   BackgroundImage?: string;
//   AllowedThemes?: string[];
//   AllowedSchemes?: string[];
//   AllowedLayouts?: string[];
// }

// export interface IKediSiteConfig {
//   code: string;
//   cdn: string;
//   siteTitle: string;
//   siteName: string[];
//   siteDescription: string[];
//   siteMotto: string[];
//   allowAnonymous: boolean,    // Kullanıcı girişi zorunlu mu?
//   captchaSiteKey: string;
//   canSignUp: boolean;
//   canChangeLanguage: boolean;
//   canChangeLayout: boolean;
//   canChangeTheme: boolean;
//   canChangeScheme: boolean;
//   loginWith: string,          // Kullanıcı girişi email ile mi yapılıyor (email | username)
//   authScreenStyle: string,    // Login vs ekran stili (classic | fullscreen | fullscreen-reversed | modern | modern-reversed | split-screen | split-screen-reversed)
//   defaultLanguage: string,    // Varsayılan dil
//   defaultLayout: string,      // Varsayılan layout (empty | centered | enterprise | material | modern | classic | classy | compact | dense | futuristic | thin )
//   defaultRoute: string,       // Varsayılan Rota
//   defaultScheme: string,      // (default | light | dark)
//   defaultTheme: string,       // (default | brand | indigo | rose | purple | amber)
//   multiLanguage: boolean,     // Çoklu dil var mı?
//   multiLayout: boolean,       // Çoklu layout seçimi var mı?
//   multiTheme: boolean,        // Çoklu renk teması var mı?
//   multiScheme: boolean,       // Çoklu renk şeması var mı?
//   hasCalendarApp: boolean;
//   hasChatApp: boolean;
//   hasContactsApp: boolean;
//   hasLibraryApp: boolean;
//   hasMailApp: boolean;
//   hasNotesApp: boolean;
//   hasScrumboardApp: boolean;
//   hasTasksApp: boolean;
//   userSettings: ApiUserSettings;
//   signUpSettings: ApiSignUpSettings;
//   layoutSettings: ApiLayoutSettings;
//   searchPost: string;
//   metaTitle: string;
//   metaDescription: string;
//   metaKeywords: string;
//   metaOther: string[];
//   resetPasswordMethod: string;
// }


export class IKediConfig {
  api: IKediApiConfig;
}
export interface IKediApiConfig {
  url: string,
  site: string
}

export interface IKediResponse<T> {
  Succeded: boolean,
  Message: string,
  Result: T
}

export interface IKediDataResponse<T> {
  Succeded: boolean,
  Message: string,
  Data: T
}

export interface IVOEResponse<T> {
  Succeded: boolean,
  Message: string,
  Data: T
}

export interface IKediDataResult<T> {
  Data?: T[],
  PageInfo?: IApiDataPage
  View?: ApiView;
  PivotFields?: string[];
}

export interface IApiDataFilter {
  Property: string;
  Operator: string;
  Value: string;
}
export interface IKediDataComponent {
  get rowSubject();
}

export interface IApiDataSort {
  Property: string;
  Descending?: boolean;
}

export interface IApiDataPage {
  Size?: number;
  Number?: number;
  PageCount?: number;
  TotalCount?: number;
}

export interface IApiDataFormat {
  DataAutoInclude?: boolean;
  DataInclude?: string;
  DataExclude?: string;
  JsonIgnore?: string;
  JsonUnignore?: string;
  JsonName?: string;
}

export interface IApiDataBulk {
  DownloadData?: boolean;
  UploadData?: boolean;
}

export interface IApiDataLoadOptions {
  Caller?: IApiCaller;
  Filter?: IApiDataFilter[];
  Sort?: IApiDataSort[];
  Page?: IApiDataPage;
  Format?: IApiDataFormat;
  WithView?: boolean;
  Pivot?: IApiDataPivot
}

export interface IApiDataDumpOptions {
  Caller?: IApiCaller;
  Format?: IApiDataFormat;
  Body?: any;
  SubEntities?: string;
}

export interface IApiDataRequest extends IApiDataLoadOptions {
  Body?: any
}

export interface IApiDataPivotProp {
  Property?: string;
  AggFunc?: string;
}

export interface IApiDataPivot {
  IsActive?: boolean;
  GroupKeys?: string[];
  GroupProps?: IApiDataPivotProp[];
  ValueProps? : IApiDataPivotProp[];
  PivotProps?: IApiDataPivotProp[];
}

export interface IApiViewLoadOptions {
  Format?: IApiDataFormat;
  Caller?: IApiCaller;
  Filter?: IApiDataFilter[];
  Sort?: IApiDataSort[];
  Page?: IApiDataPage;
}

export interface IApiCaller {
  Source: IApiCallerSource;
  Name: string;
  Parameters?: string;
}

export enum IApiCallerSource {
  Web,
  Mobile,
  Service
}

export class ApiFileActionRequest {
  Entity: string;
  Id: number;
  Property: string;
  Action: string;
  OrderNo?: number = 0;
  File?: KediFile;
  Keys?: string | string[];
}

export class ApiNgModule {
  NgType: any;
  ModuleCode: string;
  ApiModule: ApiModule;
  ApiRoutes: ApiRoute[]
}

export interface IKediData {
  add<T>(key: string, value?: T): T;
  get<T>(key: string): T;
  set<T>(key: string, value?: T): void;
  [key: string]: any;
}

export class KediData implements IKediData {
  [key: string]: any;
  constructor() {
    // normalde container içine herhangi bir veri yüklenebilir. Burası sadece trackinglist ve list tipli veriler içindir
  }

  add<T>(key: string, value?: T): T {
    if (!key || key === "") return undefined;
    var bs: BehaviorSubject<T> = this[key];
    if (bs) {
      bs.complete();
      bs = null;
      delete this[key];
    }
    this[key] = new BehaviorSubject<T>(value);
    return value;
  }

  get<T>(key: string): T {
    if (!key || key === "") return undefined;
    var bs: BehaviorSubject<T> = this[key];
    if (!bs) return undefined;
    return bs.getValue();
  }

  set<T>(key: string, value?: T): T {
    if (!key || key === "") return undefined;
    var bs: BehaviorSubject<T> = this[key];
    if (!bs) return undefined;
    bs.next(value);
    return value;
  }
}

export class KediDataLink {
  component: IKediDataComponent;
  asFilter: boolean;
  maps: KediDataLinkMap[];
  currentRow?: any;
}

export class KediDataLinkMap {
  sourceProperty: string;
  targetProperty: string;
}

export class KediDataOptions {
  dataSourceType?: KediDataSourceType;
  dataSource?: string;
  entity?: string;
  entityType?: any;
  view?: KediViewOptions;
  hasView?: boolean;
  autoLoad?: boolean;
  autoFirst?: boolean;
  property?: string;
  mapFunction?: any;
  links?: KediDataLink[];
  batch?: number;
  sort?: IApiDataSort[] = null;
  filter?: IApiDataFilter[] = null;
  format?: IApiDataFormat = null;
  page?: IApiDataPage;
  withView?: boolean;
  bulk?: IApiDataBulk = {
    DownloadData: true,
    UploadData: false
  };

  asKediDataLoadOptions(): IApiDataLoadOptions {
    let filter: IApiDataFilter[] = [];
    let returnNull = false;
    this.links?.forEach(l => {
      if (!l.currentRow) {
        if (l.asFilter) returnNull = true;
        else {
          filter.push({
            Operator: "pass_load",
            Property: "x",
            Value: null
          });
        }
      }
      else {
        l.maps.forEach(m => {
          filter.push({
            Operator: "dynamic",
            Property: this.entity,
            Value: this.entity + "." + m.targetProperty + "==" + l.currentRow[m.sourceProperty]
          });
        });
      }
    });
    this.filter?.forEach(f => filter.push({ ...f }));
    return {
      Format: this.format,
      Filter: returnNull ? null : filter,
      Sort: this.sort,
      Page: this.page,
      WithView: this.withView
    };
  }
}

export class KediViewOptions {
  dataSourceType: KediDataSourceType;
  dataSource: string;
  entity?: string;
  entityType?: any;
  format?: IApiDataFormat = null;
  view: KediViewOptions;

  asApiViewLoadOptions(): IApiViewLoadOptions {
    return {
      Format: this.format
    };
  }
}

export enum KediDataSourceType {
  entity = 1,
  route = 3,
  func = 4
}

export class ApiView {
  Entities: ApiViewEntity[];
}

export class ApiViewEntity {
  Key?: string;
  Name?: string;
  Title?: string;
  Properties?: ApiViewProperty[];
  DefaultFiltering?: ApiViewFilter[];
  DefaultSorting?: ApiViewSort[];
  DefaultPaging?: ApiViewPage;

}

export class ApiViewProperty {
  OrderNo?: number;
  Key?: string;
  Name?: string;
  SqlName?: string;
  OriginalName?: string;
  Title?: string;
  Tooltip?: string;
  MaxLength?: number;
  Nullable?: boolean;
  Format?: string;
  TypeName?: string;
  DataType?: string; // string, number, datetime, boolean...
  DefaultValue?: string;
  PropertyType?: string;
  RenderType?: string;
  EditorType?: string; // text, number, memo, month, date, datetime, lookup, image
  DataSource?: string;
  Multiple?: boolean;
  Chooser?: boolean;
  ChooserInfo?: string;
  Suppress?: boolean;
  Editable?: boolean;
  Visible?: boolean;
  LockVisible?: boolean;
  Resizable?: boolean;
  Movable?: boolean;
  Filterable?: boolean;
  FilterInfo?: string;
  Sortable?: boolean;
  SortInfo?: string;
  Pinnable?: boolean;
  PinInfo?: string;
  Groupable?: boolean;
  GroupInfo?: string;
  Pivotable?: boolean;
  PivotInfo?: string;

  Width?: number;
  MinWidth?: number;
  MaxWidth?: number;
  Flex?: number;
  RefData?: any;
}

export class ApiViewFilter {
  Property: string;
  Operator: string;
  Value: string;
}

export class ApiViewSort {
  Property: string;
  Descending?: boolean = false;
}

export class ApiViewPage {
  Size?: number;
  Number?: number;
}

export class KediBadgeValue {
  code: string;
  value: number | string;
  classes?: string
}

export class KediSharedEnumValue {
  id: number;
  text: string;
  value: any;
}

export class KediSharedData<T> {
  key: string;
  data: BehaviorSubject<T>;
  source: any; // Component
}

export class KediSharedList<T extends { Id?: number }> extends KediSharedData<T>{
  list: BehaviorSubject<T[]>;
  rowid: BehaviorSubject<number>;
  totalCount: BehaviorSubject<number>;
  autoFirst: boolean = false;
  constructor(_value: T[]) {
    super();
  }

  getListValue(): T[] {
    if (!this.list) return undefined;
    return this.list.getValue();
  }

  setListValue(_value?: T[]): T[] {
    if (!this.list) return undefined;
    this.list.next(_value);
    if (this.autoFirst && _value?.length > 0) {
      this.data.next(_value[0] ?? null);
    }
    else {
      this.data.next(null);
    }
    return _value;
  }

  setListBatch(startRow: number, endRow: number, _value?: T[], purge: boolean = false): T[] {
    if (!this.list) return undefined;
    let curVal = this.list.getValue();
    let newVal = (curVal && curVal.length > 0 && startRow > 0 ? curVal.slice(0, startRow) : []).concat(_value ?? []);
    this.list.next(newVal);
    return newVal;
  }

  getRowId(): number {
    if (!this.rowid) return undefined;
    return this.rowid.getValue();
  }

  setRowId(_id?: number): number {
    let listValue = this.getListValue();
    if (!listValue || !this.data || !this.rowid) return undefined;
    if (_id >= 0 && listValue.length <= _id) _id = null;
    this.data.next(_id == null ? null : listValue[_id]);
    this.rowid.next(_id);
    return _id;
  }

  getRowValue(): T {
    if (!this.data) return undefined;
    return this.data.getValue();
  }

  setRowValue(_value?: T, _index?: number): T {
    if (!this.data) return undefined;
    let listValue = this.getListValue();
    let _idx = listValue.findIndex(v => v.Id == _value.Id);
    if (_idx < 0) {
      this.data.next(null);
      this.rowid.next(null);
    }
    else {
      this.data.next(_value);
      this.rowid.next(_idx);
    }

    // let _id = listValue?.indexOf(_value);
    // if (_id < 0) {
    //   this.data.next(null);
    //   this.rowid.next(null);
    // }
    // else {
    //   this.data.next(_value);
    //   this.rowid.next(_id);
    // }
    return _value;
  }

  getTotalCount(): number {
    if (!this.totalCount) return undefined;
    return this.totalCount.getValue();
  }

  setTotalCount(_count?: number): void {
    this.totalCount.next(_count);
  }
}

export class KediSharedValue<T> extends KediSharedData<T>{
  constructor(_value: T) {
    super();
  }
  getValue(): T {
    if (!this.data) return undefined;
    return this.data.getValue();
  }

  setValue(_value?: T): T {
    if (!this.data) return undefined;
    this.data.next(_value);
    return _value;
  }
}
export enum PageGuardType {
  Default = 0,
  Admin = 1,
  Auth = 2,
  Anonym = 3,
  NoAuth = 4
}
export class KediPage {
  Authorized: boolean;
  IsMeta: boolean;
  Name: string;
  Guard: PageGuardType;
  NeedsAdmin: string;
  NeedsUser: boolean;
  NeedsPlugin: string;
  Url: string;
}

export class KediSharedZone {
  constructor() {
  }
  [key: string]: any;
  registerList<T>(_key: string, _source?: any): KediSharedList<T> {
    if (!_key || _key === "") return null;
    if (this.hasOwnProperty(_key)) {
      this[_key].list?.complete();
      this[_key].list = null;
      delete this[_key];
    }
    var sv: KediSharedList<T> = this[_key];
    this[_key] = new KediSharedList<T>(null);
    sv = this[_key];
    sv.key = _key;
    sv.list = new BehaviorSubject<T[]>(null);
    sv.rowid = new BehaviorSubject<number>(null);
    sv.data = new BehaviorSubject<T>(null);
    sv.totalCount = new BehaviorSubject<number>(null);
    sv.source = _source;
    return sv;
  }

  unregisterList<T extends { Id?: number }>(_key: string) {
    if (!_key || _key === "") return;
    if (this.hasOwnProperty(_key)) {
      var sv: KediSharedList<T> = this[_key];
      sv.data?.complete();
      sv.data = null;
      sv.list?.complete();
      sv.list = null;
      sv.rowid.complete();
      sv.list = null;
      delete this[_key];
    }
  }

  registerValue<T>(_key: string, _value?: T, _source?: any): KediSharedValue<T> {
    if (!_key || _key === "") return null;
    // if (this.hasOwnProperty(_key)) {
    //   this[_key].data?.complete();
    //   this[_key].data = null;
    //   delete this[_key];
    // }
    if (!this.hasOwnProperty(_key)) {
      this[_key] = new KediSharedValue<T>(_value);
      this[_key].data = new BehaviorSubject<T>(_value);
    }
    this[_key].key = _key;
    this[_key].source = _source;
    this[_key].data.next(_value);
    return this[_key];
  }

  unregisterValue<T>(_key: string) {
    if (!_key || _key === "") return;
    if (this.hasOwnProperty(_key)) {
      this[_key].data?.complete();
      this[_key].data = null;
      delete this[_key];
    }
  }

  list<T>(_key: string): BehaviorSubject<T[]> {
    if (!_key || _key === "") return undefined;
    if (!this.hasOwnProperty(_key)) return undefined;
    var sv: KediSharedList<T> = this[_key];
    var bs: BehaviorSubject<T[]> = sv.list;
    if (!bs) return undefined;
    return bs;
  }

  row<T>(_key: string): BehaviorSubject<T> {
    if (!_key || _key === "") return undefined;
    if (!this.hasOwnProperty(_key)) return undefined;
    var sv: KediSharedList<T> = this[_key];

    var bs: BehaviorSubject<T> = sv.data;
    if (!bs) return undefined;
    return bs;
  }

  getListValue<T>(_key: string): T[] {
    if (!_key || _key === "") return undefined;
    if (!this.hasOwnProperty(_key)) return undefined;
    var sv: KediSharedList<T> = this[_key];

    var bs: BehaviorSubject<T[]> = sv.list;
    if (!bs) return undefined;
    return bs.getValue();
  }

  setListValue<T>(_key: string, _value?: T[]): T[] {
    if (!_key || _key === "") return undefined;
    if (!this.hasOwnProperty(_key)) return undefined;
    var sv: KediSharedList<T> = this[_key];

    var bs: BehaviorSubject<T[]> = sv.list;
    if (!bs) return undefined;
    bs.next(_value);
    return _value;
  }

  getRowValue<T>(_key: string): T {
    if (!_key || _key === "") return undefined;
    if (!this.hasOwnProperty(_key)) return undefined;
    var sv: KediSharedList<T> = this[_key];

    var bs: BehaviorSubject<T> = sv.data;
    if (!bs) return undefined;
    return bs.getValue();
  }

  setRowValue<T>(_key: string, _value?: T): T {
    if (!_key || _key === "") return undefined;
    if (!this.hasOwnProperty(_key)) return undefined;
    var sv: KediSharedList<T> = this[_key];

    var bs: BehaviorSubject<T> = sv.data;
    if (!bs) return undefined;

    bs.next(_value);
    return _value;
  }

  value<T>(_key: string): BehaviorSubject<T> {
    if (!_key || _key === "") return undefined;
    if (!this.hasOwnProperty(_key)) return undefined;
    var sv: KediSharedValue<T> = this[_key];

    var bs: BehaviorSubject<T> = sv.data;
    if (!bs) return undefined;
    return bs;
  }

  getValue<T>(_key: string): T {
    if (!_key || _key === "") return undefined;
    if (!this.hasOwnProperty(_key)) return undefined;
    var sv: KediSharedValue<T> = this[_key];

    var bs: BehaviorSubject<T> = sv.data;
    if (!bs) return undefined;
    return bs.getValue();
  }

  setValue<T>(_key: string, _value?: T): T {
    if (!_key || _key === "") return undefined;
    if (!this.hasOwnProperty(_key)) return undefined;
    var sv: KediSharedValue<T> = this[_key];

    var bs: BehaviorSubject<T> = sv.data;
    if (!bs) return undefined;

    bs.next(_value);
    return _value;
  }
}

export class ApiState {
  User: User;
  Session: ApiStateInfo;
  Menu: ApiMenu;
  //Routes: ApiRoute[];
  Pages: KediPage[];
  //Modules: ApiModule[];
  MultiLang: ApiMultiLang;
  CachedValues: ApiCachedValue[];
  Settings: ApiSetting;
}

export class ApiStateInfo {
  Key: string;
  Type: string;
  Token: string;
  Platform: string;
  StartedAt: Date;
  ValidUntil: Date;
  UserId: number;
  Username: string;
  Name: string;
  Email: string;
  Language: string;
  Roles: string;
  MustChangePassword?: boolean;
  ActiveRole?: SessionRole;
  ActiveFirm?: SessionFirm;
  ActiveCompany?: SessionCompany;
}

export class ApiMenu {
  id: number;
  isDefault: boolean;
  title: string;
  children: NavigationItem[];
}

export enum MenuItemType {
  Group = 1,
  Page = 2,
  Report = 3
}

export class ApiMenuItem {
  Id: number;
  Key: string;
  Name: string;
  Description: string;
  ParentKey: string;
  TargetKey: string;
  TargetType: MenuItemType;
  Children: ApiMenuItem[];
}

export class ApiModule {
  Id: number;
  Key: string;
  Code: string;
  Description: string;
  KediModuleKey: string;
  KediModuleCode: string;
  Components: ApiComponent[];
}

export class ApiComponent {
  Id: number;
  Key: string;
  Name: string;
  Description: string;
  ModuleKey: string;
  ModuleId: number;
}

export class ApiRoute {
  Id: number;
  ModuleKey: string;
  Key: string;
  Code: string;
  Description: string;
  RouteParam: string;
  Component: ApiComponent;
}

export class ApiMultiLang {
  Languages: ApiLanguage[];
  Locales: ApiLocale[];
}

export class ApiLanguage {
  index?: number;
  id: string;
  title: string;
  flag: string;
  locale: string;
  rtl?: boolean = false;
}
export interface ApiLocale {
  lang: string;
  data: Object;
}

export class ApiCachedValue {
  Key: string;
  Signature: string;
  Value: string;
}

export class ApiSetting {
  Code: string;
  SiteTitle: string;
  SiteName: string[];
  SiteDescription: string[];
  SiteMotto: string[];
  AllowAnonymous: boolean;
  CaptchaSiteKey: string;
  CanSignUp: boolean;
  CanChangeLanguage: boolean;
  CanChangeLayout: boolean;
  CanChangeTheme: boolean;
  CanChangeScheme: boolean;
  LoginWith: string;
  AuthScreenStyle: string;
  DefaultLanguage: string;
  DefaultLayout: string;
  DefaultRoute: string;
  DefaultTheme: string;
  DefaultScheme: string;
  ChangePasswordRoute?: string;
  ResetPasswordMethod?: string;
  MultiLanguage: boolean;
  MultiLayout: boolean;
  MultiTheme: boolean;
  MultiScheme: boolean;
  HasCalendarApp: boolean;
  HasChatApp: boolean;
  HasContactsApp: boolean;
  HasLibraryApp: boolean;
  HasMailApp: boolean;
  HasNotesApp: boolean;
  HasScurmboardApp: boolean;
  HasTasksApp: boolean;
  TransparentHeader?: boolean;
  BackgroundImage?: string;
  AllowedThemes?: string[];
  AllowedSchemes?: string[];
  AllowedLayouts?: string[];
  UserSettings: ApiUserSettings;
  SignUpSettings: ApiSignUpSettings;
  MetaTitle: string;
  MetaDescription: string;
  MetaKeywords: string;
  MetaOther: string[];
}

export interface ApiUserSettings {
  HasUserSettings: boolean;
  RouteUserSettings: string;
  HasUserProfile: boolean;
  RouteUserProfile: string;
  HasUserStatus: boolean;
  HasMessages: boolean;
  HasNotifications: boolean;
  HasShortcuts: boolean;
  HasSearch: boolean;
  HasFullScreen: boolean;
  HasUserFirm: boolean;
}

export interface ApiSignUpSettings {
  AskCaptcha: boolean;
  AskCompany: boolean;
  AskContract: boolean;
  ContractContent: string;
  AskPrivacy: boolean;
  PrivacyContent: string;
}

export interface ApiLayoutSettings {
  TransparentHeader?: boolean;
  BackgroundImage?: string;
  AllowedThemes?: string[];
  AllowedSchemes?: string[];
  AllowedLayouts?: string[];
}

export class ApiLogData {
  Data?: string;
  PageCount?: null;
  PageNumber?: number;
  PageSize?: number;
}

export class ApiLogFilter {
  EndDate?: Date;
  StartDate?: Date;
  Type?: string;
  PageNumber?: number;
  PageSize?: number;
  PageCount?: number;
};