import { NgModule } from '@angular/core';

@NgModule({
    declarations: [],
    imports     : []
    //,
    // providers   : [
    //     SessionService
    // ]
})
export class NavigationServiceModule
{
    /**
     * Constructor
     */
    constructor()
    {
    }
}
