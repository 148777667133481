import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthUtils } from './auth.utils';
import { catchError, Observable, throwError } from 'rxjs';
import { SessionService } from '../session';

export const authInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    const _session = inject(SessionService);

    let newReq = req.clone();

    if (_session.token && !AuthUtils.isTokenExpired(_session.token)) {
        newReq = req.clone({
            headers: req.headers.set('Authorization', 'Bearer ' + _session.token),
        });
    }

    return next(newReq).pipe(
        catchError((error) => {
            if (error instanceof HttpErrorResponse && error.status === 401) {
                _session.signOut();
                location.reload();
            }

            return throwError(() => new Error(error));
        }),
    );
};
