export { } // this will make it module
declare global { // this is important to access it as global type String
    interface String {
        toPascal(): string;
        toKebab(): string;
        rightTrim(s: string): string;
        leftTrim(s: string): string;
    }
}

String.prototype.toPascal = function() {
    return this.replace(/\w+/g, function(w){return w[0].toUpperCase() + w.slice(1).toLowerCase();});
}

String.prototype.toKebab = function() {
    return this.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)?.map((x) => x.toLowerCase())?.join("_") ?? this;
}

String.prototype.rightTrim = function(s: string) {
    return this.replace(new RegExp(s + "+$"), '');
}

String.prototype.leftTrim = function(s: string) {
    return this.replace(new RegExp("^" + s), '');
}