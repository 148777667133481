import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { fuseAnimations } from '../../animations';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector     : 'fuse-sticky-header',
    templateUrl  : './sticky-header.component.html',
    encapsulation: ViewEncapsulation.None,
    exportAs     : 'fuseStickyHeader',
    animations : fuseAnimations,
    standalone: true,
    imports: [ NgIf, NgFor ]
})
export class FuseStickyHeaderComponent implements OnChanges, OnInit, OnDestroy
{
    @ViewChild('template',{static: true}) template;

    show: boolean = false;
    label: string = "Start";
    state: "collapsed" | "expanded" = "expanded";
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor( private _vcf: ViewContainerRef)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On changes
     *
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void
    {
        this.label = JSON.stringify(changes);
    }

    /**
     * On init
     */
    ngOnInit(): void
    {
        this._vcf.createEmbeddedView(this.template);
        this.toggleHeader();
        // setTimeout(() => {
        //     this.show = true; //!this.show;
        // }, 1000);
    }

    toggleHeader() {
        setTimeout(() => {
            this.show = !this.show;
            // this.label = this.show ? "GÖRÜNÜYOR" : "GİZLİ";
            this.toggleHeader();
        }, 2000);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
