import { KediEntityBase } from "./entity.base";

export class Firm extends KediEntityBase
{
  Code?: string;
  Name?: string;
}

export enum AddressType {
  Delivery = 1,
  Invoice = 2,
}

export class FirmAddress extends KediEntityBase {
  FirmId?: number;
  Type?: AddressType;
  Code?: string;
  Address?: string;
  CityId?: number;
  CityName?: string;
  DistrictId?: number;
  DistrictName?: string;
  IsActive?: boolean;
  Notes?: string;
}

export enum ItemType {
  Raw = 1,
  SemiProduct = 2,
  Product = 3,
  Service = 4,
  Consumable = 5,
  Fixture = 6,
}

export class Item extends KediEntityBase {
  ItemGroupId?:number;
  ItemType?:ItemType;
  Code?: string;
  Name?: string;
  IsActive?: boolean;
  VatRateId?:number;
  VatRatePercent?: number;
  Weight?:number;
  UnitId?:number;
  Images?:string[];
  Thumbs?:string[];
  TimeCodes?:string[];
  OrderNo?:number;
}

export class ItemGroup extends KediEntityBase {
  Parent: ItemGroup;
  ParentId?: number;
  ItemType: ItemType;
  Code: string;
  Name: string;
  Description: string[];
  Images: string[];
  Thumbs: string[];
  OrderNo?: number;
}

export class Unit extends KediEntityBase {
  Code?:string;
  Name?: string;
}

export class UnitConversion extends KediEntityBase {
  UnitIdFrom?:number;
  UnitCodeFrom?: string;
  UnitIdTo?:number;
  UnitCodeTo?: string;
  QtyFrom?:number;
  QtyTo?:number;
}

export class PriceListD extends KediEntityBase
{
  PriceListMId?:number;
  DeliveryTypeId?:number;
  PaymentTypeId?: number;
  ItemId?:number;
  Item?: Item;
  UnitPrice?:number;
  StartQty?:number;
  EndQty?:number;
  IsActive?:boolean;
  VatIncluded?:boolean;
  CurrencyId?:number;
  CurrencyCode?:string;
  UnitId?:number;
  UnitCode?:string;
  ItemUnitId?:number;
  ItemUnitCode?:string;
}

export class PriceListM extends KediEntityBase
{
  Code?: string;
  Name?: string;
  IsActive?: boolean;
  StartDate?: Date;
  EndDate?: Date;
  IntegrationId?: number;
  Details?: PriceListD[];
}

export class Holiday extends KediEntityBase {
  CountryId?: number;
  CountryCode?: string;
  CountryName?: string;
  DayOfWeek?: number;
  DayOfMonth?: number;
  Month?: number;
  Year?: number;
  Name?: string;
  Description?: string;
  IsHalfDay?: boolean;
  IsSpecialDay?: boolean;
}

export class PaymentType extends KediEntityBase {
  Code?: string;
  Name?: string[];
}

export class FirmPriceList extends KediEntityBase
{
  FirmId?: number;
  PriceListMId?: number;
  PriceListM?: PriceListM;
  AccountCode?: string;
  Title?: string;
  IntegrationId?: number;
  IntegrationNote?: string;
}

export class DeliveryType extends KediEntityBase {
  Code?: string;
  DeliveryMode?: DeliveryMode;
  ShipmentMode?: ShipmentMode;
  Name?: string[];
}

export class ShipmentType extends KediEntityBase {
  Code?: string;
  DeliveryMode?: DeliveryMode;
  ShipmentMode?: ShipmentMode;
  Name?: string[];
  MaxKg?: number;
}
export enum ShipmentMode {
  Truck = 1,
  TIR = 2
}

export enum DeliveryMode {
  Pickup = 1,
  Shipment = 2
}
