<!-- Button -->
<button
  mat-icon-button
  [matTooltip]="'Kullanıcı'"
  [matMenuTriggerFor]="userActions"
  (menuOpened)="userActionsMenuOpened()"
>
  <span class="relative">
    <img
      [class]="'w-' + size + ' h-' + size + ' rounded-full object-cover'"
      *ngIf="showAvatar && session.userAvatar()"
      [src]="session.userAvatar()"
    />
    <mat-icon
      *ngIf="!showAvatar || !session.userAvatar()"
      [svgIcon]="'heroicons_outline:user-circle'"
      [class.text-white]="session.currentHeaderTransparent()"
    ></mat-icon>
    <span
      class="absolute right-0 bottom-0 w-2 h-2 rounded-full"
      [ngClass]="{
        'mr-px mb-px': !showAvatar || !session.userAvatar(),
        'bg-green-500': session.userStatus() === 'online',
        'bg-amber-500': session.userStatus() === 'away',
        'bg-red-500': session.userStatus() === 'busy',
        'bg-gray-400': session.userStatus() === 'not-visible',
      }"
    ></span>
  </span>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
  <button *ngIf="session.enabledUser()" mat-menu-item>
    <span class="flex flex-col leading-none">
      <span>{{ "LAYOUT.SIGNED_IN_AS" | transloco }}</span>
      <span class="mt-1.5 text-md font-medium">{{ session.userEmail() }}</span>
    </span>
  </button>
  <mat-divider *ngIf="session.enabledUser()" class="my-2"></mat-divider>
  <button
    *ngIf="session.enabledUser() && session.config.toolProfile()"
    mat-menu-item
    (click)="goToProfile()"
  >
    <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
    <span>{{ "LAYOUT.PROFILE" | transloco }}</span>
  </button>
  <button
    *ngIf="session.enabledUser() && session.config.toolSettings()"
    mat-menu-item
    (click)="goToSettings()"
  >
    <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
    <span>{{ "LAYOUT.SETTINGS" | transloco }}</span>
  </button>
  <button
    *ngIf="session.enabledUser() && session.config.toolStatus()"
    mat-menu-item
    [matMenuTriggerFor]="userStatus"
  >
    <mat-icon [svgIcon]="'heroicons_outline:dots-circle-horizontal'"></mat-icon>
    <span>{{ "LAYOUT.STATUS" | transloco }}</span>
  </button>
  <mat-divider
    *ngIf="session.enabledUser() && hasUserAction"
    class="my-2"
  ></mat-divider>
  <button *ngIf="session.enabledUser()" mat-menu-item (click)="signOut()">
    <mat-icon [svgIcon]="'logout'"></mat-icon>
    <span>{{ "LAYOUT.SIGN_OUT" | transloco }}</span>
  </button>
  <button *ngIf="!session.enabledUser()" mat-menu-item (click)="signIn()">
    <mat-icon [svgIcon]="'login'"></mat-icon>
    <span>{{ "LAYOUT.SIGN_IN" | transloco }}</span>
  </button>
  <button *ngIf="!session.enabledUser()" mat-menu-item (click)="signUp()">
    <mat-icon [svgIcon]="'person_add_alt'"></mat-icon>
    <span>{{ "LAYOUT.SIGN_UP" | transloco }}</span>
  </button>
</mat-menu>

<mat-menu class="user-status-menu" #userStatus="matMenu">
  <button mat-menu-item (click)="updateUserStatus('online')">
    <span class="w-4 h-4 mr-3 rounded-full bg-green-500"></span>
    <span>{{ "LAYOUT.ONLINE" | transloco }}</span>
  </button>
  <button mat-menu-item (click)="updateUserStatus('away')">
    <span class="w-4 h-4 mr-3 rounded-full bg-amber-500"></span>
    <span>{{ "LAYOUT.AWAY" | transloco }}</span>
  </button>
  <button mat-menu-item (click)="updateUserStatus('busy')">
    <span class="w-4 h-4 mr-3 rounded-full bg-red-500"></span>
    <span>{{ "LAYOUT.BUSY" | transloco }}</span>
  </button>
  <button mat-menu-item (click)="updateUserStatus('not-visible')">
    <span class="w-4 h-4 mr-3 rounded-full bg-gray-400"></span>
    <span>{{ "LAYOUT.INVISIBLE" | transloco }}</span>
  </button>
</mat-menu>
