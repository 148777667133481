<div *ngIf="opened" class="fixed lg:sticky top-0 bottom-0 lg:left-full w-full sm:w-96 lg:w-16 lg:h-screen lg:shadow">
    <div
        class="flex flex-col w-full border-l sm:w-96 h-full transition-transform duration-400 ease-drawer bg-card"
        [ngClass]="{'-translate-x-full sm:-translate-x-96 lg:-translate-x-80 shadow': opened, 'translate-x-0': !opened}">

        <!-- Header -->
        <ng-container *ngIf="!opened">
            <div class="quick-chat-header flex flex-0 h-16 items-center justify-start cursor-pointer" (click)="toggle()">
                <div class="flex flex-auto items-center">
                    <div class="flex flex-0 items-center justify-center w-16">
                        <mat-icon
                            class="icon-size-6"
                            [class.text-white]="session.currentHeaderTransparent()"
                            [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
                    </div>
                    <!-- <div class="text-lg font-medium text-secondary">Sohbet Odası</div>
                    <button 
                        class="ml-auto mr-4"
                        mat-icon-button>
                        <mat-icon [svgIcon]="'heroicons_outline:x'"></mat-icon>
                    </button> -->
                </div>
            </div>
        </ng-container>   
        <ng-container *ngIf="opened">

            <!-- Toggle -->
            <!-- <ng-container *ngIf="!opened || (opened && !selectedRoom)">
                <div class="flex flex-auto items-center justify-center">
                    <div class="flex flex-0 items-center justify-center w-16">
                        <mat-icon
                            class="icon-size-6"
                            [class.text-white]="session.currentHeaderTransparent()"
                            [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
                    </div>
                    <div class="text-lg font-medium text-secondary">Sohbet Odası</div>
                    <button  (click)="toggle()"
                        class="ml-auto mr-4"
                        mat-icon-button>
                        <mat-icon [svgIcon]="'heroicons_outline:x'"></mat-icon>
                    </button>
                </div>
            </ng-container> -->

            <!-- Contact info -->
            <!-- <ng-container *ngIf="opened && selectedRoom">
                <div class="flex flex-auto items-center ml-3">
                    <div class="relative flex flex-0 items-center justify-center w-10 h-10">
                        <ng-container *ngIf="chat.contact.avatar">
                            <img
                                class="w-full h-full rounded-full object-cover"
                                [src]="chat.contact.avatar"
                                alt="Contact avatar"/>
                        </ng-container>
                        <ng-container *ngIf="!chat.contact.avatar">
                            <div class="flex items-center justify-center w-full h-full rounded-full text-lg uppercase bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">
                                {{chat.contact.name.charAt(0)}}
                            </div>
                        </ng-container>
                    </div>
                    <div class="ml-4 text-lg font-medium leading-5 truncate">{{chat.contact.name}}</div>
                    <button
                        class="ml-auto mr-4"
                        mat-icon-button>
                        <mat-icon [svgIcon]="'heroicons_outline:x'"></mat-icon>
                    </button>
                </div>
            </ng-container> -->
        </ng-container>
        <!-- Content -->
        <mat-drawer-container class="flex-auto h-full" [hasBackdrop]="false">
            <!-- Drawer -->
            <mat-drawer
                class="w-full sm:w-100 lg:border-r lg:shadow-none dark:bg-gray-900"
                [autoFocus]="false"
                [(opened)]="drawerOpened"
                #drawer>
                <!-- New Room-->
                <ng-container *ngIf="drawerType == 'newRoom'">
                    <div class="flex flex-col flex-auto h-full overflow-hidden bg-card dark:bg-default">

                        <!-- Header -->
                        <div class="flex flex-0 items-center h-18 -mb-px px-6 bg-gray-50 dark:bg-transparent">
                            <button
                                mat-icon-button
                                (click)="drawer.close()">
                                <mat-icon [svgIcon]="'heroicons_outline:arrow-narrow-left'"></mat-icon>
                            </button>
                            <div class="ml-2 text-2xl font-semibold">Yeni Sohbet</div>
                        </div>
                    
                        <div class="relative overflow-y-auto">
                            <ng-container *ngIf="contacts.length; else noContacts">
                                <ng-container *ngFor="let contact of contacts; let i = index; trackBy: trackByFn">
                                    <!-- Group -->
                                    <ng-container *ngIf="i === 0 || contact.name.charAt(0) !== contacts[i - 1].name.charAt(0)">
                                        <div class="z-10 sticky top-0 -mt-px px-6 py-1 md:px-8 border-t border-b font-medium uppercase text-secondary bg-gray-100 dark:bg-gray-900">
                                            {{contact.name.charAt(0)}}
                                        </div>
                                    </ng-container>
                                    <!-- Contact -->
                                    <div class="z-20 flex items-center px-6 py-4 md:px-8 cursor-pointer border-b hover:bg-gray-100 dark:hover:bg-hover" (click)="createPrivateRoom(contact)">
                                        <div class="flex flex-0 items-center justify-center w-10 h-10 rounded-full overflow-hidden">
                                            <ng-container *ngIf="contact.avatar">
                                                <img
                                                    class="object-cover w-full h-full"
                                                    [src]="session.getImageUrl(contact.avatar)"
                                                    alt="Contact avatar"/>
                                            </ng-container>
                                            <ng-container *ngIf="!contact.avatar">
                                                <div class="flex items-center justify-center w-full h-full rounded-full text-lg uppercase bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">
                                                    {{contact.name.charAt(0)}}
                                                </div>
                                            </ng-container>
                                        </div>
                                        <div class="min-w-0 ml-4">
                                            <div class="font-medium leading-5 truncate">{{contact.title}}</div>
                                            <div class="font-medium leading-5 truncate text-secondary">{{contact.email}}</div>
                                            <div class="leading-5 truncate text-secondary">{{contact.about}}</div>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    
                        <!-- No contacts -->
                        <ng-template #noContacts>
                            <div class="p-8 sm:p-16 border-t text-4xl font-semibold tracking-tight text-center">Kişi yok!</div>
                        </ng-template>
                    
                    </div>                    
                </ng-container>
            </mat-drawer> 
            <div class="quick-chat-header flex flex-0 h-16 items-center justify-start bg-default">
                <div class="flex items-center w-full mr-4 ml-4">
                    <div class="flex items-center mr-1 cursor-pointer">                
                        <user [showAvatar]="true" size="12"></user>
                    </div>
                    <div class="ml-4 font-medium truncate" style="width: 170px;">{{activeMember?.name}}</div>

                    <!-- <div
                        class="flex items-center mr-1 cursor-pointer"
                        (click)="openProfile()">
                        <div class="w-10 h-10">
                            <ng-container *ngIf="profile.avatar">
                                <img
                                    class="object-cover w-full h-full rounded-full"
                                    [src]="profile.avatar"
                                    alt="Profile avatar"/>
                            </ng-container>
                            <ng-container *ngIf="!profile.avatar">
                                <div class="flex items-center justify-center w-full h-full rounded-full text-lg uppercase bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">
                                    {{profile.name.charAt(0)}}
                                </div>
                            </ng-container>
                        </div>
                        <div class="ml-4 font-medium truncate">{{profile.name}}</div>
                    </div> -->
                    <button
                        class=""
                        mat-icon-button
                        (click)="openNewRoom()">
                        <mat-icon [svgIcon]="'heroicons_outline:plus-circle'"></mat-icon>
                    </button>
                    <button
                        class="ml-1 -mr-4"
                        mat-icon-button
                        [matMenuTriggerFor]="chatsHeaderMenu">
                        <mat-icon [svgIcon]="'heroicons_outline:dots-vertical'"></mat-icon>
                        <mat-menu #chatsHeaderMenu>
                            <button mat-menu-item>
                                <mat-icon [svgIcon]="'heroicons_outline:user-group'"></mat-icon>
                                Yeni grup oluştur
                            </button>
                            <button mat-menu-item>
                                <mat-icon [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
                                Yeni görüşme
                            </button>
                            <!-- <button
                                mat-menu-item
                                (click)="openProfile()">
                                <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
                                Profile
                            </button> -->
                            <button mat-menu-item>
                                <mat-icon [svgIcon]="'heroicons_outline:archive'"></mat-icon>
                                Arşiv
                            </button>
                            <button mat-menu-item>
                                <mat-icon [svgIcon]="'heroicons_outline:star'"></mat-icon>
                                Favori
                            </button>
                            <button mat-menu-item>
                                <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
                                Ayarlar
                            </button>
                        </mat-menu>
                    </button>
                </div>
            </div>
            <div class="quick-chat-header flex flex-0 h-16 items-center justify-start bg-default">
                <div class="flex items-center w-full">
                    <!-- Search -->
                    <div class="w-full mb-4 mr-4 ml-4">
                        <!-- <kd-field type="lookup" 
                            [placeholder]="'Bir kişi seçin ya da görüşme başlatın'"
                            [source]="contacts" 
                            textField="name"
                            [(value)]="selectedContact"
                            class="mr-4 w-full h-auto" 
                            (changed)="selectedContactChanged($event)"
                        ></kd-field>                         -->
                        <mat-form-field
                            [subscriptSizing]="'dynamic'" class="fuse-mat-rounded fuse-mat-dense w-full"
                            [floatLabel]="'always'">
                            <mat-icon
                                matPrefix
                                class="icon-size-5"
                                [svgIcon]="'heroicons_solid:search'"></mat-icon>
                            <input
                                matInput
                                [autocomplete]="'off'"
                                [placeholder]="'Bir kişi seçin ya da görüşme başlatın'"
                                (input)="filterRooms(searchField.value)"
                                #searchField>
                        </mat-form-field>
                    </div> 
                </div>
            </div>                  
            <div class="flex flex-auto border-t overflow-hidden w-full" style="height: calc(100vh - 112px) !important">

                <!-- Room list -->
                <div
                    class="flex-0 w-16 h-full overflow-y-auto overscroll-y-contain sm:overflow-hidden sm:overscroll-auto"
                    fuseScrollbar
                    [fuseScrollbarOptions]="{wheelPropagation: false}">
                    <div class="flex-auto">
                        <ng-container *ngFor="let room of rooms; trackBy: trackByFn">
                            <div
                                class="flex items-center py-3 px-4 cursor-pointer"
                                [ngClass]="{'hover:bg-gray-100 dark:hover:bg-hover': !selectedRoom || selectedRoom.id !== room.id,
                                            'bg-primary-50 dark:bg-hover': selectedRoom && selectedRoom.id === room.id}"
                                (click)="selectRoom(room.id)">
                                <div class="relative flex flex-0 items-center justify-center w-8 h-8">
                                    <ng-container *ngIf="room.unreadCount > 0">
                                        <div
                                            class="absolute bottom-0 right-0 flex-0 w-2 h-2 -ml-0.5 rounded-full ring-2 ring-bg-card dark:ring-gray-900 bg-primary dark:bg-primary-500 text-on-primary"
                                            [class.ring-primary-50]="selectedRoom && selectedRoom.id === room.id"></div>
                                    </ng-container>
                                    <ng-container *ngIf="room.avatar">
                                        <img
                                            class="w-full h-full rounded-full object-cover"
                                            [src]="getRoomAvatar(room)"
                                            alt="Contact avatar"/>
                                    </ng-container>
                                    <ng-container *ngIf="!room.avatar">
                                        <div class="flex items-center justify-center w-full h-full rounded-full text-lg uppercase bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">
                                            {{room.name.charAt(0)}}
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>

                <!-- Conversation -->
                <div class="flex flex-col justify-between flex-auto border-l overflow-hidden bg-gray-50 dark:bg-transparent">
                    <ng-container *ngIf="room; else selectRoomOrStartNew">
                        <div class="flex flex-col-reverse overflow-y-auto overscroll-y-contain">
                            <div class="flex flex-col flex-auto shrink p-6">
                                <ng-container *ngFor="let message of room.messages; let i = index; let first = first; let last = last; trackBy: trackByFn">
                                    <!-- Start of the day -->
                                    <ng-container *ngIf="first || (room.messages[i - 1].messageTime | date:'d') !== (message.messageTime | date:'d')">
                                        <div class="flex items-center justify-center my-3 -mx-6">
                                            <div class="flex-auto border-b"></div>
                                            <div class="flex-0 mx-4 text-sm font-medium leading-5 text-secondary">
                                                {{message.messageTime | date: 'longDate'}}
                                            </div>
                                            <div class="flex-auto border-b"></div>
                                        </div>
                                    </ng-container>
                                    <div
                                        class="flex flex-col"
                                        [ngClass]="{'items-end': isMine(message),
                                                    'items-start': !isMine(message),
                                                    'mt-0.5': i > 0 && isMine(room.messages[i - 1]) === isMine(message),
                                                    'mt-3': i > 0 && isMine(room.messages[i - 1]) !== isMine(message)}">
                                        <!-- Bubble -->
                                        <div
                                            class="relative max-w-3/4 px-3 py-2 rounded-lg"
                                            [ngClass]="{'bg-blue-500 text-blue-50': isMine(message),
                                                        'bg-gray-500 text-gray-50': !isMine(message)}">
                                            <!-- Speech bubble tail -->
                                            <ng-container *ngIf="last || isMine(room.messages[i + 1]) !== isMine(message)">
                                                <div
                                                    class="absolute bottom-0 w-3"
                                                    [ngClass]="{'text-blue-500 -right-1 -mr-px mb-px': isMine(message),
                                                                'text-gray-500 -left-1 -ml-px mb-px -scale-x-1': !isMine(message)}">
                                                    <ng-container *ngTemplateOutlet="speechBubbleExtension"></ng-container>
                                                </div>
                                            </ng-container>
                                            <!-- Message -->
                                            <div
                                                class="min-w-4 leading-5"
                                                [innerHTML]="message.body">
                                            </div>
                                        </div>
                                        <!-- Time -->
                                        <ng-container
                                            *ngIf="first
                                        || last
                                        || isMine(room.messages[i + 1]) !== isMine(message)
                                        || room.messages[i + 1].messageTime !== message.messageTime">
                                            <div
                                                class="my-0.5 text-sm font-medium text-secondary"
                                                [ngClass]="{'mr-3': isMine(message),
                                                            'ml-3': !isMine(message)}">
                                                {{message.messageTime | date:'HH:mm'}}
                                            </div>
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </div>
                        </div>

                        <!-- Message field -->
                        <div class="flex items-end p-4 border-t bg-gray-50 dark:bg-transparent">
                            <mat-form-field [subscriptSizing]="'dynamic'" class="fuse-mat-dense fuse-mat-bold w-full">
                                <textarea
                                    matInput
                                    cdkTextareaAutosize
                                    placeholder="Bir mesaj yazın"
                                    #messageInput (keypress)="messageInputKeyPress($event)"></textarea>
                            </mat-form-field>
                            <!-- <div class="flex items-center h-11 my-px ml-4">
                                <button
                                    mat-icon-button>
                                    <mat-icon
                                        class="rotate-90"
                                        [svgIcon]="'heroicons_outline:paper-airplane'"></mat-icon>
                                </button>
                            </div> -->
                        </div>
                    </ng-container>
                </div>
            </div>
        </mat-drawer-container>
    </div>
</div>

<!-- Select room or start new template -->
<ng-template #selectRoomOrStartNew>
    <div class="flex flex-col flex-auto items-center justify-center w-full h-full p-4">
        <mat-icon
        class="icon-size-24"
        [svgIcon]="'heroicons_outline:chat'"></mat-icon>
    <div class="mt-4 text-xl text-center font-medium tracking-tight text-secondary">Bir kişi seçin ya da görüşme başlatın</div>
    </div>
</ng-template>

<!-- Speech bubble tail SVG -->
<!-- @formatter:off -->
<ng-template #speechBubbleExtension>
    <svg width="100%" height="100%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M1.01522827,0.516204834 C-8.83532715,54.3062744 61.7609863,70.5215302 64.8009949,64.3061218 C68.8074951,54.8859711 30.1663208,52.9997559 37.5036011,0.516204834 L1.01522827,0.516204834 Z" fill="currentColor" fill-rule="nonzero"></path>
        </g>
    </svg>
</ng-template>
<!-- @formatter:on -->
