//import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, of, catchError, map, tap } from 'rxjs';
import _ from 'lodash';
import { IKediConfig, IApiDataDumpOptions, IApiDataLoadOptions, IKediDataResult, IKediResponse, IApiViewLoadOptions, IVOEResponse, KediDataOptions } from './api.types';
import { KediFile } from '../../entities/core.entities';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    private http: HttpClient, 
    ) {
      //super();
  }
  token: string;
  apiUrl: string;
  //cdnUrl: string;
  currentLang: string = "tr";
  currentLangIdx: number = 0;
  currentSite: string = "";
  loadConfig() {
    return new Promise((resolve) => {
      this.http
        .get('config.json?rand=' + (new Date()).getTime())
        .subscribe((data: IKediConfig) => {
          this.apiUrl = data.api.url;
          // this.cdnUrl = data.site.cdn;
          resolve(data);
        });
    });
  }

  public setLanguage(id: string, idx: number): void
  {
    this.currentLang = id;
    this.currentLangIdx = idx;
  }

  public setSite(site: string): void
  {
    this.currentSite = site;
  }

  public kediGetBlob(url: string, auth: boolean = true): Observable<Blob> {
    var headers: HttpHeaders = null;
    if (auth) {
      headers = new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'bearer ' + this.token, 'lang': this.currentLang, 'site': this.currentSite });
    }
    else {
      headers = new HttpHeaders({ 'Content-Type': 'application/json','anonymous':'true', 'lang': this.currentLang, 'site': this.currentSite });
    }
    return this.http.get(this.apiUrl + '/' + url, {
      headers: headers, 
      responseType: 'blob',
      observe: 'body'
    });
  }

  public voeGetBlob(url: string, auth: boolean = true, addHeaders: { name: string, value: string } [] = null): Observable<Blob> {
    var headers: HttpHeaders = null;
    if (auth) {
      headers = new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'bearer ' + this.token, 'lang': this.currentLang, 'site': this.currentSite, 'Token': !addHeaders || addHeaders.length == 0 ? "" : addHeaders[0].value });
    }
    else {
      headers = new HttpHeaders({ 'Content-Type': 'application/json','anonymous':'true', 'lang': this.currentLang, 'site': this.currentSite });
    }
    return this.http.get(this.apiUrl + '/' + url, {
      headers: headers, 
      responseType: 'blob',
      observe: 'body'
    });
  }

  public kediGet<T>(url:string, auth: boolean = true, addHeaders: { name: string, value: string } [] = null): Observable<IKediResponse<T>> {
    var headers: HttpHeaders = null;
    if (auth) {
      headers = new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'bearer ' + this.token, 'lang': this.currentLang, 'site': this.currentSite, 'Token': !addHeaders || addHeaders.length == 0 ? "" : addHeaders[0].value });
    }
    else {
      headers = new HttpHeaders({ 'Content-Type': 'application/json','anonymous':'true', 'lang': this.currentLang, 'site': this.currentSite });
    }
    if (!url.includes("?rand")) url += (url.includes("?") ? "&rand=" : "?rand=")+ (new Date()).getTime();
    return this.http.get<IKediResponse<T>>(this.apiUrl + '/' + url, { headers: headers }).pipe(
      catchError((error) => {
        let errorResult: IKediResponse<T> = { Succeded: false, Message: error.message, Result: null };
        return of(errorResult);
      })
    );
  }

  public voeGet<T>(url:string, auth: boolean, addHeaders: { name: string, value: string } [] = null): Observable<IKediResponse<T>> {
    return this.kediGet<IVOEResponse<T>>(url, auth, addHeaders).pipe(
      map(r => {
        let voeResponse: IKediResponse<T> = { Succeded: false, Message: r.Message, Result: null };
        if (!r.Succeded) return voeResponse;
        else {
          if (!r.Result.Succeded) {
            voeResponse.Message = r.Result.Message;
            return voeResponse;
          }
          voeResponse.Succeded = true;
          voeResponse.Result = r.Result?.Data;
          voeResponse.Message = null;
          return voeResponse;
        }
      }),
      catchError((error) => {
        let errorResult: IKediResponse<T> = { Succeded: false, Message: error.message, Result: null };
        return of(errorResult);
      })
    );
  }

  public kediPost<T>(url:string, body: any, auth: boolean, addHeaders: { name: string, value: string } [] = null): Observable<IKediResponse<T>> {
    var headers: any = null;
    if (auth) {
      headers = { 'Content-Type': 'application/json','Authorization':'bearer ' + this.token, 'lang': this.currentLang, 'site': this.currentSite, 'Token': !addHeaders || addHeaders.length == 0 ? "" : addHeaders[0].value };

      // headers = new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'bearer ' + this.token, 'lang': this.currentLang, 'site': this.currentSite, 'Token': !addHeaders || addHeaders.length == 0 ? "" : addHeaders[0].value });
    }
    else {
      headers = { 'Content-Type': 'application/json','anonymous':'true', 'lang': this.currentLang, 'site': this.currentSite };

      // headers = new HttpHeaders({ 'Content-Type': 'application/json','anonymous':'true', 'lang': this.currentLang, 'site': this.currentSite });
    }
    if (!url.includes("?rand")) url += (url.includes("?") ? "&rand=" : "?rand=")+ (new Date()).getTime();
    return this.http.post<IKediResponse<T>>(this.apiUrl + '/' + url, body, { headers: headers }).pipe(
      catchError((error) => {
        let errorResult: IKediResponse<T> = { Succeded: false, Message: error.message, Result: null };
        return of(errorResult);
      })
    );
  }

  public voePost<T>(url:string, body: any, auth: boolean, addHeaders: { name: string, value: string } [] = null): Observable<IKediResponse<T>> {
    return this.kediPost<IVOEResponse<T>>(url, body, auth, addHeaders).pipe(
      map(r => {
        let voeResponse: IKediResponse<T> = { Succeded: false, Message: r.Message, Result: null };
        if (!r.Succeded) return voeResponse;
        else {
          if (!r.Result.Succeded) {
            voeResponse.Message = r.Result.Message;
            return voeResponse;
          }
          voeResponse.Succeded = true;
          voeResponse.Result = r.Result?.Data;
          voeResponse.Message = null;
          return voeResponse;
        }
      }),
      catchError((error) => {
        let errorResult: IKediResponse<T> = { Succeded: false, Message: error.message, Result: null };
        return of(errorResult);
      })
    );
  }

  public kediPut<T>(url:string, body: any, auth: boolean = true, addHeaders: { name: string, value: string } [] = null): Observable<IKediResponse<T>> {
    var headers: HttpHeaders = null;
    if (auth) {
      headers = new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'bearer ' + this.token, 'lang': this.currentLang, 'site': this.currentSite, 'Token': !addHeaders || addHeaders.length == 0 ? "" : addHeaders[0].value });
    }
    else {
      headers = new HttpHeaders({ 'Content-Type': 'application/json','anonymous':'true', 'lang': this.currentLang, 'site': this.currentSite });
    }
    if (addHeaders) { addHeaders.forEach(h => { headers.append(h.name, h.value); }); }
    if (!url.includes("?rand")) url += (url.includes("?") ? "&rand=" : "?rand=")+ (new Date()).getTime();
    return this.http.put<IKediResponse<T>>(this.apiUrl + '/' + url, body, { headers: headers }).pipe(
      catchError((error) => {
        let errorResult: IKediResponse<T> = { Succeded: false, Message: error.message, Result: null };
        return of(errorResult);
      })
    );
  }

  public kediDelete<T>(url:string, auth: boolean = true, addHeaders: { name: string, value: string } [] = null): Observable<IKediResponse<T>> {
    var headers: HttpHeaders = null;
    if (auth) {
      headers = new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'bearer ' + this.token, 'lang': this.currentLang, 'site': this.currentSite, 'Token': !addHeaders || addHeaders.length == 0 ? "" : addHeaders[0].value });
    }
    else {
      headers = new HttpHeaders({ 'Content-Type': 'application/json','anonymous':'true', 'lang': this.currentLang, 'site': this.currentSite });
    }
    if (addHeaders) { addHeaders.forEach(h => { headers.append(h.name, h.value); }); }
    if (!url.includes("?rand")) url += (url.includes("?") ? "&rand=" : "?rand=")+ (new Date()).getTime();
    return this.http.delete<IKediResponse<T>>(this.apiUrl + '/' + url, { headers: headers }).pipe(
      catchError((error) => {
        let errorResult: IKediResponse<T> = { Succeded: false, Message: error.message, Result: null };
        return of(errorResult);
      })
    );
  }


  
  public kediPostBlob(url: string, body: any, auth: boolean = true, addHeaders: { name: string, value: string } [] = null): Observable<Blob> {
    var headers: HttpHeaders = null;
    if (auth) {
      headers = new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'bearer ' + this.token, 'lang': this.currentLang, 'site': this.currentSite, 'Token': !addHeaders || addHeaders.length == 0 ? "" : addHeaders[0].value });
    }
    else {
      headers = new HttpHeaders({ 'Content-Type': 'application/json','anonymous':'true', 'lang': this.currentLang, 'site': this.currentSite });
    }
    if (!url.includes("?rand")) url += (url.includes("?") ? "&rand=" : "?rand=")+ (new Date()).getTime();
    if (!url.includes("kedi_wrap")) url += "&kedi_wrap=false";

    return this.http.post(this.apiUrl + '/' + url, body, {
      headers: headers, 
      responseType: 'blob',
      observe: 'body'
    });
  }

  public noWrapPost(url:string, body: any, auth: boolean, addHeaders: { name: string, value: string } [] = null): Observable<any> {
    var headers: HttpHeaders = null;
    if (auth) {
      headers = new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'bearer ' + this.token, 'lang': this.currentLang, 'site': this.currentSite, 'Token': !addHeaders || addHeaders.length == 0 ? "" : addHeaders[0].value });
    }
    else {
      headers = new HttpHeaders({ 'Content-Type': 'application/json','anonymous':'true', 'lang': this.currentLang, 'site': this.currentSite });
    }
    if (!url.includes("?rand")) url += (url.includes("?") ? "&rand=" : "?rand=")+ (new Date()).getTime();
    return this.http.post<any>(this.apiUrl + '/' + url, body, { headers: headers }).pipe(
      catchError((error) => {
        let errorResult = { Succeded: false, Message: error.message, Result: null };
        return of(errorResult);
      })
    );
  }

  public getView(entity:string, body: IApiViewLoadOptions): Observable<IKediResponse<any>> {
    var headers: HttpHeaders = null;
    headers = new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'bearer ' + this.token, 'lang': this.currentLang, 'site': this.currentSite });
    let url = entity;
    if (!url.includes("?rand")) url += (url.includes("?") ? "&rand=" : "?rand=")+ (new Date()).getTime();

    return this.http.post<IKediResponse<any>>(this.apiUrl + '/Data/View/' + url, body, { headers: headers }).pipe(
      catchError((error) => {
        let errorResult: IKediResponse<any> = { Succeded: false, Message: error.message, Result: null };
        return of(errorResult);
      })
    );
  }

  public getData(entity:string, body: IApiDataLoadOptions): Observable<IKediResponse<any>> {
    if (!body) {
      let response: IKediResponse<any> = {
        Message: null,
        Succeded: true,
        Result: []
      };
      return of(response);
    }
    var headers: HttpHeaders = null;
    headers = new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'bearer ' + this.token, 'lang': this.currentLang, 'site': this.currentSite });
    let url = entity;
    if (!url.includes("?rand")) url += (url.includes("?") ? "&rand=" : "?rand=")+ (new Date()).getTime();

    return this.http.post<IKediResponse<any>>(this.apiUrl + '/Data/' + url, body, { headers: headers }).pipe(
      catchError((error) => {
        let errorResult: IKediResponse<any> = { Succeded: false, Message: error.message, Result: null };
        return of(errorResult);
      })
    );
  }

  public getDataList<T>(type: { new(): T ;}, options: IApiDataLoadOptions = null): Observable<IKediResponse<T[]>> {
    let sample = new type();
    let entity = sample.constructor.name;
    var headers: HttpHeaders = null;
    headers = new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'bearer ' + this.token, 'lang': this.currentLang, 'site': this.currentSite });
    let url = entity;
    if (!url.includes("?rand")) url += (url.includes("?") ? "&rand=" : "?rand=")+ (new Date()).getTime();

    return this.http.post<IKediResponse<IKediDataResult<T>>>(this.apiUrl + '/Data/' + url, options, { headers: headers }).pipe(
      map((response => {
        let result: IKediResponse<T[]>;
        if (response.Succeded) result = { Succeded: true, Message: null, Result: (!response.Result ? [] : response.Result.Data) };
        else result = { Succeded: false, Message: response.Message, Result: null };
        return result;
      })),
      catchError((error) => {
        let errorResult: IKediResponse<T[]> = { Succeded: false, Message: error.message, Result: null };
        return of(errorResult);
      })
    );
  }

  public getDataFirst<T>(type: { new(): T ;}, where: string = null): Observable<IKediResponse<T>> {
    let sample = new type();
    let entity = sample.constructor.name;
    var headers: HttpHeaders = null;
    let options: IApiDataLoadOptions = where ? { Filter: [{ Property:"XXX", Operator:"dynamic", Value: where }]} : null;
    headers = new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'bearer ' + this.token, 'lang': this.currentLang, 'site': this.currentSite });
    let url = entity;
    if (!url.includes("?rand")) url += (url.includes("?") ? "&rand=" : "?rand=")+ (new Date()).getTime();

    return this.http.post<IKediResponse<IKediDataResult<T>>>(this.apiUrl + '/Data/' + entity, options, { headers: headers }).pipe(
      map((response => {
        let result: IKediResponse<T>;
        if (response.Succeded) result = { Succeded: true, Message: null, Result: response.Result?.Data && response.Result.Data.length > 0 ? response.Result.Data[0] : null };
        else result = { Succeded: false, Message: response.Message, Result: null };
        return result;
      })),
      catchError((error) => {
        let errorResult: IKediResponse<T> = { Succeded: false, Message: error.message, Result: null };
        return of(errorResult);
      })
    );
  }

  // public saveRow<T>(row): Observable<IKediResponse<T>> {
  //   let entity = row.constructor.name;
  //   var headers: HttpHeaders = null;
  //   headers = new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'bearer ' + this.token, 'lang': this.currentLang, 'site': this.currentSite });
  //   let url = entity + "/save";
  //   if (!url.includes("?rand")) url += (url.includes("?") ? "&rand=" : "?rand=")+ (new Date()).getTime();

  //   return this.http.post<IKediResponse<T>>(this.apiUrl + '/Data/' + url, row, { headers: headers }).pipe(
  //     catchError((error) => {
  //       let errorResult: IKediResponse<T> = { Succeded: false, Message: error.message, Result: null };
  //       return of(errorResult);
  //     })
  //   );
  // }

  // public deleteRow<T>(row): Observable<IKediResponse<boolean>> {
  //   let entity = row.constructor.name;
  //   var headers: HttpHeaders = null;
  //   headers = new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'bearer ' + this.token, 'lang': this.currentLang, 'site': this.currentSite });
  //   let url = entity + "/delete";
  //   if (!url.includes("?rand")) url += (url.includes("?") ? "&rand=" : "?rand=")+ (new Date()).getTime();

  //   return this.http.post<IKediResponse<boolean>>(this.apiUrl + '/Data/' + url, row, { headers: headers }).pipe(
  //     catchError((error) => {
  //       let errorResult: IKediResponse<boolean> = { Succeded: false, Message: error.message, Result: null };
  //       return of(errorResult);
  //     })
  //   );
  // }

  public getEntityData(options: KediDataOptions): Observable<IKediResponse<any>> {
    return this.getData(options.entity, options.asKediDataLoadOptions()).pipe(
      tap((response: IKediResponse<any>) => {
          if (!response.Succeded) {
              return throwError(() => new Error(response.Message)); 
          }
          return response;
      }),
      map(response => {
          var list = !response.Result ? [] : (options.property && options.property != "" ? response.Result[options.property] : response.Result.Data);
          if (options.mapFunction) {
              list = options.mapFunction(list);                                
          }
          return list;
      }),
      catchError(err => {
          return throwError(() => err)
      })
  );    
  }

  public getDataById(entity:string, id: number): Observable<IKediResponse<any>> {
    var headers: HttpHeaders = null;
    headers = new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'bearer ' + this.token, 'lang': this.currentLang, 'site': this.currentSite });
    let url = entity + '/' + id;
    if (!url.includes("?rand")) url += (url.includes("?") ? "&rand=" : "?rand=")+ (new Date()).getTime();

    return this.http.get<IKediResponse<any>>(this.apiUrl + '/Data/' + url, { headers: headers });
  }

  public putData(entity:string, id: number, body: IApiDataDumpOptions): Observable<IKediResponse<any>> {
    var headers: HttpHeaders = null;
    headers = new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'bearer ' + this.token, 'lang': this.currentLang, 'site': this.currentSite });
    let url = entity + '/' + id;
    if (!url.includes("?rand")) url += (url.includes("?") ? "&rand=" : "?rand=")+ (new Date()).getTime();
    return this.http.put<IKediResponse<any>>(this.apiUrl + '/Data/' + url, body, { headers: headers });
  }

  public postData(entity:string, body: IApiDataDumpOptions): Observable<IKediResponse<any>> {
    var headers: HttpHeaders = null;
    headers = new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'bearer ' + this.token, 'lang': this.currentLang, 'site': this.currentSite });
    let url = entity + '/0';
    if (!url.includes("?rand")) url += (url.includes("?") ? "&rand=" : "?rand=")+ (new Date()).getTime();

    return this.http.post<IKediResponse<any>>(this.apiUrl + '/Data/' + url, body, { headers: headers });
  }

  public deleteData(entity:string, id: number): Observable<IKediResponse<any>> {
    var headers: HttpHeaders = null;
    headers = new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'bearer ' + this.token, 'lang': this.currentLang, 'site': this.currentSite });
    let url = entity + '/' + id;
    if (!url.includes("?rand")) url += (url.includes("?") ? "&rand=" : "?rand=")+ (new Date()).getTime();

    return this.http.delete<IKediResponse<any>>(this.apiUrl + '/Data/' + url, { headers: headers });
  }

  public getSession(): Observable<IKediResponse<any>> {
    var headers: HttpHeaders = null;
    headers = new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'bearer ' + this.token, 'lang': this.currentLang, 'site': this.currentSite });
    let url = "session";
    if (!url.includes("?rand")) url += (url.includes("?") ? "&rand=" : "?rand=")+ (new Date()).getTime();

    return this.http.post<IKediResponse<any>>(this.apiUrl + '/Data/' + url, null, { headers: headers });
  }

  public getState(site: string): Observable<IKediResponse<any>> {
    var data = { Site: site, Signatures: []};
    var localeSignature = localStorage.getItem("localeSignature");
    if (localeSignature) data.Signatures.push({ Key: "locale", Signature: localeSignature, Value: null });
    var headers: HttpHeaders = null;
    headers = new HttpHeaders({ 'Content-Type': 'application/json','anonymous':'true', 'Authorization':'bearer ' + this.token, 'lang': this.currentLang, 'site': this.currentSite });
    let url = "state";
    if (!url.includes("?rand")) url += (url.includes("?") ? "&rand=" : "?rand=")+ (new Date()).getTime();

    return this.http.post<IKediResponse<any>>(this.apiUrl + '/web/' + url, data, { headers: headers });
  }

  public setToken(token:string) {
    this.token = token;
  }

  public setAvatar(file: KediFile): Observable<IKediResponse<any>> {
    return this.kediPost("account/avatar", file, true);
  }

  public authenticate(email, password, siteCode, lang, returnUrl) : Observable<IKediResponse<any>> {
    //var password_encrypted = CryptoJS.AES.encrypt(password, "kedi").toString();
    var data = "{ \"UserName\":\"" + email + "\", \"Password\":\"" + password + "\", \"Language\":\"" + lang + "\", \"SiteCode\":\"" + siteCode + "\", \"ReturnUrl\": \"" + returnUrl + "\" }";
    return this.kediPost("account/login", data, false);
  }

  public authenticateByEmail(email, siteCode, lang, returnUrl: string) : Observable<IKediResponse<any>> {
    var data = "{ \"UserName\":\"" + email + "\", \"Language\":\"" + lang + "\", \"SiteCode\":\"" + siteCode + "\", \"ReturnUrl\": \"" + returnUrl + "\" }";
    return this.kediPost("account/loginbyemail", data, false);
  }

  public loginByToken() : Observable<IKediResponse<any>> {
    return this.kediPost("account/loginbytoken",null, true);
  }

  public register(name, email, username, password, adConfirmation: boolean, returnUrl: string, signupToken:string) : Observable<IKediResponse<any>> {
    var data = "{ \"Name\":\"" + name + "\", \"Email\":\"" + email + "\", \"UserName\":\"" + username + "\", \"Password\":\"" + password + "\", \"AdConfirmation\":\"" + adConfirmation + "\", \"ReturnUrl\": \"" + returnUrl + "\", \"SiteCode\": \"" + signupToken + "\" }";
    return this.kediPost("account/register", data, false);
  }

  public sendConfirmation(email: string, returnUrl: string): Observable<IKediResponse<any>> {
    var data = "{ \"Username\":\"" + email + "\", \"ReturnUrl\": \"" + returnUrl + "\" }";
    return this.kediPost("account/sendconfirmation", data, false);
  }

  public confirmEmail(userid: string, code: string): Observable<IKediResponse<any>> {
    var data = "{ \"UserId\":\"" + userid + "\", \"Code\": \"" + code + "\" }";
    return this.kediPost("account/confirm", data, false);
  }

  public forgotPassword(identity: string, returnUrl: string, useEmail:boolean): Observable<IKediResponse<any>> {
    var data = "{ \"" + (useEmail ? "Email" : "Username") + "\":\"" + identity + "\", \"ReturnUrl\": \"" + returnUrl + "\" }";
    return this.kediPost("account/forgotpassword", data, false);
  }

  public confirmPassword(userid: string, code: string, newPassword: string): Observable<IKediResponse<any>> {
    var data = "{ \"UserId\":\"" + userid + "\", \"Code\": \"" + code + "\", \"NewPassword\": \"" + newPassword + "\" }";
    return this.kediPost("account/confirmpassword", data, false);
  }

  public resetPassword(userid: string, resetToken:string, newPassword: string): Observable<IKediResponse<any>> {
    if(!userid || !resetToken) {
      var data = "{ \"NewPassword\": \"" + newPassword + "\" }";
      return this.kediPost("account/resetpassword", data, true);
    }
    else {
      var data = "{ \"UserId\":\"" + userid + "\", \"Code\": \"" + resetToken + "\", \"NewPassword\": \"" + newPassword + "\" }";
      return this.kediPost("account/resetpassword", data, false);
    }
  }
}


