import { toObservable } from '@angular/core/rxjs-interop';
import { HttpErrorResponse } from '@angular/common/http';
import { computed, inject, Inject, Injectable, signal, WritableSignal } from '@angular/core';
import { BehaviorSubject, from, Observable, concatAll, map, ReplaySubject, throwError, of, forkJoin, tap, catchError, switchMap } from 'rxjs';
import { IKediConfig, IKediResponse, ApiCachedValue, ApiLanguage, ApiLocale, ApiState, KediSharedList, KediSharedValue, KediSharedZone, ApiFileActionRequest, ApiRoute, KediPage } from '../api/api.types';
import { IKediTemplate, IKediTemplateConfigService, IKediTemplateConfirmationConfig, IKediTemplateConfirmService, IKediTemplateDrawerService, SessionCompany, SessionFirm, SessionRole, User } from './session.types';
import { NavigationService } from '../navigation/navigation.service';
import { ApiService } from '../api/api.service';
import { KediFile } from '../../entities/core.entities';
import dayjstr from 'dayjs/locale/tr';
import dayjs from 'dayjs/esm/index.js';
import { KEDI_SITE_SETTINGS, KediConfig, KediSiteSettings } from './session.provider';
import { KEDI_LANGS } from './session.provider';

//import { FuseSplashScreenService } from '../fuse/services/splash-screen/splash-screen.service';
import { Navigation, NavigationItem, IKediShortcut } from '../navigation/navigation.types';

import { TranslocoService } from '@jsverse/transloco';
import _ from 'lodash';
// import { KediApp } from '../model/app.class';
// import { KediService } from '../model/service.class';
//import { PrimeNGConfig } from 'primeng/api';
//import { FuseConfigService } from '../fuse/services/config';
//import { MatDialog } from '@angular/material/dialog';
//import { FuseConfirmationConfig, FuseConfirmationService } from '../fuse/services/confirmation';
//import { Router } from '@angular/router';
//import { Shortcut } from '../layout/common/shortcuts/shortcuts.types';
import { MessageService } from 'primeng/api';
//import { FuseDrawerService } from '../fuse/components/drawer/drawer.service';
//import { FuseHorizontalNavigationComponent } from '../fuse/components/navigation/horizontal/horizontal.component';
//import { FuseNavigationService } from '../fuse/components/navigation/navigation.service';
//import { HeaderButtonsComponent } from '../layout/common/header-buttons/header-buttons.component';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { ensureValue } from '../../pipes';
// import { ChatService } from '../chat/chat.service';
@Injectable({ providedIn: 'root' })
export class SessionService /*extends KediService */ {
  public platform: "Mobile" | "Web" = "Web";
  public platformType: "Android" | "IOS" | "Other" | "Edge" | "Firefox" | "Chrome";
  config: KediConfig;

  public shared: KediSharedZone;
  public currentLang: string;
  public currentLangId: number;
  public token: string;
  private _authenticated: boolean = false;
  private _administrator: boolean = false;
  private _confirmed: boolean = false;

  //private _routes: ApiRoute[] = [];
  private _pages: KediPage[] = [];
  private _localeReadySubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  private _stateLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public get LocaleReady$(): Observable<boolean> {
    return this._localeReadySubject.asObservable();
  }
  public get StateLoadedSubject$(): Observable<boolean> {
    return this._stateLoadedSubject.asObservable();
  }
  public get Navigation(): ReplaySubject<Navigation> {
    return this._navigationService.navigationSubject;
  }
  public mergeLocale(localeCode: string, locale: any) {
    Object.keys(locale).forEach(lang => {
      let trn = this._translocoService.getTranslation(lang);
      Object.keys(locale[lang]).forEach(key => {
        if (!trn[localeCode + "." + key])
          this._translocoService.setTranslationKey(localeCode + "." + key, locale[lang][key], { lang: lang });
      });
    });

  }

  // public checkRoute(route: string) {
  //   if (route == '/' ||
  //     route == '/signed-in-redirect' ||
  //     route == '/confirmation-required' ||
  //     route == '/forgot-password' ||
  //     route == '/password-changed' ||
  //     route == '/reset-password' ||
  //     route == '/sign-in' ||
  //     route == '/sign-up' ||
  //     route == '/sign-out' ||
  //     route == '/unlock-session'
  //   ) return true;
  //   let i = this._pages?.findIndex(r => r.Name == route);
  //   if (i == null || i < 0) {
  //     let exists = false;
  //     this._routes.forEach(r => {
  //       if (route.startsWith(r.Code)) { exists = true; return; };
  //     });
  //     return exists;
  //   }
  //   return true;
  // }
  public checkPage(page: string) {

    let _page = this._pages.find(p => p.Name == page);
    if (!_page) return true;
    // if (_page.NeedsAdmin && !this._authenticated) return false;
    return _page.Authorized;
  }
  public getPage(page: string) {
    return this._pages.find(p => p.Name == page);
  }
  public Shortcuts: IKediShortcut[];
  //public Modules: ApiModule[];

  //public Routes: ApiRoute[];
  public ActiveRole: SessionRole;
  public ActiveFirm: SessionFirm;
  public ActiveCompany: SessionCompany;

  //public NgModules: ApiNgModule[] = [];

  // public apiUrl: string;
  //public cdnUrl: string;
  // public siteCode: string;
  public languages: ApiLanguage[];
  public locales: ApiLocale[];


  public user: WritableSignal<User> = signal<User>({});
  public user$ = toObservable(this.user);

  apiUrl = computed(() => this.config.apiUrl);
  enabledUser = computed(() => this.isLoggedIn() && this.user().Enabled);
  userId = computed(() => this.user().Id);
  username = computed(() => this.user().UserName);
  userFirstName = computed(() => this.user().FirstName);
  userLastName = computed(() => this.user().LastName);
  userFullName = computed(() => this.user().FirstName + (this.user().MiddleName ? "" + this.user().MiddleName : "") + (this.user().LastName ? "" + this.user().LastName : ""));
  userEmail = computed(() => this.user().Email);
  userRole = computed(() => this.user().RoleCode);
  userAdmin = computed(() => this.userRole() == 'Admin' || this.userRole() == 'System');
  userLetters = computed(() => {
    if (!this.user()) return "X";
    if (!this.user().FirstName && !this.user().MiddleName && !this.user().LastName) return "X";
    var letter = this.user().FirstName ? this.user().FirstName.charAt(0) : this.user().MiddleName ? this.user().MiddleName.charAt(0) : "";
    if (this.user().LastName) letter += this.user().LastName.charAt(0);
    return letter;
  });
  userAvatar = computed(() => this.user()?.Avatar);
  userStatus = computed(() => this.user()?.Status);

  public isLoggedIn: WritableSignal<boolean> = signal<boolean>(false);
  public isLoggedIn$ = toObservable(this.isLoggedIn);

  public avatarUrl: WritableSignal<string> = signal<string>(null);


  public currentTheme: WritableSignal<string> = signal<string>("kedi");
  public currentScheme: WritableSignal<string> = signal<string>("auth");
  public currentLayout: WritableSignal<string> = signal<string>("classy");
  public currentHeaderTransparent: WritableSignal<boolean> = signal<boolean>(false);

  setTheme(value: string) {
    this.currentTheme.set(value);
  }

  setScheme(value: string) {
    this.currentScheme.set(value);
  }

  setLayout(value: string) {
    this.currentLayout.set(value);
  }

  setHeaderTransparent(value: boolean) {
    this.currentHeaderTransparent.set(value);
  }
  //public _activeParentDrawer: any;
  //private _appComponent: any;
  //favIcon: HTMLLinkElement = document.querySelector('#appIcon');
  // private _title: HTMLTitleElement = document.querySelector('#appTitle');
  // private _splashLogo: HTMLImageElement = document.querySelector('#appSplashLogo');
  // private _splashStyle: HTMLLinkElement = document.querySelector('#appSplashStyle');
  toastPosition: string = "top-right"; // center | top-right | top-left | bottom-right | bottom-left | top-center | bottom-center | center
  public lastConfirmationId: string;
  public lastConfirmationCode: string;
  public MustChangePassword: boolean;
  private _changePasswordRoute: string;
  public get ChangePasswordRoute() {
    return this._changePasswordRoute;
  }
  customFunction: any;

  /** User Service Start */
  // private _user: BehaviorSubject<User> = new BehaviorSubject<User>({
  //   Id: null,
  //   Email: "",
  //   RoleCode: "",
  //   UserName: "",
  //   FirstName: "",
  //   MiddleName: "",
  //   LastName: "",
  //   Avatar: null,
  //   Status: "",
  //   EmailVerified: false,
  //   PasswordVerified: false,
  //   PhoneVerified: false,
  //   Phone: "",
  //   About: "",
  //   Company: "",
  //   Country: "tr",
  //   DefaultLanguage: "tr",
  //   Title: ""
  // });
  // private _loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public template$: BehaviorSubject<IKediTemplate> = new BehaviorSubject<IKediTemplate>(null);
  public get navigator$() {
    return navigator;
  }
  /** User Service End */
  private _siteSettings: KediSiteSettings;
  private _kediLangs: ApiLanguage[];


  constructor(
    //@Inject(LOCAL_STORAGE) private storage: StorageService,
    @Inject(DOCUMENT) private _document: any,
    @Inject(KEDI_SITE_SETTINGS) _siteSettings: KediSiteSettings,
    @Inject(KEDI_LANGS) _kediLangs: ApiLanguage[],
    private _api: ApiService,
    //private router: Router,
    //private dialog: MatDialog,
    //private splash: FuseSplashScreenService,
    private _translocoService: TranslocoService,
    //private _fuseSidebarService: FuseSidebarService,
    // private primengConfig: PrimeNGConfig,
    // private _fuseConfigService: FuseConfigService,
    //private _fuseConfirmationService: IKediConfirmationService,
    private _navigationService: NavigationService,
    //private _chatService: ChatService,
    private messageService: MessageService,
    //private _fuseDrawerService: FuseDrawerService,
    //private _fuseNavigationService: FuseNavigationService,
    private titleService: Title,
    private metaService: Meta
  ) {
    console.log('SessionService oluştu ', Math.random());
    /** UserService Start */
    this._siteSettings = _siteSettings;
    this._kediLangs = _kediLangs;
    dayjs.locale(dayjstr);
    this.checkPlatform();
    this.template$.next({
      confirmService: null,
      drawerService: null,
      templateService: null
    });
    this.clean();

    this.template$
      .subscribe((template: IKediTemplate) => {
        this._template = template;
      });
    /** UserService End */

    //super();

    this.shared = new KediSharedZone();
    //this.create<Navigation>("Navigation");
    //this.create<User>("User");
    //this.create<string>("AvatarUrl");
    //this.create<any[]>("Shortcuts");
    //this.create<ApiRoute[]>("Routes");
    //this.create<ApiModule[]>("Modules");
    //this.create<any>("KediEntityType");

    //this.setKediEntity("User",User);


    // this.customFunction = {};
    // this.customFunction["openSettingsBar"] = () => {
    //   _fuseSidebarService.getSidebar("themeOptionsPanel").toggleOpen();
    // };
    this.StateLoadedSubject$.pipe(ensureValue(this)).subscribe(() => {
      this.hideMenuItems(null, (item: NavigationItem) => {
        if (item.guard) {
          switch (item.guard) {
            case "Admin": return !this.isLoggedIn() || !this.userAdmin();
            case "User": return !this.isLoggedIn();
            case "EnabledUser": return !this.isLoggedIn() || !this.user().Enabled;
            case "DisabledUser": return !this.isLoggedIn() || this.user().Enabled;
            case "Anonymous": return this.isLoggedIn();
            default: return false;
          }
        }
        return false;
      });
      this.setMenu();
    });
  }

  /** UserService Start */
  checkPlatform() {
    var ua = navigator.userAgent;
    if (/Android/i.test(ua)) {
      this.platform = "Mobile";
      this.platformType = "Android";
    }
    else if (/iPhone|iPad|iPod|Opera Mini/i.test(ua)) {
      this.platform = "Mobile";
      this.platformType = "IOS";
    }
    else if (/webOS|BlackBerry|IEMobile|Mobile|mobile|CriOS/i.test(ua)) {
      this.platform = "Mobile";
      this.platformType = "Other";
    }
    else if (/Firefox/i.test(ua)) {
      this.platform = "Web";
      this.platformType = "Firefox";
    }
    else if (/Edg/i.test(ua)) {
      this.platform = "Web";
      this.platformType = "Edge";
    }
    else if (/Chrome/i.test(ua)) {
      this.platform = "Web";
      this.platformType = "Chrome";
    }
    else {
      this.platform = "Web";
      this.platformType = "Other";
    }
  }

  public clean() {
    var user: User = {
      Id: null,
      Email: "",
      FirstName: "",
      MiddleName: "",
      LastName: "",
      Avatar: null,
      Status: "",
      EmailVerified: false,
      PasswordVerified: false,
      PhoneVerified: false,
      Phone: "",
      About: "",
      Company: "",
      Country: "tr",
      DefaultLanguage: "tr",
      Title: "",
      RoleCode: "",
      UserName: "",
    }
    this.user.set(user);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for user
   *
   * @param value
   */
  setUser(value: User) {
    // Store the value
    this.isLoggedIn.set(value && value.Id > 0);
    this.user.set(value);
  }

  uploadAvatar(file: File): void {
    if (!file) {
      var kedifile: KediFile = {
        Base64Encoded: null
      };
      this.api.setAvatar(kedifile).subscribe(response => {
        if (response.Succeded) {
          this.deleteLocal("img:" + this.user().Avatar); //this.getValue("currentAvatar"));
          var user = _.cloneDeep(this.user());
          user.Avatar = null;
          this.user.set(user);
        }
        else {
          this.showError(response.Message);
        }
      },
        (err: HttpErrorResponse) => this.showError(err.message));
    }
    else {
      this.readBase64(file)
        .then((data) => {
          var kedifile: KediFile = {
            Base64Encoded: data
          };
          this.api.setAvatar(kedifile).subscribe((response) => {
            if (response.Succeded) {
              this.deleteLocal("img:" + this.user().Avatar); //this.getValue("currentAvatar"));
              var user = _.cloneDeep(this.user());
              user.Avatar = response?.Result ? this.getImageUrl(response.Result) : null;
              this.user.set(user);
            }
            else {
              this.showError(response.Message);
            }
          });
        });
    }
  }

  updateProfile(profile: User): Observable<boolean> {
    return this.api.kediPost("account/user", profile, true).pipe(
      map((response: IKediResponse<User>) => {
        if (!response.Succeded) {
          this.showError(response.Message).subscribe();
          throwError(() => new Error(response.Message));
          return false;
        }
        else {
          //Başarılı
          this.user.update((currentValue) => ({
            ...currentValue, ...{
              FirstName: response.Result.FirstName,
              MiddleName: response.Result.MiddleName,
              LastName: response.Result.LastName,
              Phone: response.Result.Phone,
              Title: response.Result.Title,
              Company: response.Result.Company,
              About: response.Result.About,
              DefaultLanguage: response.Result.DefaultLanguage,
              Country: response.Result.Country
            }
          }));
          return true;
        }
      })
    );
  }

  getProfile(): Observable<boolean> {
    return this.api.kediGet("account/user", true).pipe(
      map((response: IKediResponse<User>) => {
        if (!response.Succeded) {
          this.showError(response.Message).subscribe();
          throwError(() => new Error(response.Message));
          return false;
        }
        else {
          //Başarılı
          this.user.update((currentValue) => ({
            ...currentValue, ...{
              About: response.Result.About,
              Avatar: response.Result.Avatar,
              Company: response.Result.Company,
              DefaultLanguage: response.Result.DefaultLanguage,
              FirstName: response.Result.FirstName,
              LastName: response.Result.LastName,
              MiddleName: response.Result.MiddleName,
              Phone: response.Result.Phone,
              Title: response.Result.Title
            }
          }));
          return true;
        }
      })
    );
  }
  /** UserService End */
  private _template: IKediTemplate;

  //private _app: KediApp;
  public get api(): ApiService {
    return this._api;
  }

  // public get app(): KediApp {
  //   return this._app;
  // }

  isAnonymous = computed(() => !(this.user()?.Id > 0));
  isTemporary = computed(() => this.user() && (!this.user().EmailVerified || !this.user().PasswordVerified));

  public StringToArray(value: string, splitter: string = ",") {
    if (value) return value.split(splitter);
    return [];
  }

  public ArrayToString(value: string[], joiner: string = ","): string {
    if (value && value.length > 0) return value.join(joiner);
    else return "";
  }

  public get selectedLanguageObject(): ApiLanguage {
    //return _.find(this.languages, {id: this._translateService.currentLang}) ?? this.languages[0];
    return _.find(this.languages, { id: this._translocoService.getActiveLang() }) ?? this.languages[0];
  }

  public get selectedLanguage(): string {
    return this._translocoService.getActiveLang() ?? this.languages[0].id;
    //return this._translateService.currentLang ?? this.languages[0].id;
  }

  public getImageUrl(key) {
    //return this.cdnUrl ? this.cdnUrl+'api/data/img/'+key : this.apiUrl+'data/img/' + key;
    return !key ? null : this.config.apiUrl + 'data/img/' + key;
  }
  public setLanguage(lang: string): void {
    this.currentLangId = 0;
    for (let i = 0; i < this.languages.length; i++) {
      if (this.languages[i].id == lang) {
        this.currentLangId = i;
        break;
      }
    }
    this.currentLang = lang;
    this.api.setLanguage(this.currentLang, this.currentLangId);
    this.setLocal("lastLanguage", lang);
    this._translocoService.setActiveLang(lang);
    location.reload();
  }

  // public setApp(app: KediApp) {
  //   this._app = app;
  // }

  public getConfig() {
    return this.config;
  }

  public setConfig(e) {
    return this.config = e;
  }

  public clearStorage() {
    localStorage.clear();
  }

  public getLocal(key: string): any {
    return localStorage.getItem(key) || null;
  }

  public setLocal(key: string, value: any): void {
    localStorage.setItem(key, value);
  }

  public deleteLocal(key: string): void {
    localStorage.removeItem(key);
  }

  public getToken(): string {
    this.token = this.getLocal("token");
    this.api.setToken(this.token);
    return this.token;
  }

  public setToken(new_token: string): void {
    this.token = new_token;
    this.api.setToken(this.token);
    this.setLocal("token", this.token);
  }

  // public setAuthStatus(_authenticated: boolean, _administrator: boolean): void {
  //   this._authenticated = _authenticated;
  //   this._administrator = _administrator;
  // }

  public deleteToken() {
    this.deleteLocal("token");
  }

  public translate(key: string): string {
    return this._translocoService.translate(key);
    //return this._translateService.instant(key);
  }

  public getCurrentLanguage(): string {
    var lang = this.getLocal("lastLanguage");
    if (!lang || lang == "") lang = "tr";
    this.setLocal("lastLanguage", lang);
    this.currentLang = lang;
    this.currentLangId = 0;
    for (let i = 0; i < this.languages.length; i++) {
      if (this.languages[i].id == lang) {
        this.currentLangId = i;
        break;
      }
    }
    this.api.setLanguage(this.currentLang, this.currentLangId);
    return lang;
  }

  public readBase64(file: File): Promise<any> {
    const reader = new FileReader();
    const future = new Promise((resolve, reject) => {
      reader.addEventListener('load', function () {
        resolve(reader.result);
      }, false);
      reader.addEventListener('error', function (event) {
        reject(event);
      }, false);

      reader.readAsDataURL(file);
    });
    return future;
  }


  public readAsBinaryArray(file: File): Promise<any> {
    const reader = new FileReader();
    const future = new Promise((resolve, reject) => {
      reader.addEventListener('load', function () {
        var binary_string: string = window.atob(reader.result as string);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
          bytes[i] = binary_string.charCodeAt(i);
        }
        resolve(bytes.buffer);
      }, false);
      reader.addEventListener('error', function (event) {
        reject(event);
      }, false);

      reader.readAsDataURL(file);
    });
    return future;
  }

  public fileAction(request: ApiFileActionRequest, file: File): Observable<any> {
    var observable: Observable<any> = null;
    if (!file) {
      var kedifile: KediFile = {
        Base64Encoded: null
      };
      observable = this.api.kediPost("file", request, true).pipe(
        map(response => {
          if (response && response.Succeded) {
            return response.Result;
          }
          else {
            if (response) this.showError(response.Message);
            return undefined;
          }
        })
      );
    }
    else {
      observable = from(this.readBase64(file)).pipe(
        map(data => {
          if (!request.File) request.File = { Base64Encoded: data };
          else request.File.Base64Encoded = data;
          return this.api.kediPost("file", request, true).pipe(
            map(response => {
              if (response.Succeded) {
                return response.Result;
              }
              else {
                this.showError(response.Message);
                return undefined;
              }
            })
          );
        }),
        concatAll()
      );
    }
    return observable;
  }

  // Get last value without subscribing to the puppies$ observable (synchronously).
  // getCurrentUser(): User {
  //   return this._currentUserSource.getValue();
  // }

  // private _setCurrentUser(user: User): void {
  //   this._currentUserSource.next(user);
  // }

  // setCurrentUser(user: User): void {
  //   this.currentUser = user;
  //   this._setCurrentUser(user);
  // }

  load() {
    return new Promise<void>((resolve, reject) => {
      this.api.loadConfig()
        .then((config: IKediConfig) => {
          this.config = new KediConfig(this._siteSettings, config.api.url);
          this.token = this.getToken();
          var lang = this.getLocal("lastLanguage");
          if (!lang || lang == "") lang = "tr";
          this.currentLang = lang;
          // for (let i = 0; i < this.languages.length; i++) {
          //   if (this.languages[i].id == lang) {
          //     this.currentLangId = i;
          //     break;
          //   }
          // }
          this.api.setLanguage(this.currentLang, this.currentLangId);
          //this.cdnUrl = this.config.site.cdn ? this.config.site.cdn+'/' : null;
          this.api.setToken(this.token);
          this.api.setSite(this.config.siteCode);

          this.api.getState(this.config.siteCode)
            .subscribe({
              next: (response: IKediResponse<any>) => {
                if (response.Succeded) {
                  this.setState(response.Result);
                  resolve();
                  // forkJoin([
                  //   (this.config.site.hasChatApp ? this._chatService.start(this) : of(null))
                  //   //this._navigationService.get(),
                  //   // this._messagesService.getAll(),
                  //   // this._notificationsService.getAll(),
                  //   // this._quickChatService.getChats(),
                  //   // this._shortcutsService.getAll(),
                  //   // this._userService.get()
                  // ]).subscribe(result => {
                  //   resolve();
                  // });
                }
                else {
                  alert(response.Message);
                  //this.setState(null);
                  //reject("ERROR_RELOAD_SESSION:" + response.Message);
                  reject("ERROR_RELOAD_SESSION:" + response.Message);
                }
              },
              error: (err: HttpErrorResponse) => {
                this.setState(null);
                this.showMessage("Uygulama sunucusuna bağlanamadı. Lütfen internet bağlantınızı kontrol ediniz. Aksi durumda lütfen sistem yöneticinize bildiriniz.", "Bağlantı Hatası").subscribe((result) => {
                  // alert("Kedi API sunucusuna bağlanamadı. Lütfen internet bağlantınızı kontrol ediniz. Aksi durumda lütfen sistem yöneticinize bildiriniz.");
                  reject("ERROR_RELOAD_SESSION:" + err.message);
                });
                // alert("Kedi API sunucusuna bağlanamadı. Lütfen internet bağlantınızı kontrol ediniz. Aksi durumda lütfen sistem yöneticinize bildiriniz.");
                // reject("ERROR_RELOAD_SESSION:" + err.message);
              }
            });
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  reload() {
    return new Promise<void>((resolve, reject) => {
      this.api.getState(this.config.siteCode)
        .subscribe((response: IKediResponse<any>) => {
          if (response.Succeded) {
            this.setState(response.Result);
            resolve();
          }
          else {
            alert(response.Message);
            reject("ERROR_RELOAD_SESSION:" + response.Message);
          }
        },
          (err: HttpErrorResponse) => {
            this.setState(null);
            alert("Kedi API sunucusuna bağlanamadı. Lütfen internet bağlantınızı kontrol ediniz. Aksi durumda lütfen sistem yöneticinize bildiriniz.");
            reject("ERROR_RELOAD_SESSION:" + err.message);
          });
    });
  }

  showSplash(duration?: number): void {
    this._document.body.classList.remove('fuse-splash-screen-hidden');
    console.log('[SST] call showSplash');
    if (duration && duration > 0) {
      console.log('[SST] showSplash duration: ', duration);
      setTimeout(() => {
        console.log('[SST] showSplash setTimeout called');
        this.hideSplash();
      }, duration);
    }
  }

  hideSplash(): void {
    console.log('[SST] call hideSplash');
    this._document.body.classList.add('fuse-splash-screen-hidden');
  }

  getWebText(type: string) {
    return this.api.kediGet("web/text/" + type, true).pipe(
      map((response: any) => {
        if (!response.Succeded) {
          this.showError(response.Message).subscribe();
          throwError(() => new Error(response.Message));
        }
        else {
          return response.Result;
        }
      })
    );
  }

  private setShortcuts(nodes: NavigationItem[], _shortcuts: any[]) {
    if (!nodes) return;
    nodes.forEach(item => {
      if (item.isShortcut) {
        let _title = this.translate(item.translate);
        _shortcuts.push({
          title: _title,
          letter: _title.substr(0, 1).toUpperCase(),
          type: item.type,
          icon: item.icon,
          url: item.link,
          id: item.id
        });
      }
      this.setShortcuts(item.children, _shortcuts);
    });
  }

  // private checkMenuFunction(c: FuseNavigationItem[]) {
  //   c.forEach(c => {
  //     if (c.function) {
  //       c.function = this.customFunction[c.function];
  //     }
  //     else if (c.children) this.checkMenuFunction(c.children);
  //   });
  // }

  resolveMenuChildren(source: NavigationItem[]): NavigationItem[] {
    source?.forEach(c => {
      if (!c.code) c.code = c.id;
      if (!c.position) c.position = "menu";
      if (!c.type) c.type = c.children ? "collapsable" : "basic";
      if (!c.icon) c.icon = "heroicons_outline:menu";
      if (!c.title) c.title = c.id;
      if (c.children && c.children.length > 0) c.children = this.resolveMenuChildren(c.children);
    });
    return source;
  }

  public menuItemHideFunc: (item: NavigationItem) => boolean = () => true;

  setMenu(menu?: NavigationItem[]) {
    if (!menu) menu = this._siteSettings.menu;
    var compact: NavigationItem[] = [];
    var futuristic: NavigationItem[] = [];
    var horizontal: NavigationItem[] = [];
    var buttons: NavigationItem[] = [];

    menu?.forEach(c => {
      if (!c.code) c.code = c.id;
      if (!c.position) c.position = "menu";
      if (!c.type) c.type = c.children ? "collapsable" : "basic";
      if (!c.icon) c.icon = "heroicons_outline:menu";
      if (!c.title) c.title = c.id;
      if (c.children && c.children.length > 0) c.children = this.resolveMenuChildren(c.children);

      /*
              if (!c.position) c.position = "menu";
            state.Menu?.children.forEach(c => {*/
      // if (c.type != 'button') {

      compact.push({
        id: c.id,
        code: c.code,
        // title: c.title,
        // tooltip: c.tooltip,
        position: c.position,
        type: "aside",
        icon: c.icon,
        link: c.link,
        children: c.children,
        hidden: c.hidden
      });
      futuristic.push({
        id: c.id,
        code: c.code,
        // title: c.title.toUpperCase(),
        // tooltip: c.tooltip,
        position: c.position,
        type: c.type,
        icon: c.icon,
        link: c.link,
        children: c.children,
        hidden: c.hidden
      });
      horizontal.push({
        id: c.id,
        code: c.code,
        // title: c.title,
        // tooltip: c.tooltip,
        position: c.position,
        type: c.type,
        icon: c.icon,
        link: c.link,
        children: c.children,
        hidden: c.hidden
      });
      // }
      // else {
      //   buttons.push({
      //     id: c.id,
      //     title: c.title,
      //     tooltip: c.tooltip,
      //     type: c.type,
      //     icon: c.icon,
      //     link: c.link,
      //     children: c.children
      //   });
      // }
    });
    var navigation: Navigation = {
      default: menu,
      compact: compact,
      futuristic: futuristic,
      horizontal: horizontal
    };
    navigation.compact.forEach(c => {
      c.type = "aside"
    });


    this.Navigation.next(navigation);

  }

  hideMenuItems(items: NavigationItem[], func: Function) {
    if (!items) items = this._siteSettings.menu;
    if (items && items.length > 0) {
      items?.forEach((item: NavigationItem) => {
        if (func(item)) item.hidden = this.menuItemHideFunc;
        if (item.children && item.children.length > 0) {
          this.hideMenuItems(item.children, func);
        }
        if (!item.hidden && item.children && !item.children.find(i => !i.hidden)) item.hidden = this.menuItemHideFunc;
      });
    }
  }

  showMenuItems(items: NavigationItem[], func: Function) {
    if (!items) items = this._siteSettings.menu;
    if (items && items.length > 0) {
      items.forEach((item) => {
        if (func(item)) item.hidden = undefined;
        if (item.children && item.children.length > 0) {
          this.showMenuItems(item.children, func);
        }
      });
    }
  }

  setState(state: ApiState) {
    if (state) {
      let th = this.config.site().theme;
      //this._routes = state?.Routes ? [...state.Routes] : [];
      this._pages = state?.Pages ? [...state.Pages] : [];

      this.languages = [];
      if (th?.languages && th.languages.length > 0) {
        let _index = 0;
        th.languages.forEach(_lang => {
          let kediLang = this._kediLangs.find(l => l.id == _lang);
          if (kediLang) {
            kediLang.index = _index;
            _index++;
            this.languages.push(kediLang);
          }
        });
      }
      else {
        let kediLang = this._kediLangs.find(l => l.id == "tr");
        kediLang.index = 0;
        this.languages = [kediLang];
      }
      //this.languages = state.MultiLang.Languages;

      // this.config.site.code = state.Settings.Code;
      // this.config.site.captchaSiteKey = ss?.tools?.captcha?.site_key;//state.Settings.CaptchaSiteKey;
      // this.config.site.siteTitle = ss?.site?.title && ss.site?.title[0];//state.Settings.SiteTitle;
      // this.config.site.siteName = ss?.site?.name;//[...state.Settings.SiteName];
      // this.config.site.siteDescription = ss?.site?.description;//[...state.Settings.SiteDescription];
      // this.config.site.siteMotto = ss?.site?.motto;//[...state.Settings.SiteMotto];
      // this.config.site.allowAnonymous = ss?.auth?.signin?.anonymous;//state.Settings.AllowAnonymous;
      // this.config.site.canSignUp = ss?.auth?.signup?.allowed;//state.Settings.CanSignUp;
      // this.config.site.canChangeLanguage = ss?.theme?.languages?.length > 1;//state.Settings.CanChangeLanguage;
      // this.config.site.canChangeLayout = ss?.theme?.layouts?.length > 1;//state.Settings.CanChangeLayout;
      // this.config.site.canChangeScheme = ss?.theme?.schemes?.length > 1;//state.Settings.CanChangeScheme;
      // this.config.site.canChangeTheme = ss?.theme?.colors?.length > 1;//state.Settings.CanChangeTheme;
      // this.config.site.loginWith = ss?.auth?.signin?.ask;//state.Settings.LoginWith;
      // this.config.site.authScreenStyle = ss?.auth?.style;//state.Settings.AuthScreenStyle;
      // this.config.site.defaultLanguage = ss?.defaults?.language;//state.Settings.DefaultLanguage;
      // this.config.site.defaultLayout = ss?.defaults?.layout;//state.Settings.DefaultLayout;
      // this.config.site.defaultRoute = ss?.routes?.default;//state.Settings.DefaultRoute;
      // this.config.site.defaultScheme = ss?.defaults?.scheme;//state.Settings.DefaultScheme;
      // this.config.site.defaultTheme = ss?.defaults?.theme;//state.Settings.DefaultTheme;
      // this.config.site.multiLanguage = ss?.theme?.languages?.length > 1;//state.Settings.MultiLanguage;
      // this.config.site.multiLayout = ss?.theme?.layouts?.length > 1;//state.Settings.MultiLayout;
      // this.config.site.multiScheme = ss?.theme?.schemes?.length > 1;//state.Settings.MultiScheme;
      // this.config.site.multiTheme = ss?.theme?.colors?.length > 1;//state.Settings.MultiTheme;
      // this.config.site.hasCalendarApp = ss?.tools?.calendar;//state.Settings.HasCalendarApp;
      // this.config.site.hasChatApp = ss?.tools?.chat;//state.Settings.HasChatApp;
      // this.config.site.hasContactsApp = ss?.tools?.contacts;//state.Settings.HasContactsApp;
      // this.config.site.hasLibraryApp = ss?.tools?.library;//state.Settings.HasLibraryApp;
      // this.config.site.hasMailApp = ss?.tools?.mail;//state.Settings.HasMailApp;
      // this.config.site.hasNotesApp = ss?.tools?.notes;//state.Settings.HasNotesApp;
      // this.config.site.hasScrumboardApp = ss?.tools?.scrumboards;//state.Settings.HasScurmboardApp;
      // this.config.site.hasTasksApp = ss?.tools?.tasks;//state.Settings.HasTasksApp;
      // this.config.site.resetPasswordMethod = ss?.routes?.change_password;//state.Settings.ResetPasswordMethod;
      // this.config.site.userSettings = {
      //   HasUserProfile: ss?.theme?.header?.profile,//state.Settings.UserSettings.HasUserProfile,
      //   HasUserSettings: ss?.theme?.header?.settings,//state.Settings.UserSettings.HasUserSettings,
      //   HasUserStatus: ss?.theme?.header?.status,//state.Settings.UserSettings.HasUserStatus,
      //   RouteUserProfile: ss?.routes?.user_profile,//state.Settings.UserSettings.RouteUserProfile,
      //   RouteUserSettings: ss?.routes?.user_settings,//state.Settings.UserSettings.RouteUserSettings,
      //   HasMessages: ss?.theme?.header?.messages,//state.Settings.UserSettings.HasMessages,
      //   HasShortcuts: ss?.theme?.header?.shortcuts,//state.Settings.UserSettings.HasShortcuts,
      //   HasNotifications: ss?.theme?.header?.notifications,//state.Settings.UserSettings.HasNotifications,
      //   HasSearch: !!ss?.theme?.header?.search,//state.Settings.UserSettings.HasSearch,
      //   HasFullScreen: ss?.theme?.header?.full_screen,//state.Settings.UserSettings.HasFullScreen,
      //   HasUserFirm: ss?.theme?.header?.firm,//state.Settings.UserSettings.HasUserFirm
      // },
      //   this.config.site.signUpSettings = {
      //     AskCaptcha: (typeof ss?.auth?.signup?.captcha === 'boolean' && ss?.auth?.signup?.captcha) || (<KediSiteSettings_Auth_SignUp_Item>ss?.auth?.signup?.captcha)?.ask,//state.Settings.SignUpSettings.AskCaptcha,
      //     AskCompany: (typeof ss?.auth?.signup?.company === 'boolean' && ss?.auth?.signup?.company) || (<KediSiteSettings_Auth_SignUp_Item>ss?.auth?.signup?.company)?.ask,//state.Settings.SignUpSettings.AskCompany,
      //     AskContract: (typeof ss?.auth?.signup?.contract === 'boolean' && ss?.auth?.signup?.contract) || (<KediSiteSettings_Auth_SignUp_Item>ss?.auth?.signup?.contract)?.ask,//state.Settings.SignUpSettings.AskContract,
      //     AskPrivacy: (typeof ss?.auth?.signup?.privacy === 'boolean' && ss?.auth?.signup?.privacy) || (<KediSiteSettings_Auth_SignUp_Item>ss?.auth?.signup?.privacy)?.ask,//state.Settings.SignUpSettings.AskPrivacy,
      //     ContractContent: ss?.auth?.signup?.contract && (<KediSiteSettings_Auth_SignUp_Item>ss?.auth?.signup?.contract)?.content,//state.Settings.SignUpSettings.ContractContent,
      //     PrivacyContent: ss?.auth?.signup?.privacy && (<KediSiteSettings_Auth_SignUp_Item>ss?.auth?.signup?.privacy)?.content,//state.Settings.SignUpSettings.PrivacyContent,
      //   }
      // // TODO: Backend tarafından dinamik alınacak
      // this.config.site.layoutSettings = {
      //   TransparentHeader: ss?.theme?.header?.transparent,//state.Settings.TransparentHeader,
      //   BackgroundImage: ss?.theme?.header?.background_image,//state.Settings.BackgroundImage,
      //   AllowedThemes: (ss?.theme?.colors ?? []).map(x => x.id),//state.Settings.AllowedThemes,
      //   AllowedSchemes: ss?.theme?.schemes,//state.Settings.AllowedSchemes,
      //   AllowedLayouts: ss?.theme?.layouts,//state.Settings.AllowedLayouts,
      // }
      // this.config.site.searchPost = ss?.theme?.header.search?.url;//"c002/search";
      // this.config.site.metaTitle = ss?.site?.meta?.title;//state.Settings.MetaTitle;
      // this.config.site.metaDescription = ss?.site?.meta?.description;//state.Settings.MetaDescription;
      // this.config.site.metaKeywords = ss?.site?.meta?.description;//state.Settings.MetaKeywords;
      // this.config.site.metaOther = null;
      // if (ss?.site?.meta?.other && ss?.site?.meta?.other?.length > 0) {//state.Settings.MetaOther && state.Settings.MetaOther.length > 0) {
      //   this.config.site.metaOther = [];
      //   ss?.site?.meta?.other?.forEach(mo => {//state.Settings.MetaOther.forEach(mo => {
      //   this.config.site.metaOther.push(mo);
      // });
      //}

      // state.Routes.forEach(route => {
      //   this.router.config.push({
      //     path: route.Name,
      //     component: route.Component
      //   });
      // });
      this.titleService.setTitle(this.config.site().meta.title);

      if (!this.config.site().meta.description)
        this.metaService.removeTag("name='description'");
      else
        this.metaService.updateTag({ property: 'description', content: this.config.site().meta.description })

      if (!this.config.site().meta.keywords)
        this.metaService.removeTag("name='keywords'");
      else
        this.metaService.updateTag({ property: 'keywords', content: this.config.site().meta.keywords })

      if (this.config.site().meta.other && this.config.site().meta.other.length > 0) {
        this.config.site().meta.other.forEach(m => {
          let _split = m?.split("=");
          if (_split && _split.length == 2) {
            this.metaService.updateTag({ property: _split[0], content: _split[1] })
          }
        });
      }

      //this._title.innerHTML = this.config.site.siteName[0];
      //this.favIcon.href = 'https://www.google.com/favicon.ico';

      this._template.templateService.setConfig({
        scheme: this.config.site().defaults.scheme,
        theme: "theme-" + this.config.site().defaults.theme,
        layout: this.config.site().defaults.layout
      });
      this.currentTheme.set(this._siteSettings?.defaults?.theme);
      this.currentLayout.set(this._siteSettings?.defaults?.layout);
      this.currentScheme.set(this._siteSettings?.defaults?.scheme);
      this.currentHeaderTransparent.set(this._siteSettings?.theme?.header?.transparent);
      // this.currentTheme.set(state.Settings.DefaultTheme);
      // this.currentLayout.set(state.Settings.DefaultLayout);
      // this.currentScheme.set(state.Settings.DefaultScheme);
      // this.currentHeaderTransparent.set(state.Settings.TransparentHeader);
      // this._fuseConfigService.config = {
      //   scheme: this.config.site.defaultScheme,
      //   theme: this.config.site.defaultTheme,
      //   layout: this.config.site.defaultLayout
      // };
      // var localeSignature = localStorage.getItem("localeSignature");
      // var localeCV: ApiCachedValue = null;
      // state.CachedValues.forEach(cv => {
      //   if (cv.Key == "locale") localeCV = cv;
      // });
      // if (localeSignature && localeCV && localeSignature == localeCV.Signature) {
      //   var localeValue = localStorage.getItem("localeValue");
      //   this.locales = JSON.parse(localeValue);
      // }
      // else {
      //   this.locales = state.MultiLang.Locales;
      //   localStorage.setItem("localeSignature", localeCV.Signature);
      //   localStorage.setItem("localeValue", JSON.stringify(this.locales));
      // }
      var langs: any[] = [];
      this.languages.forEach(l => {
        langs.push({
          id: l.id,
          label: l.title
        });
      });
      this._translocoService.setAvailableLangs(langs);
      var curLang = this.getLocal("lastLanguage");
      if (!curLang) curLang = this._siteSettings.defaults.language;
      // if (!curLang) curLang = state.Settings.DefaultLanguage;

      //this._translateService.addLangs(this.languages.map(l => l.id));
      this._translocoService.setDefaultLang(curLang);
      //this._translateService.setDefaultLang(this.languages[0].id);
      this._translocoService.setActiveLang(curLang);

      this.currentLangId = 0;
      for (let i = 0; i < this.languages.length; i++) {
        if (this.languages[i].id == this._siteSettings.defaults.language) {
          // if (this.languages[i].id == state.Settings.DefaultLanguage) {
          this.currentLangId = i;
          break;
        }
      }
      this.api.setLanguage(this.currentLang, this.currentLangId);
      this.getCurrentLanguage();

      // Object.values(ns_kedi_locale).forEach((c) => {
      //   if (c.getLocale) this.mergeLocale(this.constructor.name, c.getLocale());
      // });

      // let localeId = 0;
      // this.locales.forEach(l => {
      //   this._translocoService.setTranslation(l.data, l.lang);
      //   this._translocoService.setTranslationKey("LOGIN.BODY_TITLE1", this.site.title[l.] ?? " ", { lang: l.lang });
      //   this._translocoService.setTranslationKey("LOGIN.BODY_TITLE2", this.config.site.siteName[localeId] ?? " ", { lang: l.lang });
      //   this._translocoService.setTranslationKey("LOGIN.BODY_TEXT", this.config.site.siteDescription[localeId] ?? " ", { lang: l.lang });
      //   this._translocoService.setTranslationKey("LOGIN.BODY_MESSAGE", this.config.site.siteMotto[localeId] ?? " ", { lang: l.lang });
      //   localeId++;
      // });
      // this._localeReadySubject.next(true);

      // let primengTranslation = this._translocoService.translateObject('primeng');
      // if (primengTranslation) {
      //   primengTranslation.dayNames = ["Pazar","Pazartesi","Salı","Çarşamba","Perşembe","Cuma","Cumartesi"];
      //   primengTranslation.dayNamesShort = ["Paz","Pts","Sal","Çar","Per","Cum","Cts"];
      //   primengTranslation.dayNamesMin = ["Pz","Ps","Sa","Ça","Pe","Cu","Cs"];
      //   primengTranslation.monthNames= ["Ocak","Şubat","Mart","Nisan","Mayıs","Haziran","Temmuz","Ağustos","Eylül","Ekim","Kasım","Aralık"],
      //   primengTranslation.monthNamesShort= ["Oca","Şub","Mar","Nis","May","Haz","Tem","Ağu","Eyl","Eki","Kas","Ara"],
      //   this.primengConfig.setTranslation(primengTranslation);
      // }

      // var compact: NavigationItem[] = [];
      // var futuristic: NavigationItem[] = [];
      // var horizontal: NavigationItem[] = [];
      // var buttons: NavigationItem[] = [];

      // this._siteSettings.menu?.forEach(c => {
      //   if (!c.code) c.code = c.id;
      //   if (!c.position) c.position = "menu";
      //   if (!c.type) c.type = c.children ? "collapsable" : "basic";
      //   if (!c.icon) c.icon = "heroicons_outline:menu";
      //   if (!c.title) c.title = c.id;
      //   /*
      //           if (!c.position) c.position = "menu";
      //         state.Menu?.children.forEach(c => {*/
      //   // if (c.type != 'button') {

      //   compact.push({
      //     id: c.id,
      //     code: c.code,
      //     // title: c.title,
      //     // tooltip: c.tooltip,
      //     position: c.position,
      //     type: "aside",
      //     icon: c.icon,
      //     link: c.link,
      //     children: c.children
      //   });
      //   futuristic.push({
      //     id: c.id,
      //     code: c.code,
      //     // title: c.title.toUpperCase(),
      //     // tooltip: c.tooltip,
      //     position: c.position,
      //     type: c.type,
      //     icon: c.icon,
      //     link: c.link,
      //     children: c.children
      //   });
      //   horizontal.push({
      //     id: c.id,
      //     code: c.code,
      //     // title: c.title,
      //     // tooltip: c.tooltip,
      //     position: c.position,
      //     type: c.type,
      //     icon: c.icon,
      //     link: c.link,
      //     children: c.children
      //   });
      //   // }
      //   // else {
      //   //   buttons.push({
      //   //     id: c.id,
      //   //     title: c.title,
      //   //     tooltip: c.tooltip,
      //   //     type: c.type,
      //   //     icon: c.icon,
      //   //     link: c.link,
      //   //     children: c.children
      //   //   });
      //   // }
      // });
      // var navigation: Navigation = {
      //   default: this._siteSettings.menu,
      //   compact: compact,
      //   futuristic: futuristic,
      //   horizontal: horizontal
      // };
      // navigation.compact.forEach(c => {
      //   c.type = "aside"
      // });


      // this.Navigation.next(navigation);
      //this.setValue<FuseNavigation[]>("navigation",this._siteSettings.menu.children);
      let _shortcuts: any[] = [];
      this.setShortcuts(this._siteSettings.menu, _shortcuts);
      this.Shortcuts = _shortcuts;
      this.MustChangePassword = state.Session.MustChangePassword;
      this._changePasswordRoute = this._siteSettings.routes.change_password;
      // this._changePasswordRoute = state.Settings.ChangePasswordRoute;
      if (!this._changePasswordRoute) this._changePasswordRoute = "core/usersettings/security";
      // if (this.config.site.hasChatApp) {
      //   this._chatService.apiUrl = this.apiUrl;
      // }
      this.ActiveRole = state.Session.ActiveRole;
      this.ActiveFirm = state.Session.ActiveFirm;
      this.ActiveCompany = state.Session.ActiveCompany;
      if (state.User) {
        if (state.User?.Avatar) state.User.Avatar = this.getImageUrl(state.User.Avatar);
        this.user.set(state.User);
        this.authenticated = true;
        this.isLoggedIn.set(true);
        this.admin = state.User.IsAdmin;
      }
      else this.clean();
      //this.setValue<any[]>("shortcuts", _shortcuts);
      //this.Routes = state.Routes;
      //this.setValue<ApiRoute[]>("routes",state.Routes);
      //this.Modules = state.Modules;
      // this.NgModules.forEach(ngm => {
      //   let _module = this.Modules.find(m => m.KediModuleCode == ngm.ModuleCode);
      //   if (_module) {
      //     ngm.ApiModule = _module;
      //     this.Routes.filter(r => r.ModuleKey == _module.Key).forEach(r => {
      //       ngm.ApiRoutes.push(r);
      //     });
      //   }
      // });
    }
    else {
      this.clean();
      this.user.set(null);
      this.avatarUrl.set(null);
      this.Navigation.next(null);
      this.Shortcuts = null;
      //this.Routes = null;
      //this.Modules = null;
    }
    this.setStateLoaded();
  }
  setStateLoaded() {
    this.showMenuItems(this._siteSettings.menu, () => true);
    // this.setMenu(this._siteSettings.menu);
    this._stateLoadedSubject.next(true);
  }
  setTemplateDrawerService(drawerService: IKediTemplateDrawerService) {
    this._template.drawerService = drawerService;
  }

  setTemplateConfirmService(confirmService: IKediTemplateConfirmService) {
    this._template.confirmService = confirmService;
  }

  setTemplateConfigService(templateService: IKediTemplateConfigService) {
    this._template.templateService = templateService;
  }

  showConfirm(config?: IKediTemplateConfirmationConfig): Observable<any> {
    return this._template.confirmService ? this._template.confirmService.showConfirm(config) : null;
  }

  showError(text: string, title?: string) {
    return this.showConfirm({ title: title ?? "HATA", message: text, dismissible: false, icon: { color: "error", name: "error", show: true }, actions: { cancel: { label: "Kapat", show: true }, confirm: { show: false } } });
  }

  showWarning(text: string, title?: string) {
    return this.showConfirm({ title: title ?? "UYARI", message: text, dismissible: false, icon: { color: "warning", name: "warning", show: true }, actions: { cancel: { label: "Tamam", show: true }, confirm: { show: false } } });
  }

  askWarning(text: string, title?: string) {
    return this.showConfirm({ title: title ?? "UYARI", message: text, dismissible: false, icon: { color: "warning", name: "warning", show: true }, actions: { confirm: { label: "Tamam", show: true, color: "primary" }, cancel: { show: true, label: "İptal" } } });
  }

  askQuestion(text: string, title?: string) {
    return this.showConfirm({ title: title ?? "BİLGİ", message: text, dismissible: false, icon: { color: "info", name: "info", show: true }, actions: { confirm: { label: "Tamam", show: true, color: "primary" }, cancel: { show: true, label: "İptal" } } });
  }

  showMessage(text: string, title?: string) {
    return this.showConfirm({ title: title ?? "BİLGİ", message: text, dismissible: false, icon: { color: "info", name: "info", show: true }, actions: { cancel: { label: "Tamam", show: true }, confirm: { show: false } } });
  }

  toastError(text: string, title?: string, key?: string) {
    this.messageService.add({ key: key ?? "toastLayout", severity: 'error', summary: title ?? "HATA", detail: text });
  }

  toastWarning(text: string, title?: string, key?: string) {
    this.messageService.add({ key: key ?? "toastLayout", severity: 'warn', summary: title ?? "UYARI", detail: text });
  }

  toastInfo(text: string, title?: string, key?: string) {
    this.messageService.add({ key: key ?? "toastLayout", severity: 'info', summary: title ?? "BİLGİ", detail: text });
  }

  toastSuccess(text: string, title?: string, key?: string) {
    this.messageService.add({ key: key ?? "toastLayout", severity: 'success', summary: title ?? "BAŞARILI", detail: text });
  }

  toastConfirm(key: string, text: string, title?: string, data?: any) {
    this.messageService.add({ key: key ?? "toastLayout", severity: 'warn', summary: title ?? "UYARI", detail: text, sticky: true, closable: false, data: data });
  }

  toastClose(key: string, value?: any) {
    this.messageService.clear(key);
  }

  toastCloseAll() {
    this.messageService.clear();
  }

  // // // showMessage(text: string, title: string = "") {
  // // //   return this.openDialog({
  // // //     data: text,
  // // //     title: title,
  // // //     height: 200
  // // //   });
  // // // }

  // // // showWarning(text: string, title: string = "") {
  // // //   return this.openDialog({ data: text, title: title, type: KediDialogType.WARNING });
  // // // }

  // // // showError(text: string, title: string = "") {
  // // //   return this.openDialog({ data: text, title: title, type: KediDialogType.ERROR });
  // // // }

  // askQuestion(text: string, title: string = "") {
  //   return this.openDialog({ data: text, title: title, type: KediDialogType.QUESTION });
  // }

  // confirm(text: string, title: string = "") {
  //   return this.openDialog({ data: text, title: title, type: KediDialogType.CONFIRM });
  // }

  // showDialog(options: KediDialogOptions) {
  //   options.type = KediDialogType.CUSTOM;
  //   return this.openDialog(options);
  // }

  toggleShortcut(key: string) {
    return new Promise<boolean>((resolve) => {
      this.api.kediPost("web/shortcut/" + key, null, true)
        .subscribe({
          next: (response: IKediResponse<any>) => {
            if (response.Succeded) {
              resolve(true);
            }
            else {
              this.showError(response.Message);
              resolve(false);
            }
          },
          error: (err: HttpErrorResponse) => {
            this.showError(err.message);
            resolve(false);
          }
        });
    });
  }

  public clearCache(scope: string = "ALL") {
    var lastLanguage = this.getLocal("lastLanguage");
    var lastEmail = this.getLocal("lastEmail");
    var token = this.getLocal("token");
    var rememberMe = this.getLocal("rememberMe");
    this.clearStorage();
    if (lastLanguage) this.setLocal("lastLanguage", lastLanguage);
    if (lastEmail) this.setLocal("lastEmail", lastEmail);
    if (token) this.setLocal("token", token);
    if (rememberMe) this.setLocal("rememberMe", rememberMe);
  }

  public Guid(): string {
    const ho = (n, p) => n.toString(16).padStart(p, 0); /// Return the hexadecimal text representation of number `n`, padded with zeroes to be of length `p`
    const view = new DataView(new ArrayBuffer(16)); /// Create a view backed by a 16-byte buffer
    crypto.getRandomValues(new Uint8Array(view.buffer)); /// Fill the buffer with random data
    view.setUint8(6, (view.getUint8(6) & 0xf) | 0x40); /// Patch the 6th byte to reflect a version 4 UUID
    view.setUint8(8, (view.getUint8(8) & 0x3f) | 0x80); /// Patch the 8th byte to reflect a variant 1 UUID (version 4 UUIDs are)
    return `${ho(view.getUint32(0), 8)}-${ho(view.getUint16(4), 4)}-${ho(view.getUint16(6), 4)}-${ho(view.getUint16(8), 4)}-${ho(view.getUint32(10), 8)}${ho(view.getUint16(14), 4)}`; /// Compile the canonical textual form from the array data
  }

  public registerSharedList<T>(_key: string, _source?: any): KediSharedList<T> {
    return this.shared.registerList<T>(_key, _source);
  }

  public unregisterSharedList<T>(_key: string): void {
    this.shared.unregisterList<T>(_key);
  }

  public registerSharedValue<T>(_key: string, _source?: any): KediSharedValue<T> {
    return this.shared.registerValue<T>(_key, _source);
  }

  public unregisterSharedValue<T>(_key: string): void {
    this.shared.unregisterValue<T>(_key);
  }

  public getSharedListValue<T>(_key: string): T[] {
    return this.shared.getListValue<T>(_key);
  }

  public getSharedRowValue<T>(_key: string): T {
    return this.shared.getRowValue<T>(_key);
  }

  public getSharedValue<T>(_key: string): T {
    return this.shared.getValue<T>(_key);
  }

  public setSharedListValue<T>(_key: string, _value: T[]) {
    return this.shared.setListValue<T>(_key, _value);
  }

  public setSharedRowValue<T>(_key: string, _value: T) {
    return this.shared.setRowValue<T>(_key, _value);
  }

  public setSharedValue<T>(_key: string, _value: T) {
    return this.shared.setValue<T>(_key), _value;
  }

  public sharedList<T>(_key: string): BehaviorSubject<T[]> {
    return this.shared.list<T>(_key);
  }

  public sharedRow<T>(_key: string): BehaviorSubject<T> {
    return this.shared.row<T>(_key);
  }

  public sharedValue<T>(_key: string): BehaviorSubject<T> {
    return this.shared.value<T>(_key);
  }

  public sharedEnum(_key: string): BehaviorSubject<any> {
    let _enum = this.shared.value<any>(_key);
    if (!_enum?.value) {
      let _val: any = {};
      // _enum = this.shared.registerValue<any>(_key, null).data;
      // var obs = this.api.kediPost<any[]>("data/enum/"+_key,null,true).pipe(
      //   map(response => {

      //     if (response.Succeded) {
      //       response.Result.forEach(enumValue => {
      //         _val[enumValue.Key] = {
      //           id: enumValue.Id,
      //           text: enumValue.Text,
      //           value: enumValue.Id
      //         }
      //       });
      //       this.shared.registerValue<any>(_key, _val);
      //     }
      //     else {
      //       this.showError(response.Message);
      //     }
      //   })
      // );
      // obs.subscribe(_enum);
      // return _enum;
      this.api.kediPost<any[]>("data/enum/" + _key, null, true).subscribe(response => {
        if (response.Succeded) {
          response.Result.forEach(enumValue => {
            _val[enumValue.Key] = {
              id: enumValue.Id,
              text: enumValue.Text,
              value: enumValue.Id
            }
          });
          this.shared.registerValue<any>(_key, _val);
        }
        else {
          this.showError(response.Message);
        }
      });
      return this.shared.registerValue<any>(_key, null).data;
    }
    else return _enum;
  }

  public getSharedEnumText(_key: string, _value: any) {
    let _enum = this.shared.value<any>(_key)?.getValue();
    if (!_enum || !_enum["_" + _value]) return null;
    return _enum["_" + _value].text[this.currentLangId];
  }

  public getSharedEnumTextList(_key: string, success: (values: (string | null)[]) => void) {
    let _enum = this.shared.value<any>(_key)?.getValue();
    if (!_enum) return null;
    let arr: string[] = [];
    Object.keys(_enum).forEach((prop: string) => arr.push(_enum[prop].value));
    success(arr);
  }

  public getSharedEnumValue(_key: string, _text: any) {
    let _enum = this.shared.value<any>(_key)?.getValue();
    if (!_enum) return null;
    let _val = null;
    Object.keys(_enum).forEach(_key => {
      if (_enum[_key].text[this.currentLangId] == _text) {
        _val = _enum[_key].id;
        return;
      }
    });
    return _val;
  }
  /*
    public setKediEntity(className: string, type: any) {
      if (this.KediEntityType == null)
        this.KediEntityType = {};
      if (this.KediEntityType[className] === undefined || this.KediEntityType[className] === null) {
        this.KediEntityType[className] = type;
      }
    }
    */

  toggleDrawer(drawerId: string) {
    if (this._template.drawerService) this._template.drawerService.toggleDrawer(drawerId);
    // const drawer = this._fuseDrawerService.getComponent(drawerId);
    // drawer?.toggle();
  }

  closeDrawer(drawerId: string) {
    if (this._template.drawerService) this._template.drawerService.closeDrawer(drawerId);
    // const drawer = this._fuseDrawerService.getComponent(drawerId);
    // drawer?.close();
  }

  openDrawer(drawerId: string) {
    if (this._template.drawerService) this._template.drawerService.openDrawer(drawerId);
    // const drawer = this._fuseDrawerService.getComponent(drawerId);
    // drawer?.open();
  }

  getParentDrawer() {
    if (this._template.drawerService) return this._template.drawerService.getParentDrawer();
    else return null;
    // return this._activeParentDrawer;
  }

  setParentDrawer(drawer: any) {
    if (this._template.drawerService) return this._template.drawerService.setParentDrawer(drawer);
    else return null;
    // this._activeParentDrawer = drawer;
  }

  saveLayoutSettings(): Observable<IKediResponse<any>> {
    let setting: any = {
      Code: this.config.siteCode,
      TransparentHeader: this.currentHeaderTransparent(),
      DefaultTheme: this.config.defaultTheme(),
      DefaultScheme: this.config.defaultScheme(),
      Defaultlayout: this.config.defaultLayout(),
    };
    return this.api.kediPost("web/settings/layout/save", setting, true);
  }

  dateStr(date: string | Date, type?: string) {
    if (!date) return "";
    return dayjs(date).format(!type || type == "md" ? "D MMM YYYY ddd" : (type == "lg" ? "D MMMM YYYY dddd" : (type != "" ? type : "DD/MM/YYYY")));
  }
  dateTimeStr(date: string | Date, type?: string) {
    if (!date) return "";
    return dayjs(date).format(!type || type == "md" ? "D MMM YYYY ddd HH:mm" : (type == "lg" ? "D MMMM YYYY dddd HH:mm" : (type != "" ? type : "DD/MM/YYYY HH:mm")));
  }
  timeStr(date: string | Date) {
    return dayjs(date).format("HH:mm");
  }
  toOADateStr(date: string | Date) {
    return (Math.abs(dayjs(date).toDate().getTime() - new Date(Date.UTC(1899, 11, 30)).getTime())) / (24 * 60 * 60 * 1000)
  }
  toISOString(date: string | Date) {
    return dayjs(date).format().substring(0, 19) + "Z";
  }

  formatDataByView(cols: any[], data: any[]) {
    cols.forEach(c => {
      if (c.datatype?.startsWith('Date')) {
        data.forEach(d => {
          d[c.field] = dayjs(d[c.field]).toDate()
        });
      }
      else if (c.type == "subgrid") {
        data.forEach(d => {
          this.formatDataByView(c.detailGridColumnDefs, d[c.field]);
        });
      }
    });
  }

  toNumberStr = function (value: string | number, decimals: number) {
    let _number = typeof value === "string" ? parseFloat(value) : value;
    if ((1.1).toLocaleString().indexOf(".") >= 0) {
      return _number.toFixed(decimals).replace(/^[+-]?\d+/, function (int) {
        return int.replace(/(\d)(?=(\d{3})+$)/g, '$1,');
      });
    }
    else {
      return _number.toFixed(decimals).replace(".", ",").replace(/^[+-]?\d+/, function (int) {
        return int.replace(/(\d)(?=(\d{3})+$)/g, '$1.');
      });
    }
  };

  beforeCallback(_callback: (() => boolean | Observable<boolean>) | (() => boolean | Observable<boolean>)[]): Observable<boolean> {
    let observables: Observable<boolean>[] = [];
    if (_callback) {
      if (Array.isArray(_callback)) {
        _callback.forEach(_c => {
          let result = _c();
          observables.push(typeof result === 'boolean' ? of(result) : result);
        });
      }
      else {
        let result = _callback();
        observables.push(typeof result === 'boolean' ? of(result) : result);
      }
    }
    if (observables.length == 0) observables.push(of(true));
    return forkJoin(observables).pipe(
      map((list) => {
        return (list.findIndex(e => !e) < 0)
      })
    )
  }

  isUUID(uuid: string) {
    let result = uuid.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
    return result && result.length > 0 && result[0];
  }

  public isObject(item): boolean {
    return (item && typeof item === 'object' && !Array.isArray(item));
  }
  public mergeDeep(target: any, ...sources: any[]): any {
    if (!sources.length) return target;
    const source = sources.shift();

    if (this.isObject(target) && this.isObject(source)) {
      for (const key in source) {
        if (this.isObject(source[key])) {
          if (!target[key]) Object.assign(target, { [key]: {} });
          this.mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }

    return this.mergeDeep(target, ...sources);
  }

  //#region AUTH_SERVICE
  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Forgot password
   *
   * @param email
   */
  forgotPassword(identity: string, useEmail: boolean): Observable<IKediResponse<any>> {
    var returnUrl = location.href.replace("forgot-password", "") + "reset-password";
    return this.api.forgotPassword(identity, returnUrl, useEmail).pipe(
      catchError((error) => {
        return of({ Succeded: false, Message: error, Result: null });
      }),
      switchMap((response) => {
        if (response.Succeded) {
          return of(response);
        }
        else {
          throwError(() => new Error(response.Message));
        }
        return of(response);
      })
    );
  }

  /**
   * Confirm email
   *
   */
  confirmEmail(userid: string, confirmtoken: string): Observable<any> {
    return this.api.confirmEmail(userid, confirmtoken).pipe(
      catchError((error) => {
        return of({ Succeded: false, Message: error, Result: null });
      })
    );
  }

  /**
   * Reset password
   *
   * @param password
   */
  resetPassword(userid: string, resettoken: string, newPassword: string): Observable<any> {
    return this.api.resetPassword(userid, resettoken, newPassword).pipe(
      catchError((error) => {
        return of({ Succeded: false, Message: error, Result: null });
      })
    );
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(username: string, password: string, language: string, returnUrl: string): Observable<any> {
    // Throw error, if the user is already logged in
    // if ( this._authenticated )
    // {
    //     return throwError('User is already logged in.');
    // }
    return this.api.authenticate(username, password, this.config.siteCode, language, returnUrl).pipe(
      catchError((error) => {
        return of({ Succeded: false, Message: error, Result: null });
      }),
      switchMap((response) => {
        if (response.Succeded) {
          this.api.setToken(response.Result.Token);
          localStorage.setItem("token", response.Result.Token);
          this._authenticated = true;
          this.isLoggedIn.set(true);
          this._administrator = response.Result.IsAdmin;
          //this.setAuthStatus(this._authenticated, this._administrator);
          this._confirmed = true;
          var user: User = {
            Email: response.Result.Email,
            Id: response.Result.Id,
            UserName: response.Result.UserName,
            FirstName: (response.Result.FirstName ?? ""),
            MiddleName: (response.Result.MiddleName ?? ""),
            LastName: (response.Result.LastName ?? ""),
            // Name: ((response.Result.FirstName??"") + " " + (response.Result.MiddleName??"") + " " + (response.Result.LastName??"")).replace("  "," "),
            Avatar: response.Result.Avatar ? this.getImageUrl(response.Result.Avatar) : null, //"images/avatars/none.jpg",
            Status: response.Result.Status,
            PhoneVerified: response.Result.PhoneVerified,
            EmailVerified: response.Result.EmailVerified,
            PasswordVerified: response.Result.PasswordVerified,
            Phone: response.Result.Phone,
            About: response.Result.About,
            Company: response.Result.Company,
            Country: response.Result.Country,
            DefaultLanguage: response.Result.DefaultLanguage,
            Title: response.Result.Title,
            RoleCode: response.Result.RoleCode,
            Roles: response.Result.Roles,
            IsFirmUser: response.Result.IsFirmUser,
            IsAdmin: response.Result.IsAdmin,
            FullName: response.Result.FullName
          };
          this.setUser(user);
          return of(response);
        }
        else {
          this.deleteToken();
          return of({ Succeded: false, Message: response.Message, Result: null });
        }
      })
    );
  }

  /**
   * Sign in using the access token
   */
  signInUsingToken(): Observable<any> {
    if (!this.token) return of(false);
    return this.api.loginByToken()
      .pipe(
        catchError((error) => {
          this.signOut();
          this.reload();
          return of(false);
        }),
        switchMap((response: any) => {
          if (response.Result) {
            this.token = response.Result.Token;

            // Set the authenticated flag to true
            this._authenticated = true;
            this.isLoggedIn.set(true);
            this._administrator = response.Result.IsAdmin;
            //this.setAuthStatus(this._authenticated, this._administrator);
            this._confirmed = true;

            // Store the user on the user service
            var user: User = {
              Email: response.Result.Email,
              Id: response.Result.Id,
              UserName: response.Result.UserName,
              FirstName: (response.Result.FirstName ?? ""),
              MiddleName: (response.Result.MiddleName ?? ""),
              LastName: (response.Result.LastName ?? ""),
              // Name: ((response.Result.FirstName??"") + " " + (response.Result.MiddleName??"") + " " + (response.Result.LastName??"")).replace("  "," "),
              Avatar: response.Result.Avatar ? this.getImageUrl(response.Result.Avatar) : null, //"images/avatars/none.jpg",
              Status: response.Result.Status,
              PhoneVerified: response.Result.PhoneVerified,
              EmailVerified: response.Result.EmailVerified,
              PasswordVerified: response.Result.PasswordVerified,
              Phone: response.Result.Phone,
              About: response.Result.About,
              Company: response.Result.Company,
              Country: response.Result.Country,
              DefaultLanguage: response.Result.DefaultLanguage,
              Title: response.Result.Title,
              RoleCode: response.Result.RoleCode
            };
            this.user.set(user);
            return of(true);
          }
          else {
            this.signOut();
            this.reload();
            return of(false);
          }
        })
      );
  }

  signInViaEmail(email: string, language: string, returnUrl: string): Observable<any> {
    return this.api.authenticateByEmail(email, this.config.siteCode, language, returnUrl).pipe(
      catchError((error) => {
        return of({ Succeded: false, Message: error, Result: null });
      }),
      switchMap((response) => {
        if (response.Succeded) {
          this.api.setToken(response.Result.Token);
          localStorage.setItem("token", response.Result.Token);
          this._authenticated = true;
          this.isLoggedIn.set(true);
          this._administrator = response.Result.IsAdmin;
          //this.setAuthStatus(this._authenticated, this._administrator);
          this._confirmed = false;
          var user: User = {
            Email: response.Result.Email,
            Id: response.Result.Id,
            UserName: response.Result.UserName,
            FirstName: (response.Result.FirstName ?? ""),
            MiddleName: (response.Result.MiddleName ?? ""),
            LastName: (response.Result.LastName ?? ""),
            // Name: ((response.Result.FirstName??"") + " " + (response.Result.MiddleName??"") + " " + (response.Result.LastName??"")).replace("  "," "),
            Avatar: response.Result.Avatar ? this.getImageUrl(response.Result.Avatar) : null, //"images/avatars/none.jpg",
            Status: response.Result.Status,
            PhoneVerified: response.Result.PhoneVerified,
            EmailVerified: response.Result.EmailVerified,
            PasswordVerified: response.Result.PasswordVerified,
            Phone: response.Result.Phone,
            About: response.Result.About,
            Company: response.Result.Company,
            Country: response.Result.Country,
            DefaultLanguage: response.Result.DefaultLanguage,
            Title: response.Result.Title,
            RoleCode: response.Result.RoleCode
          };
          this.user.set(user);
          this.setStateLoaded();
          return of(response);
        }
        else {
          this.deleteToken();
          this.setStateLoaded();
          return of({ Succeded: false, Message: response.Message, Result: null });
        }
      })
    );
  }
  /**
   * Sign out
   */
  signOut(): Observable<any> {
    // Set the authenticated flag to false
    this._authenticated = false;
    this.isLoggedIn.set(false);
    this._administrator = false;
    //this.setAuthStatus(this._authenticated, this._administrator);

    // Remove the access token from the local storage
    this.token = null;
    localStorage.removeItem('token');
    this.clean();
    this.setStateLoaded();
    // Return the observable
    return of(true);
  }

  /**
   * Sign up
   *
   * @param user
   */
  signUp(user: { name: string; email: string; password: string; company: string }, adConfirmation: boolean, signupToken: string,): Observable<any> {
    return this.api.register(user.name, user.email, user.email, user.password, adConfirmation, location.origin + "/sign-in", signupToken);
  }

  /**
   * Unlock session
   *
   * @param credentials
   */
  unlockSession(credentials: { email: string; password: string }): Observable<any> {
    return this.api.kediPost("auth/unlock-session", credentials, true);
  }

  public get admin(): boolean {
    return this._administrator;
  }
  public set admin(val: boolean) {
    this._administrator = val;
  }
  public get authenticated(): boolean {
    return this._authenticated;
  }
  public set authenticated(val: boolean) {
    this._authenticated = val;
  }

  //#endregion
  // refreshBadges(badgeValues: KediBadgeValue[] ) {
  //   const mainNavigationComponent = this._fuseNavigationService.getComponent<FuseHorizontalNavigationComponent>('mainNavigation');
  //   const headerButtonsComponent = this._fuseNavigationService.getComponent<HeaderButtonsComponent>('headerButtons');
  //   if (mainNavigationComponent || headerButtonsComponent) {
  //       const mainNavigation = mainNavigationComponent.navigation ?? headerButtonsComponent.navigation;
  //       if (mainNavigation) {
  //         badgeValues.forEach(bv => {
  //           const menuItem = this._fuseNavigationService.getItemByCode(bv.code, mainNavigation);
  //           if (menuItem) {
  //             if (!menuItem.badge) {
  //                 menuItem.badge = {
  //                     title: bv.value.toString(),
  //                     classes: bv.classes // "px-2 bg-teal-400 text-black rounded-lg"
  //                 };
  //             }
  //             else {
  //                 menuItem.badge.title = bv.value.toString();
  //                 if (bv.classes) menuItem.badge.classes = bv.classes; //"px-2 bg-teal-400 text-black rounded-lg";
  //             }
  //           }
  //         });
  //       }
  //       mainNavigationComponent?.refresh();
  //       headerButtonsComponent?.refresh();
  //   }
  // }
}
