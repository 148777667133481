import { ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BooleanInput } from '@angular/cdk/coercion';
import { Subject, takeUntil } from 'rxjs';

import { FuseNavigationService } from '../../../components/navigation/navigation.service';
import { User, SessionService, ApiUserSettings } from '@kedi/core';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'user',
    standalone: true,
    imports: [NgIf, NgFor, NgClass, MatButtonModule, MatDividerModule, MatIconModule, MatMenuModule, MatTooltipModule, TranslocoModule]
})
export class UserComponent implements OnInit, OnDestroy {
    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_showAvatar: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */

    @Input() showAvatar: boolean = true;
    @Input() size: string = "7";
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    nav: FuseNavigationService;

    // get siteUserSettings() : ApiUserSettings 
    // {
    //     return this.session.config.site.userSettings;
    // }
    hasUserAction = computed(() => this.session.user()?.Id && (this.session.config.toolSettings() || this.session.config.toolProfile() || this.session.config.toolStatus()));
    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _fuseNavigationService: FuseNavigationService,
        public session: SessionService,
    ) {
        this.nav = _fuseNavigationService;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the user status
     *
     * @param status
     */
    updateUserStatus(status: string): void {
        // Return if user is not available
        if (this.session.user()) {
            this.session.setUser({ ...this.session.user(), ...{ Status: status } });
        }
    }

    /**
     * Sign out
     */
    signOut(): void {
        this._router.navigate(['/sign-out']);
    }

    /**
     * Sign out
     */
    signIn(): void {
        this._router.navigate(['/sign-in']);
    }

    /**
     * Sign out
     */
    signUp(): void {
        this._router.navigate(['/sign-up']);
    }

    userActionsMenuOpened() {
        this._fuseNavigationService.lockDense();
    }

    goToProfile() {
        this._router.navigateByUrl(this.session.config.routeProfile() ?? '/core/usersettings/account');
    }

    goToSettings() {
        this._router.navigateByUrl(this.session.config.routeSettings() ?? '/core/usersettings');
    }
}
