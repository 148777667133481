import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { SessionService } from '../session/session.service';
import { IKediResponse, ApiLogFilter, ApiLogData } from '../api/api.types';
import { TimedTask } from './timed-task.types';

@Injectable()
export class TimedTaskService {
  private tasks: TimedTask[];
  constructor(
      private _session: SessionService,
      private router: Router
    ) { 
  }

  reloadService(): Observable<IKediResponse<boolean>> {
    return this._session.api.kediPost<boolean>("core/timed-task/reload",null, true);
  }

  getTasks(): Observable<IKediResponse<TimedTask[]>> {
    return this._session.api.getDataList(TimedTask);
  }

  getTask(task: TimedTask): Observable<IKediResponse<TimedTask>> {
    return this._session.api.getDataFirst(TimedTask, "x.Code == '" + task.Code + "'");
  }

  saveTask(task: TimedTask): Observable<IKediResponse<TimedTask>> {
    return task.Id > 0 ? this._session.api.postData("TimedTask", { Body: task }) : this._session.api.putData("TimedTask", task.Id, { Body: task });
  }
  
  deleteTask(task: TimedTask): Observable<IKediResponse<boolean>> {
    return this._session.api.deleteData("TimedTask", task.Id);
  }

  startTask(task: TimedTask): Observable<IKediResponse<boolean>> {
    return this._session.api.kediPost<boolean>("core/timed-task/" + task.Code + "/start", null, true);
  }

  stopTask(task: TimedTask): Observable<IKediResponse<boolean>> {
    return this._session.api.kediPost<boolean>("core/timed-task/" + task.Code + "/stop", null, true);
  }

  callTask(task: TimedTask): Observable<IKediResponse<boolean>> {
    return this._session.api.kediPost<boolean>("core/timed-task/" + task.Code + "/call", null, true);
  }

  getLog(filter: ApiLogFilter): Observable<IKediResponse<ApiLogData>> {
    return this._session.api.kediPost<ApiLogData>("core/log", filter, true);
  }
}
