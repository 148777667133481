import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
export class JsonDateInterceptor implements HttpInterceptor {
  private _isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?/;
  
 intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(map( (val: HttpEvent<any>) => {
      if (val instanceof HttpResponse){
        const body = val.body;
        this.convertResponseBody(body);
      }
      // else if (req.method === 'POST' || req.method === 'PUT') {
      //   this.convertRequest(req.body);
      // }
      return val;
    }));
  }
  isIsoDateString(value: any): boolean {
    if (value === null || value === undefined) {
      return false;
    }
    if (typeof value === 'string'){
      return this._isoDateFormat.test(value);
    }    return false;
  }
  convertResponseBody(body: any){
    if (body === null || body === undefined ) {
      return body;
    }
    if (typeof body !== 'object' ){
      return body;
    }
    for (const key of Object.keys(body)) {
      const value = body[key];
      if (this.isIsoDateString(value)) {
        body[key] = new Date(value.split("+")[0]);
      } 
      else 
      if (typeof value === 'object') {
        this.convertResponseBody(value);
      }
    }
  }

  convertRequest(req: HttpRequest<any>):HttpRequest<any> {
    if (req.body === null || req.body === undefined || typeof req.body !== 'object') return req;
    this.convertRequestBody(req.body);
    return req;
  }  

  convertRequestBody(body: any) {
    for (const key of Object.keys(body)) {
      const value = body[key];
      if (value instanceof Date) {
          body[key] = new Date(value.setHours(value.getHours() + value.getTimezoneOffset() / -60));
      } else if (value && typeof value === 'object') {
          this.convertRequestBody(value);
      }
    }
  }
}