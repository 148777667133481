import { KediEntityBase } from "./entity.base";

export class KediFile extends KediEntityBase
{
  Key?: string;
  ContentType?: string;
  Name?: string;
  Extension?: string;
  Bytes?: Uint8Array[];
  Base64Encoded?: string;
}
export interface TemplateText extends KediEntityBase
{
    Type?: string;
    Code?: string;
    Description?: string;
    Text?: string;
    Title?: string;
    Subject?: string;
}

export interface LayerData extends KediEntityBase {
  ParentId?: number;
  SourceModule?: string;
  SourceId?: number;
  Code?: string;
  Title?: string;
  Description?: string;
  Children?: LayerData[];
}

export interface Layer {
  LayerNo?: number;
  Data?: LayerData[];
  Selected?: LayerData;
  SelectedId?: number;
}

export interface KediKeyValue {
  Key?: string;
  Value?: string;
}