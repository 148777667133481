import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '../../animations';
import { SessionService, User } from '@kedi/core';
import { Subject, takeUntil } from 'rxjs';
import { NgFor, NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';


@Component({
    selector     : 'fuse-avatar',
    templateUrl  : './avatar.component.html',
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations,
    exportAs     : 'fuseAvatar',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ NgIf, NgFor, MatIconModule ]
})
export class FuseAvatarComponent implements OnInit
{
    @Input() editMode: boolean;
    @Input() big: boolean;
    @Input() profile: boolean;
    @Input() size: string = "24";
    // user: User;
    
    get sizeClass():string {
        return "relative w-" + this.size + " h-" + this.size;
    }

    private _unsubscribeAll: Subject<any> = new Subject<any>();
    @ViewChild('avatarFileInput') private _avatarFileInput: ElementRef;
    
    /**
     * Constructor
     */
    constructor(private cdr: ChangeDetectorRef, public session: SessionService)
    {
    }

    /**
         * On init
         */
    ngOnInit(): void
    {

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    /**
         * Upload avatar
         *
         * @param fileList
         */
    uploadAvatar(fileList: FileList): void
    {
        // Return if canceled
        if ( !fileList.length )
        {
            return;
        }

        const allowedTypes = ['image/jpeg', 'image/png'];
        const file = fileList[0];

        // Return if the file is not allowed
        if ( !allowedTypes.includes(file.type) )
        {
            return;
        }

        // Upload the avatar
        this.session.uploadAvatar(file);
    }

    /**
     * Remove the avatar
     */
    removeAvatar(): void
    {
        // Set the file input value as null
        this._avatarFileInput.nativeElement.value = null;

        this.session.uploadAvatar(null);
    }      
}
