import { ChangeDetectorRef, Component, computed, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

import { FuseConfigService } from '../../../services/config';
import { FuseConfig, Scheme, Theme, Themes } from '../../../services/config';
import { SessionService, IKediConfig } from '@kedi/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { FuseDrawerComponent } from '../../../components/drawer/drawer.component';
import { NgClass, NgFor, NgIf } from '@angular/common';

@Component({
    selector     : 'settings',
    templateUrl  : './settings.component.html',
    styles       : [
        `
            settings {
                position: static;
                display: block;
                flex: none;
                width: auto;
            }

            @media (screen and min-width: 1280px) {
                empty-layout + settings .settings-cog {
                    right: 0 !important;
                }
            }

        `
    ],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgIf, NgFor, NgClass, RouterModule, MatIconModule, MatTooltipModule, FuseDrawerComponent, MatButtonModule ]
})
export class SettingsComponent implements OnInit, OnDestroy
{
    config: FuseConfig;
    layout: string;
    scheme: 'dark' | 'light';
    theme: string;
    themes: Themes;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _fuseConfigService: FuseConfigService,
        public session: SessionService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Get the themes
        // this._fuseTailwindService.tailwindConfig$
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe((config) => {
        //         let themes = Object.entries(config.themes);
        //         let tempThemes = [];
        //         themes.forEach(t => {
        //             let temp = this.sessionConfig.site.layoutSettings.AllowedThemes.find(tt => tt == t[0]);
        //             if (temp) tempThemes.push(t);
        //         });
        //         this.themes = tempThemes;
        //     });

        // Subscribe to config changes
        this._fuseConfigService.config$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config: FuseConfig) => {

                // Store the config
                this.config = config;

                let themes = config.themes;
                let tempThemes: Themes = [];
                themes.forEach(t => {
                    let temp = this.session.config.themeColors().find(c => ("theme-" + c.id) == t.id);
                    if (temp) tempThemes.push(t);
                });
                config.themes = tempThemes;                
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set the layout on the config
     *
     * @param layout
     */
    setLayout(layout: string): void
    {
        // Clear the 'layout' query param to allow layout changes
        this._router.navigate([], {
            queryParams        : {
                layout: null
            },
            queryParamsHandling: 'merge'
        }).then(() => {

            this.session.saveLayoutSettings().subscribe((response) => {
                if (response && response.Succeded) {
                    this._fuseConfigService.config = {layout};
                    this.session.setLayout(layout);
                    // this.session.config.setSite({current: { layout: layout }});
                }
                else {
                    this.session.showError(response.Message);
                }
            });
        });
    }

    /**
     * Set the scheme on the config
     *
     * @param scheme
     */
    setScheme(scheme: Scheme): void
    {
        this.session.saveLayoutSettings().subscribe((response) => {
            if (response && response.Succeded) {
                this._fuseConfigService.config = {scheme};
                this.session.setScheme(scheme);
                // this.session.config.setSite({current: { scheme: scheme }});
            }
            else {
                this.session.showError(response.Message);
            }
        });
    }

    /**
     * Set the theme on the config
     *
     * @param theme
     */
    setTheme(theme: Theme): void
    {
        this.session.saveLayoutSettings().subscribe((response) => {
            if (response && response.Succeded) {
                this._fuseConfigService.config = {theme};
                this.session.setLayout(theme);
                // this.session.config.setSite({current: { theme: theme }});
            }
            else {
                this.session.showError(response.Message);
            }
        });
    }

    setTransparentHeader(val: boolean): void {
        this.session.saveLayoutSettings().subscribe((response) => {
            if (response && response.Succeded) {
                this.session.setHeaderTransparent(val);
                // this.session.config.setSite({current: { headerTransparent: true }});
            }
            else {
                this.session.showError(response.Message);
            }
        });
    }
}
