import { Directive, ElementRef, Input, Pipe } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({
    name: "trustStyle",
    standalone: true,
})
export class TrustStylePipe {
    constructor(private sanitizer: DomSanitizer) { }

    transform(style) {
        return this.sanitizer.bypassSecurityTrustStyle(style);
    }
}


// @Directive({
//     standalone: true,
//     selector: '[kdInner]',
// })
// export class KediInnerDirective {
//     constructor(private el: ElementRef, private sanitizer: DomSanitizer) {

//     }

//     @Input() public kdInner: string;

//     transform(html) {
//         return this.sanitizer.bypassSecurityTrustHtml(html);
//     }
// }