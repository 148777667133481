import { ChangeDetectorRef, Component, computed, effect, ElementRef, Injector, OnDestroy, Renderer2, signal, untracked, ViewContainerRef, WritableSignal } from "@angular/core";
import { SessionService } from "../session/session.service";
import { TranslocoModule, TranslocoService } from "@jsverse/transloco";
import { Subject } from "rxjs";

@Component({
    template: "",
    standalone: true,
    imports: [
        TranslocoModule
    ]
})
export class KComponent implements OnDestroy {
    protected elr: ElementRef;
    protected rdr: Renderer2;
    public cdr: ChangeDetectorRef;
    protected vcr: ViewContainerRef;
    protected loc: TranslocoService;
    public session: SessionService;
    protected _unsubscribeAll: Subject<any> = new Subject<any>();
    private _localeCode: WritableSignal<string> = signal<string>(null);
    private _localeMemo: WritableSignal<Map<string, any>> = signal<Map<string, any>>(new Map<string, any>());
    // public t = (key: string) => 
    //     computed(() => {
    //         if (!this._localeMemo().has(key)) {
    //             if (!key.includes(".")) key = this._localeCode() + "." + key;
    //             let val = this.loc.translate((key.includes(".") ? "" : this._localeCode() + ".") + key);
    //             if (val) this._localeMemo().set(key, val);
    //         }
    //         return this._localeMemo().get(key);
    //     })();

    public t = (key: string) => {
        if (!this._localeMemo().has(key)) {
            if (!key.includes(".")) key = this._localeCode() + "." + key;
            let val = this.loc.translate((key.includes(".") ? "" : this._localeCode() + ".") + key);
            if (val) this._localeMemo().set(key, val);
        }
        return this._localeMemo().get(key);
    };


    protected OnSignalChanged() { }
    protected OnSignalChangedUntracked() { }
    protected OnSignalChangedCleanUp() { }


    protected _OnSignalChanged = effect((onCleanup) => {
        this.OnSignalChanged();
        onCleanup(() => {
            this.OnSignalChangedCleanUp();
        });
        untracked(() => {
            this.OnSignalChangedUntracked();
        });

    });
    constructor(injector: Injector) {
        this._localeCode.set((this.pageId ?? '').rightTrim("Page").rightTrim("Component").rightTrim("Page").rightTrim("Component").toKebab().toUpperCase());
        // this._localeCode.set(this.constructor.name.rightTrim("Page").rightTrim("Component").rightTrim("Page").rightTrim("Component").toKebab().toUpperCase());
        this.elr = injector.get(ElementRef);
        this.rdr = injector.get(Renderer2);
        this.cdr = injector.get(ChangeDetectorRef);
        this.vcr = injector.get(ViewContainerRef);
        this.loc = injector.get(TranslocoService);
        this.session = injector.get(SessionService);
    }

    ngOnDestroy() {
        this._localeMemo().clear();
        this._OnSignalChanged.destroy();
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    public Guid(): string {
        const ho = (n, p) => n.toString(16).padStart(p, 0); /// Return the hexadecimal text representation of number `n`, padded with zeroes to be of length `p`
        const view = new DataView(new ArrayBuffer(16)); /// Create a view backed by a 16-byte buffer
        crypto.getRandomValues(new Uint8Array(view.buffer)); /// Fill the buffer with random data
        view.setUint8(6, (view.getUint8(6) & 0xf) | 0x40); /// Patch the 6th byte to reflect a version 4 UUID
        view.setUint8(8, (view.getUint8(8) & 0x3f) | 0x80); /// Patch the 8th byte to reflect a variant 1 UUID (version 4 UUIDs are)
        return `${ho(view.getUint32(0), 8)}-${ho(view.getUint16(4), 4)}-${ho(view.getUint16(6), 4)}-${ho(view.getUint16(8), 4)}-${ho(view.getUint32(10), 8)}${ho(view.getUint16(14), 4)}`; /// Compile the canonical textual form from the array data
    }

    public get pageId(): string {
        return this.constructor['pageId'];
    }
}
