import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ReplaySubject, Subject, take, takeUntil } from 'rxjs';
import { fuseAnimations } from '../../../animations';
import { FuseNavigationItem } from '../../../components/navigation/navigation.types';
import { FuseNavigationService } from '../../../components/navigation/navigation.service';
import { FuseUtilsService } from '../../../services/utils/utils.service';
import { Router } from '@angular/router';
import { LangDefinition, TranslocoService } from '@jsverse/transloco';
import { SessionService, ApiLanguage, KComponent } from '@kedi/core';
import { FuseMediaWatcherService } from '../../../services/media-watcher';
import { FuseVerticalNavigationComponent } from '../../../components/navigation';
import { NgFor, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';

@Component({
    selector: 'header-buttons',
    templateUrl: './header-buttons.component.html',
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'header-buttons',
    standalone: true,
    imports: [NgIf, NgFor, MatButtonModule, MatBadgeModule, MatTooltipModule, MatIconModule, MatMenuModule]
})
export class HeaderButtonsComponent extends KComponent implements OnChanges, OnInit, OnDestroy {
    @Input() name: string = this._fuseUtilsService.randomId();
    @Input() navigation: FuseNavigationItem[] = [];

    onRefreshed: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
    // private _unsubscribeAll: Subject<any> = new Subject<any>();
    private _mainComponent: any;
    //lang: LangDefinition;
    availableLangs: string[];
    activeLang: string;

    public get lang(): ApiLanguage {
        return this.session.selectedLanguageObject;
    }
    flagCodes: any;
    nav: FuseNavigationService;
    smallScreen: boolean = false;

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseUtilsService: FuseUtilsService,
        private _router: Router,
        private _translocoService: TranslocoService,
        public session: SessionService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _injector: Injector,
    ) {
        super(_injector);
        this.nav = _fuseNavigationService;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On changes
     *
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {
        // this._translocoService.getAvailableLangs().forEach(l => {
        //     if (l.id == this._translocoService.getActiveLang()) this.lang = l;
        // }) ;

        // Navigation
        if ('navigation' in changes) {
            // Mark for check
            this._changeDetectorRef.markForCheck();
        }
    }

    /**
    * On init
    */
    ngOnInit(): void {
        // Get the available languages from transloco
        this.availableLangs = this._translocoService.getAvailableLangs().map(l => l.id);

        // Subscribe to language changes
        this._translocoService.langChanges$.subscribe((activeLang) => {

            // Get the active lang
            this.activeLang = activeLang;

            // Update the navigation
            this._updateNavigation(activeLang);
        });

        this.flagCodes = {
            'en': 'us',
            'tr': 'tr'
        };

        // Make sure the name input is not an empty string
        if (this.name === '') {
            this.name = this._fuseUtilsService.randomId();
        }

        // Register the navigation component
        this._fuseNavigationService.registerComponent(this.name, this);

        // Get the parent navigation component
        this._mainComponent = this._fuseNavigationService.getComponent(this.name);

        // Mark for check
        this._changeDetectorRef.markForCheck();

        // Subscribe to onRefreshed on the navigation component
        this._mainComponent.onRefreshed.pipe(
            takeUntil(this._unsubscribeAll)
        ).subscribe(() => {

            // Mark for check
            this._changeDetectorRef.markForCheck();
        });

        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {

                // Set the drawerMode and drawerOpened
                if (matchingAliases.includes('lg')) {
                    this.smallScreen = false;
                }
                else {
                    this.smallScreen = true;
                }

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Deregister the navigation component from the registry
        this._fuseNavigationService.deregisterComponent(this.name);

        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Refresh the component to apply the changes
     */
    refresh(): void {
        // Mark for check
        this._changeDetectorRef.markForCheck();

        // Execute the observable
        this.onRefreshed.next(true);
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    buttonClicked(item: FuseNavigationItem) {
        if (item.disabled == true) return;
        if (item.function) item.function(item);
        if (item.link) {
            if (!item.externalLink) {
                this._router.navigateByUrl(item.link);
            }
            else {
                window.open(item.link, item.target || '_blank').focus();
            }
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the navigation
     *
     * @param lang
     * @private
     */
    private _updateNavigation(_lang: string): void {
        // For the demonstration purposes, we will only update the Dashboard names
        // from the navigation but you can do a full swap and change the entire
        // navigation data.
        //
        // You can import the data from a file or request it from your backend,
        // it's up to you.

        // Get the component -> navigation data -> item
        const navComponent = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>('mainNavigation');

        // Return if the navigation component does not exist
        if (!navComponent) {
            return null;
        }

        // Get the flat navigation data
        const navigation = navComponent.navigation;

        // Get the Project dashboard item and update its title
        const projectDashboardItem = this._fuseNavigationService.getItem('dashboards.project', navigation);
        if (projectDashboardItem) {
            this._translocoService.selectTranslate('Project').pipe(take(1))
                .subscribe((translation) => {

                    // Set the title
                    projectDashboardItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }

        // Get the Analytics dashboard item and update its title
        const analyticsDashboardItem = this._fuseNavigationService.getItem('dashboards.analytics', navigation);
        if (analyticsDashboardItem) {
            this._translocoService.selectTranslate('Analytics').pipe(take(1))
                .subscribe((translation) => {

                    // Set the title
                    analyticsDashboardItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }
    }
}
