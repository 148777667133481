import { map } from 'rxjs';
import { SessionService } from '../../session/session.service';
import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { ensureValue } from '../../../pipes/rxjs-ensure/rxjs-ensure';
import { PageGuardType } from '../../api/api.types';
import { KEDI_SITE_SETTINGS, KediSiteSettings } from '../../session/session.provider';

export const PageGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
    const _session: SessionService = inject(SessionService);
    const _router: Router = inject(Router);
    const _siteSettings: KediSiteSettings = inject(KEDI_SITE_SETTINGS);
    const _redirectUrl = state.url === '/sign-out' ? '/' : state.url;
    const url: string = route["_routerState"]?.url ?? '';
    const componentName = ((route.component ? route.component['pageId'] : null) ?? "");
    console.log('[PG] url: ' + url);
    // console.log('[PG] route object: ', route);

    return _session.StateLoadedSubject$.pipe(ensureValue(this), map((isLoaded) => {
        if (!isLoaded) {
            console.log('[PG] isLoaded: false');
            return false;
        }

        if (!route.component) {
            console.log('[PG] route.component: null');
            return true;
        }
        if (_session.MustChangePassword == true && state.url.replace(/^\/+|\/+$/g, '') != _session.ChangePasswordRoute.replace(/^\/+|\/+$/g, '')) {
            console.log('[PG] must change password!');
            _router.navigate([_session.ChangePasswordRoute]);
            return false;
        }

        const _isAuthenticated = _session.authenticated;
        const _isAdmin = _session.admin;

        console.log('[PG] componentName: ' + componentName);

        if (componentName == "LayoutComponent") {
            console.log('[PG] navigate is LayoutComponent');
            return true;
        }
        if (componentName == "DefaultPage") {
            console.log('[PG] navigate is DefaultPage');
            if (_session.config.defaultRoute()) {
                console.log('[PG] Default route result: false. Navigate to defaultRoute()');
                _router.navigate([_session.config.defaultRoute()]);
                return false;
            }
            else {
                console.log('[PG] Default route result: true. passing');
                return true;
            }
        }
        const _page = _session.getPage(componentName);
        let _pageGuard = PageGuardType.Anonym;
        let _pageAuthorized = true;
        if (_page) {
            _pageGuard = _page.Guard;
            _pageAuthorized = _page.Authorized;
        }
        //AnonymGuard
        switch (_pageGuard) {
            case PageGuardType.Default:
            //return checkSiteAuth(url, componentName, _redirectUrl);
            case PageGuardType.Admin:
                if (!_isAdmin) {
                    _session.showError("Bu sayfaya giriş yapmak için yönetici yetkisine sahip olmalısınız");
                    return false;
                }
                if (!_pageAuthorized) {
                    _session.showError("Bu sayfaya giriş yetkiniz bulunmamaktadır");
                    return false;
                }
                break;
            case PageGuardType.Auth:
                console.log('[PG] page is auth');
                if (!_isAuthenticated) {
                    _router.navigate(['sign-in'], { queryParams: { _redirectUrl } });
                    return false;
                }
                if (!_pageAuthorized) {
                    _session.showError("Bu sayfaya giriş yetkiniz bulunmamaktadır");
                    return false;
                }
                break;
            case PageGuardType.NoAuth:
                if (_isAuthenticated) {
                    console.log('[PG] navigated to: ""');
                    _router.navigate(['']);
                    return false;
                }
        }
        return checkSiteAuth(url, componentName, _redirectUrl);

        // //NoAuthGuard
        // if (_page.Guard == PageGuardType.NoAuth) {
        //     _router.navigate(['']);
        //     return false;
        // } 
        // //AuthGuard
        // if (!_isAuthenticated) {
        //     _router.navigate(['sign-in'], { queryParams: { _redirectUrl } });
        //     return false;
        // }
        // else {
        //     //AuthGuard
        //     if (!_page.Authorized) {
        //         _session.showError("Bu sayfaya giriş yetkiniz bulunmamaktadır");
        //         return false;
        //     }

        //     //AdminGuard
        //     if (_page.NeedsAdmin && !_isAdmin) {
        //         _session.showError("Bu sayfaya giriş yapmak için yönetici yetkisine sahip olmalısınız");
        //         return false;
        //     }

        //     return true;
        // }
    }));

    function checkSiteAuth(url: string, componentName: string, _redirectUrl: string) {
        if (_siteSettings?.auth?.signin?.anonymous == false && !_session.authenticated) {
            if (componentName.startsWith("Auth")) {
                console.log('[PG] componentName starts with Auth');
                return true;
            }
            console.log('[PG] navigating sign-in');
            console.log('[PG] checkSiteAuth: false');
            _router.navigate(['sign-in'], { queryParams: { _redirectUrl } });
            return false;
        }

        console.log('[PG] checkSiteAuth: true');
        return true;
    }
};
