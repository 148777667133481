import { Directive, ElementRef, Input, Pipe } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({
    name: "trustResourceUrl",
    standalone: true,
})
export class TrustResourceUrlPipe {
    constructor(private sanitizer: DomSanitizer) { }

    transform(resourceUrl) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(resourceUrl);
    }
}
