import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { SessionService } from '../session/session.service';
import { IKediResponse } from '../api/api.types';

@Injectable({ providedIn: 'root' })
export class CoreService {

  private scripts: any = {};

  constructor(
    private _session: SessionService,
    private router: Router
  ) {
  }

  getHolidays(start: Date, end: Date, countryCode?: string): Observable<IKediResponse<{ date: Date, name: string }[]>> {
    let _countryCode = countryCode ? countryCode : "TR";
    return this._session.api.kediPost<{ date: Date, name: string }[]>("base/holidays", { countryCode: _countryCode, start: start, end: end }, true);
  }

  public loadScripts(...scripts: string[]): Promise<any[]> {
    var promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  public loadScript(path: string): Promise<any> {
    return new Promise((resolve) => {
      var name = path.replaceAll('/', '_').replaceAll('.', '_');
      var s = document.getElementsByName("core_script_" + name);
      if(!this.scripts[name]) {
        this.scripts[name] = {};
      }
      this.scripts[name].loaded = false;
      if (s && s.length > 0) {
        var parentNode = s[0].parentNode;
        parentNode.removeChild(s[0]);
      }
      //resolve if already loaded
      if (this.scripts[name].loaded) {
        resolve({ script: path, loaded: true, status: 'Already Loaded' });
      }
      else {
        //load script
        let script: any = document.createElement('script');
        script.name = "core_script_" + name;
        script.type = 'text/javascript';
        script.src = path;
        if (script.readyState) {  //IE
          script.onreadystatechange = () => {
            if (script.readyState === "loaded" || script.readyState === "complete") {
              script.onreadystatechange = null;
              this.scripts[name].loaded = true;
              resolve({ script: path, loaded: true, status: 'Loaded' });
            }
          };
        } else {  //Others
          script.onload = () => {
            this.scripts[name].loaded = true;

            resolve({ script: path, loaded: true, status: 'Loaded' });
          };
        }
        script.onerror = () => resolve({ script: path, loaded: false, status: 'Loaded' });
        document.getElementsByTagName('body')[0].appendChild(script);
      }
    });
  }
}
