import { EventEmitter, Injectable } from '@angular/core';  
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';  
import { BehaviorSubject, map, Observable, of, switchMap, tap, throwError, take, filter } from 'rxjs';
import { SessionService } from '@kedi/core';
import { ChatRoom, ChatMember,ChatMessage } from './chat.types';  
  
@Injectable({ providedIn: 'root'})
export class ChatService {  
    connectionEstablished = new EventEmitter<Boolean>();  
    messageReceived = new EventEmitter<ChatMessage>();  
    //private _state: BehaviorSubject<ChatRoom | null> = new BehaviorSubject(null);
    private _room: BehaviorSubject<ChatRoom> = new BehaviorSubject(null);
    private _rooms: BehaviorSubject<ChatRoom[]> = new BehaviorSubject<ChatRoom[]>(null);
    private _contact: BehaviorSubject<ChatMember> = new BehaviorSubject(null);
    private _contacts: BehaviorSubject<ChatMember[]> = new BehaviorSubject(null);
    private _activeMember: BehaviorSubject<ChatMember> = new BehaviorSubject(null);

    private activeMember: ChatMember;
    private room: ChatRoom;
    private rooms: ChatRoom[];
    private contact: ChatMember;
    private contacts: ChatMember[];
    private _hubConnection: HubConnection;  
    private thenable: Promise<void>
    private _session: SessionService;
    private connectionIsEstablished: boolean;
    constructor(_session: SessionService) {
        this._session = _session;
        
        if (_session.config.toolChat()) {
            this.createConnection();  
            this.registerOnServerEvents();  
            this.startConnection(); 
            // this.start().subscribe();
        } 

        this._rooms.subscribe(data => {
            let _room = null;
            if (this.room) {
                _room = this.rooms.find(r => r.id == this.room.id);
            }
            this.rooms = data;
            this._room.next(_room);
        });

        this._room.subscribe(data => {
            this.room = data;
        });

        this._contacts.subscribe(data => {
            let _contact = null;
            if (this.contact) {
                _contact = this.contacts.find(c => c.id == this.contact.id);
            }
            this.contacts = data;
            this._contact.next(_contact);
        });

        this._contact.subscribe(data => {
            this.contact = data;
        });
    }  

    // public getState(): Observable<ChatRoom[]> {
    //     return this._session.api.kediPost<ChatRoom[]>("chat/state", {}, true).pipe(
    //         map((response) => {
    //             if (!response.Succeded) {
    //                 this._session.showError(response.Message);
    //                 throwError(() => new Error(response.Message));
    //                 return null;
    //             }
    //             // this._state.next(response.Result);         
    //             this._rooms.next(response.Result);       
    //             // this._contacts.next(response.Result.contacts);       
    //             return response.Result;
    //         })
    //     );
    // }  

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------
    get room$(): Observable<ChatRoom>
    {
        return this._room.asObservable();
    }
    get rooms$(): Observable<ChatRoom[]>
    {
        return this._rooms.asObservable();
    }
    get activeMember$(): Observable<ChatMember>
    {
        return this._activeMember.asObservable();
    }    
    get contact$(): Observable<ChatMember>
    {
        return this._contact.asObservable();
    }    
    get contacts$(): Observable<ChatMember[]>
    {
        return this._contacts.asObservable();
    }
  
     // -----------------------------------------------------------------------------------------------------
     // @ Public methods
     // -----------------------------------------------------------------------------------------------------
 
    getRooms(): Observable<ChatRoom[]>
    {
        return this._session.api.kediGet<ChatRoom[]>("chat/rooms", true).pipe(
            map(response => {
                this._rooms.next(response.Result);
                return response.Result;
            })
        );
    }    

    getRoom(_id: string)
    {
        return this._session.api.kediGet<ChatRoom>("chat/room/"+_id, true).pipe(
            map(response => {
                let roomIdx = this.rooms.findIndex(r => r.id == _id);
                if (roomIdx >= 0) {
                    this.rooms.splice(roomIdx,1,response.Result);
                }
                this._room.next(response.Result);
                return response.Result;
            })
        );
    }

    getContact(_id: string)
    {
        return this._session.api.kediGet<ChatMember>("chat/member/"+_id, true).pipe(
            map(response => {
                let contactIdx = this.contacts.findIndex(r => r.id == _id);
                if (contactIdx >= 0) {
                    this.contacts.splice(contactIdx,1,response.Result);
                }
                this._contact.next(response.Result);
                return response.Result;
            })
        );
    }

    getContacts(): Observable<ChatMember[]>
    {
        return this._session.api.kediGet<ChatMember[]>("chat/members", true).pipe(
            map(response => {
                this._contacts.next(response.Result);
                return response.Result
            })
        );
    }  
    
    // public receiveContacts(): Observable<ChatMember[]> {
    //     return this._session.api.kediPost<ChatMember[]>("chat/contacts", {}, true).pipe(
    //         map((response) => {
    //             if (!response.Succeded) {
    //                 this._session.showError(response.Message);
    //                 throwError(() => new Error(response.Message));
    //                 return null;
    //             }
    //             this._contacts.next(response.Result);                
    //             return response.Result;
    //         })
    //     );
    // }  

 
    updateRoom(_id: string, _room: ChatRoom)
    {
        this._hubConnection.invoke('UpdateRoom', _room);   

    }
 
    /**
     * Reset the selected chat
     */
    resetRoom(): void
    {
        this._room.next(null);
    }    
 
    createPrivateRoom(contact: ChatMember): Observable<ChatRoom> {
        return this._session.api.kediPost<ChatRoom>("chat/room/create/"+contact.id, {},true).pipe(
            tap(response => {
                this.rooms.push(response.Result);
                this._rooms.next([...this.rooms]);
            }),
            map(response => response.Result)
        );
    }
 
     /**
      * Get contacts
      */
    //  getContacts(): Observable<Contact[]>
    //  {
    //     let call: HubData<any> = { id: this.Guid(), data: null };
    //     this._hubConnection.invoke('AskContacts', call);            
    //     return this._hubCallResponded.pipe(
    //         filter(result => result.id == call.id),
    //         map(result => { return result.data; }),
    //         tap((response: Contact[]) => {
    //             this._contacts.next(response);
    //         })
    //     );
    //  }    
 
     /**
      * Get contact
      *
      * @param id
      */
       
    sendMessage(roomId: string, message: ChatMessage): Observable<ChatMessage>  {  
        this._hubConnection.invoke('SendMessage', message.id, roomId, message.body);            
        return of(message);

        // return this._session.api.kediPost<ChatRoom>("chat/message/send/"+roomId, message,true).pipe(
        //     map(response => response.Result)
        // );
    }  

    // public start(): Observable<any> {
    //     this.createConnection();  
    //     this.registerOnServerEvents();  
    //     this.startConnection(); 
    //     return this.getRooms(); 
    // }
    
    private createConnection() {  
        this._hubConnection = new HubConnectionBuilder()
        .withUrl(this._session.config.apiUrl + 'hub/chat', { accessTokenFactory: () => "Bearer " + this._session.token }) 
        .configureLogging(LogLevel.Information)
        .build();  
    }  

    private startConnection(): void {  
        this.thenable = this._hubConnection.start();
        this.thenable.then(() => {  
            this.connectionIsEstablished = true;  
            this.connectionEstablished.emit(true);  
        })  
        .catch(err => {  
            let _this = this;
            console.error('Error while establishing connection, retrying...');  
            setTimeout(function () { _this.startConnection(); }, 5000);  
        });  
    }  

    private registerOnServerEvents(): void {  
        this._hubConnection.on('ReceiveRooms', (_rooms: ChatRoom[]) => {  
            this.onReceiveRooms(_rooms);
        });  

        this._hubConnection.on('ReceiveError', (_messageId: string, _message: string) => {  
            this.onReceiveError(_messageId, _message);
        });  

        this._hubConnection.on('ReceiveMessage', (_roomId:string, _message: ChatMessage) => {  
            this.onReceiveMessage(_roomId, _message)
        });  

        this._hubConnection.on('ReceiveRoom', (_room: ChatRoom) => {  
            this.onReceiveRoom(_room)
        });  

        this._hubConnection.on('ReceiveActiveMember', (_member: ChatMember) => {  
            this.onReceiveActiveMember(_member)
        });  
    }
    
    onReceiveMessage(_roomId: string, _message: ChatMessage) {
        const index = this.rooms.findIndex(item => item.id === _roomId);
        this.rooms[index]?.messages.push(_message);  
        this.messageReceived.emit(_message);
    }

    onReceiveRoom(_room: ChatRoom) {
        const index = this.rooms.findIndex(item => item.id === _room.id);
        this.rooms[index] = _room;
        this._rooms.next(null);
        this._rooms.next(this.rooms);
        this._room.next(null);
        this._room.next(_room);  
    }

    onReceiveActiveMember(_activeMember: ChatMember) {
        this._activeMember.next(_activeMember);
    }
    
    onReceiveRooms(_rooms: ChatRoom[]) {
        this._rooms.next(_rooms);
    }

    onReceiveError(_messageId: string,_message: string) {
        if (_messageId) {
            let messages = this._room.getValue()?.messages;
            if (messages) {
                let messageIdx = messages?.findIndex(m => m.id == _messageId);
                if (messageIdx || messageIdx === 0) {
                    messages.splice(messageIdx);
                }
            }
        }
        console.error(_message);
        this._session.toastError(_message, _messageId);
    }

    public Guid(): string {
        const ho = (n, p) => n.toString(16).padStart(p, 0); /// Return the hexadecimal text representation of number `n`, padded with zeroes to be of length `p`
        const view = new DataView(new ArrayBuffer(16)); /// Create a view backed by a 16-byte buffer
        crypto.getRandomValues(new Uint8Array(view.buffer)); /// Fill the buffer with random data
        view.setUint8(6, (view.getUint8(6) & 0xf) | 0x40); /// Patch the 6th byte to reflect a version 4 UUID
        view.setUint8(8, (view.getUint8(8) & 0x3f) | 0x80); /// Patch the 8th byte to reflect a variant 1 UUID (version 4 UUIDs are)
        return `${ho(view.getUint32(0), 8)}-${ho(view.getUint16(4), 4)}-${ho(view.getUint16(6), 4)}-${ho(view.getUint16(8), 4)}-${ho(view.getUint32(10), 8)}${ho(view.getUint16(14), 4)}`; /// Compile the canonical textual form from the array data    
    }    
}    