import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { VOEDashboardRequest, VOEDashboardView, VOELogData, VOELogFilter, VOEProcedure, VOEAgent, VOEData, VOEBroker, VOECodeFile, VOEParam, VOEParamType, VOEParamMode, VOEApi, VOEApiController, VOEApiMethod, VOEApiProcedure, VOETask, VOEPeriod, VOEAction, VOESessionInfo, VOEDashboard, UyumCompany, VOEUser, VOEUserGroup, VOEQuery, VOEQueryResponse, VOEQueryRequest, VOEQueryColumn } 
    from './integration.types';
import { map, tap } from 'rxjs/operators';
import { SessionService } from '../session/session.service';
import { IKediDataResult, IKediResponse } from '../api/api.types';

@Injectable()
export class IntegrationService extends VOEData {
  private settings: any;
  private brokers: VOEBroker[];
  public currentToken: string;
  public sessionInfo: VOESessionInfo;
  public integrationSettings$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _integrationSettings: any;
  public Loaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Token': ''
    })
  };
  constructor(
      private _session: SessionService
    ) { 
    super();
    this.integrationSettings$.subscribe(settings => this._integrationSettings = settings );
    this.reload();
  }

  reload() {
    this.getIntegrationSettings().subscribe((response) => {
      if (!response.Succeded) {
        this._session.showError("Integration token could not be fetched: " + response.Message);
        return;
      }
      this.getSessionInfo().subscribe({
          next: (response) => {
            if (response.Succeded) {
              this.sessionInfo = response.Result;  
              this.Loaded.next(true);
            }
            else {
              this.sessionInfo = null;
              this._session.showError(response.Message);
            }
          },
          error: (error) => {
            this.sessionInfo = null;
            this._session.showError(error.message);
          }
        });
      });    
  }

  reloadServer(): Observable<IKediResponse<boolean>> {
    return this._session.api.voePost<boolean>("voe/reloadserver",false, true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  getIntegrationSettings(): Observable<IKediResponse<any>> {
    return this._session.api.kediPost<string>("integration/settings/voe",null, true)
    .pipe(
      tap((response) => {
        if (!response.Succeded) throwError(() => new Error(response.Message));
        else if (!response.Result || response.Result == "") throwError(() => new Error("VOE entegrasyon ayarları tanımlı değil"));
        else {
          this.integrationSettings$.next(response.Result);
        }
      })
    );
  }

  getSessionInfo(): Observable<IKediResponse<VOESessionInfo>> {
    return this._session.api.voePost<VOESessionInfo>("voe/sessionInfo",null, true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  getBrokers(): Observable<IKediResponse<IKediDataResult<VOEBroker>>> {
    return this._session.api.voeGet<VOEBroker[]>("voe/brokers",true, [{ name: "Token", value: this._integrationSettings.defaultToken}]).pipe(
      map((response:IKediResponse<VOEBroker[]>) => {
        if (!response.Succeded) return { Succeded: false, Message: response.Message, Result: null};
        return {
          Succeded: true,
          Result: { Data: response.Result, PageInfo: null },
          Message: null
        };
      })
    );
  }

  getBroker(broker: VOEBroker): Observable<IKediResponse<VOEBroker>> {
    return this._session.api.voeGet<VOEBroker>("voe/" + (!broker || broker.Name == "" ? null : broker.Name),true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  saveBroker(brokername: string, broker: VOEBroker): Observable<IKediResponse<VOEBroker>> {
    //broker.Id = brokername;
    return this._session.api.voePost<VOEBroker>("voe/" + brokername + "/save", broker, true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }
  
  recreateCode(broker: any): Observable<IKediResponse<VOEProcedure[]>> {
    return this._session.api.voeGet<VOEProcedure[]>("voe/" + (!broker || broker.Name == "" ? null : broker.Name) + "/recreatecode",true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  createAssembly(broker: any): Observable<string> {
    return this._session.api.voeGetBlob("voe/" + (!broker || broker.Name == "" ? null : broker.Name) + "/createassembly",true, [{ name: "Token", value: this._integrationSettings.defaultToken}])
    // return this.http.get(this.settings.voeUrl + (!broker || broker.Name == "" ? null : broker.Name) + "/createassembly",  {
    //   headers: new HttpHeaders({
    //     'Content-Type':  'application/json',
    //     'Token': this.currentToken
    //   }),
    //   responseType: 'blob',
    //   observe: 'body'
    // })
      .pipe(
        map((blob => {
          return URL.createObjectURL(blob);
        }))
      );
  }

  deleteBroker(broker: any): Observable<IKediResponse<VOEBroker[]>> {
    return this._session.api.voePost<VOEBroker[]>("voe/" + (!broker || broker.Name == "" ? null : broker.Name) + "/delete", broker, true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  startBroker(broker: VOEBroker): Observable<IKediResponse<VOEAgent[]>> {
    return this._session.api.voePost<VOEAgent[]>("voe/" + (!broker || broker.Name == "" ? null : broker.Name) + "/start", null, true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  shutdownBroker(broker: VOEBroker): Observable<IKediResponse<VOEAgent[]>> {
    return this._session.api.voePost<VOEAgent[]>("voe/" + (!broker || broker.Name == "" ? null : broker.Name) + "/shutdown", null, true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  getDocumentation(apiname) {
    window.open(this._session.api.apiUrl + apiname + "/documentation", '_blank');
  }

  getAgents(broker: VOEBroker): Observable<IKediResponse<VOEAgent[]>> {
    return this._session.api.voeGet<VOEAgent[]>("voe/" + (!broker || broker.Name == "" ? null : broker.Name) + "/agents",true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  getAgent(broker: VOEBroker, agent: VOEAgent): Observable<IKediResponse<VOEAgent>> {
    return this._session.api.voeGet<VOEAgent>("voe/" + (!broker || broker.Name == "" ? null : broker.Name) + "/agent/" + agent.Port,true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  addAgents(broker: VOEBroker, agentCount: number): Observable<IKediResponse<VOEAgent[]>> {
    return this._session.api.voePost<VOEAgent[]>("voe/" + (!broker || broker.Name == "" ? null : broker.Name) + "/addagent", agentCount, true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  removeAgents(broker: VOEBroker, agentCount: number): Observable<IKediResponse<VOEAgent[]>> {
    return this._session.api.voePost<VOEAgent[]>("voe/" + (!broker || broker.Name == "" ? null : broker.Name) + "/removeagent", agentCount, true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  killAgent(broker: VOEBroker, pid: number): Observable<IKediResponse<VOEAgent[]>> {
    return this._session.api.voePost<VOEAgent[]>("voe/" + (!broker || broker.Name == "" ? null : broker.Name) + "/killagent", pid, true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  getSampleController(broker: VOEBroker): Observable<IKediResponse<string>> {
    return this._session.api.voeGet<string>("voe/" + (!broker || broker.Name == "" ? null : broker.Name) + "/samplecontroller", true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  getSampleModel(broker: VOEBroker): Observable<IKediResponse<string>> {
    return this._session.api.voeGet<string>("voe/" + (!broker || broker.Name == "" ? null : broker.Name) + "/samplemodel", true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  getSampleClient(broker: VOEBroker): Observable<IKediResponse<string>> {
    return this._session.api.voeGet<string>("voe/" + (!broker || broker.Name == "" ? null : broker.Name) + "/sampleclient", true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }
  getSampleRequest(broker: VOEBroker, procedure: VOEProcedure): Observable<IKediResponse<string>> {
    return this._session.api.voeGet<string>("voe/" + (!broker || broker.Name == "" ? null : broker.Name) + "/procedures/" + procedure.Name, true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  getLog(broker: VOEBroker, filter: VOELogFilter): Observable<IKediResponse<VOELogData>> {
    return this._session.api.voePost<VOELogData>("voe/" + (!broker || broker.Name == "" ? null : broker.Name) + "/log", filter, true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  getCodeFiles(broker: VOEBroker): Observable<IKediResponse<VOECodeFile[]>> {
    return this._session.api.voeGet<VOECodeFile[]>("voe/" + (!broker || broker.Name == "" ? null : broker.Name) + "/codeFiles", true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  setProcedures(broker: VOEBroker, codeFiles: VOECodeFile[]): Observable<IKediResponse<VOEProcedure[]>> {
    return this._session.api.voePost<VOEProcedure[]>("voe/" + (!broker || broker.Name == "" ? null : broker.Name) + "/setprocedures", codeFiles, true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  setUrl(broker:VOEBroker, procedure:VOEProcedure, url:string): Observable<IKediResponse<boolean>> {
    let param: VOEParam = {
      Id: 0,
      Mode: VOEParamMode.INPUT,
      ModeName: "",
      Name: url,
      Signature: "",
      Type: VOEParamType.CHARACTER,
      TypeName: ""
    };
    return this._session.api.voePost<boolean>("voe/" + (!broker || broker.Name == "" ? null : broker.Name) + "/procedures/" + procedure.Name + "/seturl", param, true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  setSampleToken(broker:VOEBroker, token:string): Observable<IKediResponse<boolean>> {
    let param: VOEParam = {
      Id: 0,
      Mode: VOEParamMode.INPUT,
      ModeName: "",
      Name: token,
      Signature: "",
      Type: VOEParamType.CHARACTER,
      TypeName: ""
    };
    return this._session.api.voePost<boolean>("voe/" + (!broker || broker.Name == "" ? null : broker.Name) + "/settoken", param, true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }


  call(url: string, method: string, token: string, body:any): Observable<IKediResponse<any>> {
    if (!token) token = "";
    let httpOptions = {
      headers: new HttpHeaders({
        'Referrer-Policy': 'no-referrer, strict-origin-when-cross-origin',
        'Content-Type':  'application/json',
        'Token': token
      })          
    }
    if (method == "get") {
      return this._session.api.voeGet<any>("voe/" + url, true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
    }
    if (method == "post") {
      let bodyObject: any;
      try {
        bodyObject = JSON.parse(body);
      }
      catch(error) {

      }
      return this._session.api.voePost<any>("voe/" + url, body, true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
    }
  }

  getApis(): Observable<IKediResponse<VOEApi[]>> {
    return this._session.api.voeGet<VOEApi[]>("voe/" + "apis", true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  getApi(api: VOEApi): Observable<IKediResponse<VOEApi>> {
    return this._session.api.voeGet<VOEApi>("voe/" + "api/" +  (!api || api.Name == "" ? null : api.Name), true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  saveApi(apiname: string, api: VOEApi): Observable<IKediResponse<VOEApi>> {
    //api.Id = apiname;
    return this._session.api.voePost<VOEApi>("voe/" + "api/" + apiname + "/save", api, true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  deleteApi(api: VOEApi): Observable<IKediResponse<VOEApi[]>> {
    return this._session.api.voePost<VOEApi[]>("voe/" + "api/" + (!api || api.Name == "" ? null : api.Name) + "/delete", api, true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  getApiLog(api: VOEApi, filter: VOELogFilter): Observable<IKediResponse<VOELogData>> {
    return this._session.api.voePost<VOELogData>("voe/" + "api/" + (!api || api.Name == "" ? null : api.Name) + "/log", filter, true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  setControllerProcedures(api: VOEApi, controller: VOEApiController, procedures: VOEApiProcedure[]): Observable<IKediResponse<VOEApiMethod[]>> {
    return this._session.api.voePost<VOEApiMethod[]>("voe/" + "api/" + (!api || api.Name == "" ? null : api.Name) + "/controller/" + controller.Name + "/setprocedures", procedures, true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  getSampleModelCode(api:VOEApi): Observable<IKediResponse<string>> {
    return this._session.api.voeGet<string>("voe/" + "api/" +  (!api || api.Name == "" ? null : api.Name) + "/samplemodel", true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  getSampleControllerCode(api:VOEApi, controller:VOEApiController): Observable<IKediResponse<string>> {
    return this._session.api.voeGet<string>("voe/" + "api/" +  (!api || api.Name == "" ? null : api.Name) + "/controller/" + controller.Name + "/samplecontroller", true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }
  
  getSampleTestCode(api:VOEApi, controller:VOEApiController, method:VOEApiMethod): Observable<IKediResponse<string>> {
    return this._session.api.voeGet<string>("voe/" + "api/" +  (!api || api.Name == "" ? null : api.Name) + "/controller/" + controller.Name + "/method/" + method.Name + "/sampletest", true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  getTasks(): Observable<IKediResponse<VOETask[]>> {
    return this._session.api.voeGet<VOETask[]>("voe/tasks", true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  getTask(task: VOETask): Observable<IKediResponse<VOETask>> {
    return this._session.api.voeGet<VOETask>("voe/tasks" + (!task || task.Name == "" ? null : task.Name), true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  saveTasks(taskname: string, task: VOETask): Observable<IKediResponse<VOETask>> {
    return this._session.api.voePost<VOETask>("voe/tasks/" + taskname + "/save", task, true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }  

  startTask(task: VOETask): Observable<IKediResponse<VOETask>> {
    return this._session.api.voePost<VOETask>("voe/tasks/" + (!task || task.Name == "" ? null : task.Name) +"/start", task, true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  shutdownTask(task: VOETask): Observable<IKediResponse<VOETask>> {
    return this._session.api.voePost<VOETask>("voe/tasks/" + (!task || task.Name == "" ? null : task.Name) +"/shutdown", null, true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  getTaskLog(task:VOETask, filter: VOELogFilter): Observable<IKediResponse<VOELogData>> {
    return this._session.api.voePost<VOELogData>("voe/tasks/" + (!task || task.Name == "" ? null : task.Name) +"/log", filter, true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  deleteTask(task: any): Observable<IKediResponse<VOETask[]>> {
    return this._session.api.voePost<VOETask[]>("voe/tasks/" + (!task || task.Name == "" ? null : task.Name) +"/delete", task, true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  saveTask(taskname: string, task: VOETask): Observable<IKediResponse<VOETask>> {
    return this._session.api.voePost<VOETask>("voe/tasks/" + taskname + "/save", task, true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  getPeriodDescription(period:VOEPeriod): Observable<IKediResponse<string>> {
    return this._session.api.voePost<string>("voe/tasks/" + "/perioddesc", period, true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  getActionDescription(action:VOEAction): Observable<IKediResponse<string>> {
    return this._session.api.voePost<string>("voe/tasks/" + "/actiondesc", action, true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  getQueries(): Observable<IKediResponse<VOEQuery[]>> {
    return this._session.api.voeGet<VOEQuery[]>("voe/queries", true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  getQuery(query: VOEQuery): Observable<IKediResponse<VOEQuery>> {
    return this._session.api.voeGet<VOEQuery>("voe/queries/" + (!query || query.Name == "" ? null : query.Name), true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  saveQueries(queryname: string, query: VOEQuery): Observable<IKediResponse<VOEQuery>> {
    return this._session.api.voePost<VOEQuery>("voe/queries/" + queryname + "/save", query, true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }  

  runQuery(query: VOEQueryRequest): Observable<IKediResponse<VOEQueryResponse>> {
    return this._session.api.voePost<VOEQueryResponse>("voe/queries/" + (!query || query.queryName == "" ? null : query.queryName) +"/run", query, true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  runQueryString(brokername:string, query: VOEQueryRequest): Observable<IKediResponse<VOEQueryResponse>> {
    return this._session.api.voePost<VOEQueryResponse>("voe/" + brokername +"/runquery", query, true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  deleteQuery(query: any): Observable<IKediResponse<VOEQuery[]>> {
    return this._session.api.voePost<VOEQuery[]>("voe/queries/" + (!query || query.Name == "" ? null : query.Name) +"/delete", query, true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  saveQuery(queryname: string, query: VOEQuery): Observable<IKediResponse<VOEQuery>> {
    return this._session.api.voePost<VOEQuery>("voe/queries/" + queryname + "/save", query, true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  saveQueryView(queryname: string, queryview: VOEQueryColumn[]): Observable<IKediResponse<boolean>> {
    return this._session.api.voePost<boolean>("voe/queries/" + queryname + "/saveview", queryview, true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  getDashboards(): Observable<IKediResponse<VOEDashboard[]>> {
    return this._session.api.voeGet<VOEDashboard[]>("voe/dashboards", true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }
  
  getCompanies(brokername: string): Observable<IKediResponse<UyumCompany[]>> {
    return this._session.api.voeGet<UyumCompany[]>("voe/" + brokername + "/companies", true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  getDashboard(dashboard: VOEDashboard): Observable<IKediResponse<VOEDashboard>> {
    return this._session.api.voeGet<VOEDashboard>("voe/dashboard/" + (!dashboard || dashboard.Name == "" ? null : dashboard.Name), true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  saveDashboard(dashboardname: string, dashboard: VOEDashboard): Observable<IKediResponse<VOEDashboard>> {
    return this._session.api.voePost<VOEDashboard>("voe/dashboard/" + dashboardname + "/save", dashboard, true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  deleteDashboard(dashboard: VOEDashboard): Observable<IKediResponse<VOEDashboard[]>> {
    return this._session.api.voePost<VOEDashboard[]>("voe/dashboard/" + (!dashboard || dashboard.Name == "" ? null : dashboard.Name) + "/delete", dashboard, true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  getDashboardLog(dashboard: VOEDashboard, filter: VOELogFilter): Observable<IKediResponse<VOELogData>> {
    return this._session.api.voePost<VOELogData>("voe/dashboard/" + (!dashboard || dashboard.Name == "" ? null : dashboard.Name) + "/log", filter, true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  getDashboardView(url: string, body: VOEDashboardRequest): Observable<IKediResponse<VOEDashboardView>> {
    return this._session.api.voePost<VOEDashboardView>("voe/" + url, body, true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  getUsers(): Observable<IKediResponse<VOEUser[]>> {
    return this._session.api.voeGet<VOEUser[]>("voe/users", true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }
  
  getUser(user: VOEUser): Observable<IKediResponse<VOEUser>> {
    return this._session.api.voeGet<VOEUser>("voe/user/" + (!user || user.Username == "" ? null : user.Username), true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  saveUser(username: string, user: VOEUser): Observable<IKediResponse<VOEUser>> {
    return this._session.api.voePost<VOEUser>("voe/" + "user/" + username + "/save", user, true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  deleteUser(user: VOEUser): Observable<IKediResponse<VOEUser[]>> {
    return this._session.api.voePost<VOEUser[]>("voe/" + "user/" + (!user || user.Username == "" ? null : user.Username) + "/delete", user, true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }  

  getUserGroups(): Observable<IKediResponse<VOEUserGroup[]>> {
    return this._session.api.voeGet<VOEUserGroup[]>("voe/usergroups/", true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }
  
  getUserGroup(usergroup: VOEUserGroup): Observable<IKediResponse<VOEUserGroup>> {
    return this._session.api.voeGet<VOEUserGroup>("voe/" + "usergroup/" + (!usergroup || usergroup.Name == "" ? null : usergroup.Name), true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  saveUserGroup(name: string, usergroup: VOEUserGroup): Observable<IKediResponse<VOEUserGroup>> {
    return this._session.api.voePost<VOEUserGroup>("voe/" + "usergroup/" + name + "/save", usergroup, true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }

  deleteUserGroup(usergroup: VOEUserGroup): Observable<IKediResponse<VOEUserGroup[]>> {
    return this._session.api.voePost<VOEUserGroup[]>("voe/" + "usergroup/" + (!usergroup || usergroup.Name == "" ? null : usergroup.Name) + "/delete", usergroup, true, [{ name: "Token", value: this._integrationSettings.defaultToken}]);
  }    
}
