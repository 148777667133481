import { filter, MonoTypeOperatorFunction, pipe, takeUntil, take, tap, Observable } from "rxjs";

export function ensure<T>(_owner: any): MonoTypeOperatorFunction<T> {
  var _subject = _owner ? _owner["_unsubscribeAll"] : null;
  return pipe(
      _subject ? takeUntil(_subject): tap(() => {})
  );
}

export function ensureValue<T>(_owner: any): MonoTypeOperatorFunction<T> {
  var _subject = _owner ? _owner["_unsubscribeAll"] : null;
  return pipe(
      _subject ? takeUntil(_subject): tap(() => {}),
      filter(x => !!x)
  );
}

export function once<T>(_owner: any): MonoTypeOperatorFunction<T> {
  var _subject = _owner ? _owner["_unsubscribeAll"] : null;
  return pipe(
      _subject ? takeUntil(_subject): tap(() => {}),
      take(1)
  );
}

export function onceValue<T>(_owner: any): MonoTypeOperatorFunction<T> {
  var _subject = _owner ? _owner["_unsubscribeAll"] : null;
  return pipe(
      _subject ? takeUntil(_subject): tap(() => {}),
      filter(x => !!x),
      take(1)
  );
}