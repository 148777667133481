<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<div
  class="flex flex-auto justify-center w-full sm:p-4 md:p-8 bg-gray-200 dark:bg-card"
  style="background-image: var(--background-image)"
>
  <!-- <img src="images/ecommerce/header-logo.svg" style="position: absolute; left: 40px; width: 160px; object-fit: cover"> -->
  <!-- Navigation -->
  <ng-container *ngIf="isScreenSmall && navigation">
    <fuse-vertical-navigation
      class="dark bg-gray-900 print:hidden"
      backColor="var(--fuse-primary-700)"
      [mode]="'over'"
      [name]="'mainNavigation'"
      [navigation]="navigation.default"
      [opened]="false"
    >
      <!-- Navigation header hook -->
      <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <!-- <div class="flex items-center h-20 pt-6 px-8"> -->
        <img
          class="kedi-main-logo kedi-layout-centered"
          [src]="'images/logo/logo-text-menu.svg'"
        />
        <!-- </div> -->
      </ng-container>
    </fuse-vertical-navigation>
  </ng-container>

  <!-- Wrapper -->
  <div
    class="flex flex-col items-center flex-auto min-w-0 max-w-screen-xl sm:rounded-xl shadow-2xl dark:shadow-none overflow-hidden"
    [class.bg-transparent]="session.currentHeaderTransparent()"
  >
    <!-- Header -->
    <div
      class="relative flex flex-0 items-center w-full h-16 sm:h-20 px-4 md:px-6 z-49 bg-card border-b dark:bg-default print:hidden"
      [class.bg-transparent]="session.currentHeaderTransparent()"
    >
      <ng-container *ngIf="!isScreenSmall && navigation">
        <!-- Logo -->
        <div class="flex items-center mx-2 lg:mr-8">
          <div class="hidden lg:flex">
            <a [routerLink]="['/']">
              <!-- Light version -->
              <img
                class="kedi-main-logo kedi-layout-centered flex dark:hidden object-contain pb-2"
                style="width: 6rem"
                [src]="
                  'images/logo/logo-text' +
                  (session.currentHeaderTransparent() ? '-on-dark' : '') +
                  '.svg'
                "
                alt="Logo image"
              />
              <!-- Dark version -->
              <img
                class="kedi-main-logo-dark kedi-layout-centered hidden dark:flex object-contain pb-2"
                style="width: 6rem"
                [src]="'images/logo/logo-text-on-dark.svg'"
                alt="Logo image"
              />
            </a>
          </div>
          <!-- Small version -->
          <img
            class="kedi-main-logo kedi-layout-centered flex lg:hidden object-contain pb-2"
            [src]="
              'images/logo/logo' +
              (session.currentHeaderTransparent() ? '-on-dark' : '') +
              '.svg'
            "
            alt="Logo image"
          />
        </div>
        <!-- Horizontal navigation -->
        <fuse-horizontal-navigation
          class="mr-2"
          [name]="'mainNavigation'"
          [navigation]="navigation.horizontal"
          [transparentHeader]="transparentHeader"
        ></fuse-horizontal-navigation>
      </ng-container>
      <!-- Navigation toggle button -->
      <ng-container *ngIf="isScreenSmall">
        <button
          class="mr-2"
          mat-icon-button
          [class.text-white]="!child && transparentHeader"
          (click)="toggleNavigation('mainNavigation')"
        >
          <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>
      </ng-container>
      <!-- Components -->
      <div
        class="kedi-main-buttons kedi-layout-center flex items-center pl-2 ml-auto space-x-1 sm:space-x-2"
      >
        <user-firm *ngIf="session && session.ActiveFirm"></user-firm>
        <user-company *ngIf="session && session.ActiveCompany"></user-company>
        <header-buttons
          *ngIf="navigation"
          class="space-x-1 sm:space-x-2"
          [navigation]="navigation.horizontal"
          [name]="'headerButtons'"
        ></header-buttons>
        <languages *ngIf="session.config.multiLanguage()"></languages>
        <fuse-fullscreen
          *ngIf="session.config.toolFullScreen()"
          class="hidden md:block"
        ></fuse-fullscreen>
        <search
          *ngIf="session.config.toolSearch()"
          [appearance]="'bar'"
        ></search>
        <shortcuts *ngIf="session.config.toolShortcuts()"></shortcuts>
        <messages *ngIf="session.config.toolMessages()"></messages>
        <notifications
          *ngIf="session.config.toolNotifications()"
        ></notifications>
        <user></user>
        <!-- Side Logo -->
        <!-- <div class="flex flex-row-reverse items-center mx-2">
                    <div class="flex">
                        <a [routerLink]="['/']">
                        <img
                            class="kedi-side-logo kedi-layout-centered dark:hidden" style="width: 6rem;"
                            [src]="'images/logo/logo-text.svg'"
                            alt="Logo image">
                        <img
                            class="kedi-side-logo-dark kedi-layout-centered hidden dark:flex" style="width: 6rem;"
                            [src]="'images/logo/logo-text-on-dark.svg'"
                            alt="Logo image">
                        </a>
                    </div>                    
                </div> -->
      </div>
    </div>

    <!-- Content -->
    <div
      class="flex flex-col flex-auto w-full bg-default"
      style="min-height: 80vh !important"
    >
      <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
                 Otherwise, layout changes won't be registered and the view won't be updated! -->
      <router-outlet
        *ngIf="true"
        (activate)="onActivate($event)"
      ></router-outlet>
    </div>
  </div>
</div>
