<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
    class="bg-card dark:bg-gray-900 print:hidden"
    [appearance]="'thin'"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation.compact"
    [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex items-center justify-center h-20">
            <img
                class="w-8"
                [src]="'images/logo/logo.svg'"
                alt="Logo">
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <!-- Navigation toggle button -->
        <button
            class="mr-2"
            mat-icon-button
            (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            @if (session && session.ActiveFirm) {
                <user-firm></user-firm>
            }
            @if (session && session.ActiveCompany) {
                <user-company></user-company>
            }
            @if (navigation) {
                <header-buttons class="space-x-1 sm:space-x-2" [navigation]="navigation.horizontal" [name]="'headerButtons'"></header-buttons>
            }
            @if (session.config.multiLanguage()) {
                <languages></languages>
            }
            @if (session.config.toolFullScreen()) {
                <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            }
            @if (session.config.toolSearch()) {
                <search [appearance]="'bar'"></search>
            }
            @if (session.config.toolShortcuts()) {
                <shortcuts></shortcuts>
            }
            @if (session.config.toolMessages()) {
                <messages></messages>
            }
            @if (session.config.toolNotifications()) {
                <notifications></notifications>
            }
            @if (session.config.toolChat()) {
                <button 
                    class="lg:hidden"
                    mat-icon-button
                    (click)="quickChat.toggle()">
                    <mat-icon [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
                </button>
            }
            <user></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true" (activate)="onActivate($event)"></router-outlet>
    </div>
</div>

<!-- Quick chat -->
<quick-chat *ngIf="session.config.toolChat() && !session.isAnonymous()" #quickChat></quick-chat>
