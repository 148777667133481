import { KediEntityBase } from "./entity.base";

export interface WebSiteContractText extends KediEntityBase
{
    SiteCode?: string;
    TextType?: WebContractTextType,
    Title?: string;
    Text?: string;
}

export enum WebContractTextType {
    Kvkk = 1,
    Membership = 2,
    SalesContract = 3,
    AdConfirmation = 4,
    CookiePolicy = 5
}
