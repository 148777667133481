import { NgModule } from '@angular/core';
import { ApiService } from './api.service';

@NgModule({
    declarations: [],
    imports     : [],
    providers   : [
        ApiService
    ]
})
export class ApiServiceModule
{
    /**
     * Constructor
     */
    constructor()
    {
    }
}
