<ng-container *ngFor="let item of navigation; trackBy: trackByFn">
  <!-- Skip the hidden items -->
  <ng-container
    *ngIf="
      ((item.hidden && !item.hidden(item)) || !item.hidden) &&
      (item.position == 'button' ||
        (item.position == 'menu-button' && smallScreen))
    "
  >
    <ng-container *ngIf="!item.children || item.children.length == 0">
      <!-- Button -->
      <button
        mat-icon-button
        [matTooltip]="nav.t(item.code)"
        [matBadge]="item.badge?.code"
        matBadgeColor="primary"
        matBadgeSize="small"
        (click)="buttonClicked(item)"
      >
        <ng-container *ngIf="item.icon">
          <mat-icon
            [ngClass]="item.classes?.icon"
            [svgIcon]="item?.icon"
            [class.text-white]="session.currentHeaderTransparent()"
          ></mat-icon>
        </ng-container>
      </button>
    </ng-container>
    <ng-container *ngIf="item.children && item.children.length > 0">
      <!-- Button -->
      <button
        mat-icon-button
        [matTooltip]="nav.t(item.code) || ''"
        [matMenuTriggerFor]="submenu"
      >
        <ng-container *ngIf="item.icon">
          <mat-icon
            [ngClass]="item.classes?.icon"
            [svgIcon]="item?.icon"
            [class.text-white]="session.currentHeaderTransparent()"
          ></mat-icon>
        </ng-container>
      </button>

      <!-- Sub menu -->
      <mat-menu [xPosition]="'before'" #submenu="matMenu">
        <ng-container *ngFor="let child of item?.children; trackBy: trackByFn">
          <button mat-menu-item (click)="buttonClicked(child)">
            <span class="flex items-center">
              <ng-container *ngIf="child.icon">
                <mat-icon
                  [ngClass]="child.classes?.icon"
                  [svgIcon]="child?.icon"
                ></mat-icon>
              </ng-container>
              <!-- <ng-container *ngTemplateOutlet="menuIcon; context: {$implicit: lang.id}"></ng-container> -->
              <span class="ml-3">{{ nav.t(child.code) }}</span>
            </span>
          </button>
        </ng-container>
      </mat-menu>

      <!-- Menu icon template -->
      <ng-template let-menu #menuIcon>
        <span class="relative w-6 shadow rounded-sm overflow-hidden">
          <span
            class="absolute inset-0 ring-1 ring-inset ring-black ring-opacity-10"
          ></span>
          <img class="w-full" [src]="'images/flags/' + lang + '.svg'" />
        </span>
      </ng-template>
    </ng-container>
  </ng-container>
</ng-container>
