import { Route } from '@angular/router';
import { PageGuard } from '@kedi/core';
import { LayoutComponent } from '@kedi/core/fuse';
import LazyRoutes from './lazy.routes';
import { DefaultPage } from './modules/default/default.component';
import { NotFoundPage } from './modules/not-found/not-found.component';
import PluginRoutes from './plugin.routes';

export const appRoutes: Route[] = [
    {
        path: '',
        canActivateChild: [PageGuard],
        children: [
            {
                path: '',
                pathMatch: 'full',
                component: DefaultPage,
            },
            {
                path: 'signed-in-redirect',
                pathMatch: 'full',
                component: DefaultPage,
            },
            ...PluginRoutes,
            ...LazyRoutes,
            {
                path: '',
                component: LayoutComponent,
                loadChildren: () =>
                    import('@kedi/core/pages').then((m) => m.MainRoutes),
            },
            {
                path: 'core',
                component: LayoutComponent,
                loadChildren: () =>
                    import('@kedi/core/pages').then((m) => m.CoreRoutes),
            },
            {
                path: '**',
                component: NotFoundPage,
            },
        ]
    }
];
