import { computed, EnvironmentProviders, InjectionToken, Provider, signal, WritableSignal } from "@angular/core";
import { ApiLanguage } from "../api/api.types";
import { NavigationItem } from "../navigation";
export const KEDI_SITE_SETTINGS = new InjectionToken<KediSiteSettings>('KEDI_SITE_SETTINGS');
export const KEDI_LANGS = new InjectionToken<ApiLanguage[]>('KEDI_LANGS');

export const provideSettings = (site: KediSiteSettings): Array<Provider | EnvironmentProviders> => {
    const providers: Array<Provider | EnvironmentProviders> = [
        {
            provide: KEDI_SITE_SETTINGS,
            useValue: site ?? {},
        },
        {
            provide: KEDI_LANGS,
            useValue: [
                { index: 0, id: "tr", title: "Türkçe", flag: "tr", rtl: false, locale: "tr-TR" },
                { index: 1, id: "en", title: "English", flag: "en", rtl: false, locale: "en-US" },
                { index: 2, id: "ar", title: "عربي", flag: "ar", rtl: true, locale: "ar-AR" },
                { index: 3, id: "ot", title: "Osmanlı Türkçesi", flag: "ot", rtl: true, locale: "ot-OT" },
            ]
        }
    ];
    return providers;
};
export class KediConfig {
    constructor(ss: KediSiteSettings, apiUrl: string) {
        this.siteCode = ss.code;
        this.apiUrl = apiUrl + (apiUrl.endsWith("/") ? "" : "/");
        this.site = signal<KediSiteSettings>({ ...new KediSiteSettings(), ...ss });
    }
    apiUrl: string;
    siteCode: string;
    site: WritableSignal<KediSiteSettings>;
    // canUseLayout = (layoutType) => computed(() => this.site().theme.layouts.find(l => l == layoutType));

    defaultTheme = computed(() => this.site().defaults?.theme);
    defaultScheme = computed(() => this.site().defaults?.scheme);
    defaultLayout = computed(() => this.site().defaults?.layout);
    defaultRoute = computed(() => this.site().routes?.default);

    toolAdminPanel = computed(() => this.site().tools?.admin_panel);
    toolCalendar = computed(() => this.site().tools?.calendar);
    toolCaptcha = computed(() => this.site().tools?.captcha);
    toolChat = computed(() => this.site().tools?.chat);
    toolContacts = computed(() => this.site().tools?.contacts);
    toolLibrary = computed(() => this.site().tools?.library);
    toolMail = computed(() => this.site().tools?.mail);
    toolNotes = computed(() => this.site().tools?.notes);
    toolOtp = computed(() => this.site().tools?.otp);
    toolScrumboards = computed(() => this.site().tools?.scrumboards);
    toolTasks = computed(() => this.site().tools?.tasks);
    toolCompany = computed(() => this.site().tools?.company);
    toolFirm = computed(() => this.site().tools?.firm);
    toolFullScreen = computed(() => this.site().tools?.full_screen);
    toolMessages = computed(() => this.site().tools?.messages);
    toolNotifications = computed(() => this.site().tools?.notifications);
    toolProfile = computed(() => this.site().tools?.profile);
    toolSettings = computed(() => this.site().tools?.settings);
    toolShortcuts = computed(() => this.site().tools?.shortcuts);
    toolSearch = computed(() => this.site().tools?.search);
    toolStatus = computed(() => this.site().tools?.status);

    headerTransparent = computed(() => this.site().theme?.header?.transparent);
    headerBackgroundImage = computed(() => this.site().theme?.header?.background_image);

    auth = computed(() => this.site().auth);
    authScheme = computed(() => this.site().auth?.scheme);
    authStyle = computed(() => this.site().auth?.style);
    authTheme = computed(() => this.site().auth?.theme);
    authSignUpAllowed = computed(() => this.site().auth?.signup?.allowed);
    authSignUpCaptcha = computed(() => this.site().auth?.signup?.captcha);
    authSignUpOtp = computed(() => this.site().auth?.signup?.otp);
    authSignUpCompany = computed(() => this.site().auth?.signup?.company);
    authSignUpContract = computed(() => this.site().auth?.signup?.contract);
    authSignUpPrivacy = computed(() => this.site().auth?.signup?.privacy);

    authSignInCaptcha = computed(() => this.site().auth?.signin?.captcha);
    authSignInOtp = computed(() => this.site().auth?.signin?.otp);
    authResetCaptcha = computed(() => this.site().auth?.reset?.captcha);
    authResetOtp = computed(() => this.site().auth?.reset?.otp);
    authResetAsk = computed(() => this.site().auth?.signin?.ask);

    authSignInAnonymous = computed(() => this.site().auth?.signin?.anonymous);
    authSignInAsk = computed(() => this.site().auth?.signin?.ask);
    authSignInAskEmail = computed(() => this.site().auth?.signin?.ask == "email");

    meta = computed(() => this.site().meta);
    metaTitle = computed(() => this.site().meta?.title);
    metaDescription = computed(() => this.site().meta?.description);
    metaKeywords = computed(() => this.site().meta?.keywords);
    metaOther = computed(() => this.site().meta?.other);

    modules = computed(() => this.site().modules);

    siteName = computed(() => this.site().name);
    siteTitle = computed(() => this.site().title);
    siteDescription = computed(() => this.site().description);
    siteMotto = computed(() => this.site().motto);

    routeDefault = computed(() => this.site().routes?.default);
    routeChangePassword = computed(() => this.site().routes?.change_password);
    routeDasboard = computed(() => this.site().routes?.dashboard);
    routeSignIn = computed(() => this.site().routes?.sign_in);
    routeProfile = computed(() => this.site().routes?.user_profile);
    routeSettings = computed(() => this.site().routes?.user_settings);

    themeColors = computed(() => this.site().theme?.colors);
    themeLanguages = computed(() => this.site().theme?.languages);
    themeLayouts = computed(() => this.site().theme?.layouts);
    themeSchemes = computed(() => this.site().theme?.schemes);
    multiLanguage = computed(() => this.site().theme?.languages?.length > 1);

    schemeAuto = computed(() => this.site().theme.schemes.find(l => l == 'auto'));
    schemeLight = computed(() => this.site().theme.schemes.find(l => l == 'light'));
    schemeDark = computed(() => this.site().theme.schemes.find(l => l == 'dark'));

    layoutCompact = computed(() => this.site().theme.layouts.find(l => l == 'compact'));
    layoutCentered = computed(() => this.site().theme.layouts.find(l => l == 'centered'));
    layoutClassic = computed(() => this.site().theme.layouts.find(l => l == 'classic'));
    layoutClassy = computed(() => this.site().theme.layouts.find(l => l == 'classy'));
    layoutDense = computed(() => this.site().theme.layouts.find(l => l == 'dense'));
    layoutEmpty = computed(() => this.site().theme.layouts.find(l => l == 'empty'));
    layoutEnterprise = computed(() => this.site().theme.layouts.find(l => l == 'enterprise'));
    layoutFuturistic = computed(() => this.site().theme.layouts.find(l => l == 'futuristic'));
    layoutMaterial = computed(() => this.site().theme.layouts.find(l => l == 'material'));
    layoutModern = computed(() => this.site().theme.layouts.find(l => l == 'modern'));
    layoutThin = computed(() => this.site().theme.layouts.find(l => l == 'thin'));

    public setSite(partialState: Partial<KediSiteSettings>): void {
        this.site.update((currentValue) => ({ ...currentValue, ...partialState }));
    }
}
export class KediSiteSettings {
    publish_key: string;
    code: string = "kedi";
    modules?: string[] = [];
    title?: string[] = ["Kedi"];
    name?: string[] = ["Kedi"];
    description?: string[] = ["Kedi Platformu"];
    motto?: string[] = ["Kedi Platformu"];
    meta?: KediSiteSettings_Site_Meta = new KediSiteSettings_Site_Meta();
    routes?: KediSiteSettings_Routes = new KediSiteSettings_Routes();
    defaults?: KediSiteSettings_Defaults = new KediSiteSettings_Defaults();
    auth?: KediSiteSettings_Auth = new KediSiteSettings_Auth();
    tools?: KediSiteSettings_Tools = new KediSiteSettings_Tools();
    theme?: KediSiteSettings_Theme = new KediSiteSettings_Theme();
    menu?: NavigationItem[] = [];
}

export class KediSiteSettings_Site_Meta {
    title?: string = "Kedi";
    description?: string = "Kedi";
    keywords?: string = "Kedi";
    other?: string[] = [];
}

export class KediSiteSettings_Routes {
    default?: string = "core/dashboard";
    dashboard?: string = "core/dashboard";
    sign_in?: string = "sign-in";
    change_password?: string = "reset-password";
    user_profile?: string = "core/profile";
    user_settings?: string = "core/usersettings";
}

export class KediSiteSettings_Defaults {
    language?: string = "tr";
    layout?: string = "classy";
    scheme?: string = "auto";
    theme?: string = "kedi";
}

export class KediSiteSettings_Current {
    language?: string = "tr";
    layout?: string = "classy";
    scheme?: string = "auto";
    theme?: string = "kedi";
    headerTransparent?: boolean = false;
}

export class KediSiteSettings_Auth {
    style?: string = "modern-reversed";
    signin?: KediSiteSettings_Auth_SignIn = new KediSiteSettings_Auth_SignIn();
    signup?: KediSiteSettings_Auth_SignUp = new KediSiteSettings_Auth_SignUp();
    reset?: KediSiteSettings_Auth_Reset = new KediSiteSettings_Auth_Reset();
    scheme?: string = "";//@TODO:varsayılan doldurulacak
    theme?: string = "";//@TODO:varsayılan doldurulacak
}

export class KediSiteSettings_Auth_SignIn {
    anonymous?: boolean = false;
    ask?: "username" | "email";
    captcha?: KediSiteSettings_Auth_ToolItem = new KediSiteSettings_Auth_ToolItem();
    otp?: KediSiteSettings_Auth_ToolItem = new KediSiteSettings_Auth_ToolItem();
}

export class KediSiteSettings_Auth_Reset {
    captcha?: KediSiteSettings_Auth_ToolItem = new KediSiteSettings_Auth_ToolItem();
    otp?: KediSiteSettings_Auth_ToolItem = new KediSiteSettings_Auth_ToolItem();
}

export class KediSiteSettings_Auth_SignUp {
    allowed?: boolean = false;
    captcha?: KediSiteSettings_Auth_ToolItem = new KediSiteSettings_Auth_ToolItem();
    otp?: KediSiteSettings_Auth_ToolItem = new KediSiteSettings_Auth_ToolItem();
    company?: KediSiteSettings_Auth_ToolItem = new KediSiteSettings_Auth_ToolItem();
    contract?: KediSiteSettings_Auth_ToolItem = new KediSiteSettings_Auth_ToolItem();
    privacy?: KediSiteSettings_Auth_ToolItem = new KediSiteSettings_Auth_ToolItem();
}

export class KediSiteSettings_Auth_ToolItem {
    constructor() {
        this.ask = false;
    }
    ask?: boolean = false;
    content?: string;
}

export class KediSiteSettings_Tools {
    calendar?: boolean = false;
    chat?: boolean = false;
    contacts?: boolean = false;
    library?: boolean = false;
    mail?: boolean = false;
    notes?: boolean = false;
    scrumboards?: boolean = false;
    tasks?: boolean = false;
    admin_panel?: boolean = false;
    captcha?: KediSiteSettings_Tools_Captcha = new KediSiteSettings_Tools_Captcha();
    otp?: KediSiteSettings_Tools_Otp = new KediSiteSettings_Tools_Otp();

    profile?: boolean = true;
    settings?: boolean = true;
    status?: boolean = true;
    shortcuts?: boolean = true;
    messages?: boolean = true;
    notifications?: boolean = true;
    search?: KediSiteSettings_Theme_Header_Search = new KediSiteSettings_Theme_Header_Search();
    full_screen?: boolean = true;
    company?: boolean = false;
    firm?: boolean = false;

}

export class KediSiteSettings_Tools_Captcha {
    site_key?: string = "";
    private_key?: string = "";
}

export class KediSiteSettings_Tools_Otp {
    reset?: string = "";
}

export class KediSiteSettings_Theme {
    languages?: string[] = ["tr"];
    colors?: KediSiteSettings_Theme_Color[];
    schemes?: string[] = ["auto", "light", "dark"];
    layouts?: string[] = ["classy"];
    header?: KediSiteSettings_Theme_Header = new KediSiteSettings_Theme_Header();
}

export class KediSiteSettings_Theme_Color {
    id?: string;
    name?: string;
    primary?: string;
    secondary?: string;
    accent?: string;
    warn?: string;
    'on-warn'?: string;
}

export class KediSiteSettings_Theme_Header {
    transparent?: boolean = false;
    background_image?: string = "";
}

export class KediSiteSettings_Theme_Header_Search {
    url?: string = "";
}