import { Routes } from '@angular/router';
  import { LayoutComponent } from '@kedi/core/fuse';

  export default [  
    {
      path: 'core',
      component: LayoutComponent,
      loadChildren: () => import('@kedi/core/pages').then(m => m.MainRoutes)
    },  
    {
      path: 'c002',
      component: LayoutComponent,
      loadChildren: () => import('@kedi/ihe_b2c').then(m => m.MainRoutes)
    }
  ] as Routes;
  